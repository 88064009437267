@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/design-system/colors';

$submit-btn-container-height: 5rem;

.form-footer {
  background-color: $ay-white-color;
  bottom: 0;
  height: $submit-btn-container-height;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: $z-index-2;
}

.cancel-btn,
.submit-btn {
  margin-top: 1rem;
  width: 10rem;
}

.submit-btn {
  margin-left: 1rem;
}
