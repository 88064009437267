.property-container {
  display: flex;
}

.property-image-container {
  margin: 0.5rem 0.5rem 0 0;
}

.property-image {
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
}
