@import 'src/assets/stylesheets/design-system/colors';

.actions {
  height: 2rem;
  position: absolute;
  right: 1rem;

  button {
    margin-left: 1rem;
    padding-bottom: 0;
  }
}

.mobile-actions {
  display: none;
}

@mixin icon {
  path {
    stroke: $primary-color-500;
  }
}

.mobile-action-button {
  @include icon;

  background-color: transparent;
  margin-left: 0.5rem;
  width: calc(100% - 1rem);

  &:first-child {
    margin-bottom: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
  }

  &:hover,
  &:focus {
    @include icon;

    background-color: $ui-color-white;
    color: $primary-color-500;
  }
}

@media only screen and (max-width: 48rem) {
  .actions {
    display: none;
  }

  .mobile-actions {
    display: block;
    position: absolute;
    right: 1rem;

    .options-icon-container {
      padding-left: 0.2rem;
    }
  }
}
