@import 'src/assets/stylesheets/design-system/colors';

.container {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
}

.loading {
  align-items: center;
  background: linear-gradient(180deg, transparent, $ay-white-color);
  box-sizing: border-box;
  display: flex;
  height: 6rem;
  justify-content: center;
  margin-top: -1.25rem;
  width: 100%;
  z-index: 1000;

  &-text {
    color: $ay-grey-80-color;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: -0.75rem;
  }
}
