@import 'src/assets/stylesheets/design-system/colors';

.link {
  color: $ay-black-color;
  text-decoration: none;

  &:hover {
    color: $primary-color-400;
  }
}
