@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/variables';

.label {
  color: $ay-grey-64-color;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 0.5rem;
}

.container {
  border-radius: 0.25rem;
  display: flex;
  min-height: $switch-toggle-buttons-size;
  overflow: hidden;

  .button {
    background-color: $primary-color-100;
    border: 0;
    color: $ay-grey-64-color;
    cursor: pointer;
    flex-grow: 1;
    font-size: 1rem;
    padding: 0.5rem 1rem;

    &.is-active {
      background-color: $primary-color-500;
      color: $ay-white-color;
    }
  }
}
