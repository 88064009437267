@import 'src/assets/stylesheets/design-system/colors';

.row-container {
  display: grid;
  gap: 1rem 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  width: fit-content;

  .input-currency {
    width: 7.5rem;
  }

  .input-year {
    width: 9rem;
  }
}

.row {
  padding: 0.75rem;

  &-options {
    margin-top: 0.75rem;
  }
}

.expense-label {
  color: $ui-color-900;
  display: flex;
  font-size: 0.875rem;
  justify-content: flex-start;
  width: 100%;
}
