@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/variables';

.availability-size-toggles {
  display: flex;

  .types-switch {
    font-weight: 600;
    height: $switch-toggle-buttons-size;
    margin-left: 0.5rem;
    min-height: $switch-toggle-buttons-size;
    min-width: 8rem;
  }

  .switch-button {
    font-weight: 600;
  }
}

.missing-graph-data {
  background-color: $primary-color-900;
  border-radius: 0.3125rem;
  box-sizing: border-box;
  height: 23rem;
  position: relative;
}
