@import 'src/assets/stylesheets/design-system/colors';

$submit-btn-container-height: 5rem;

.container {
  margin-bottom: $submit-btn-container-height;
}

.submit-btn-container {
  background-color: $ay-white-color;
  bottom: 0;
  height: $submit-btn-container-height;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;
}

.cancel-btn,
.submit-btn {
  margin-top: 1rem;
  width: 10rem;
}

.submit-btn {
  margin-left: 1rem;
}

.dropzone-container {
  margin-bottom: 1rem;
}
