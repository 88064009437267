.activity-actions {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 0.75rem;

  &.scoop-actions {
    grid-template-columns: repeat(3, 1fr);
  }
}
