@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';

.currency-symbol {
  align-items: center;
  color: $ay-grey-64-color;
  display: flex;
  font-size: 0.9rem;
  left: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: $z-index-1;
}

.currency-input {
  text-align: center;
}
