@import 'src/assets/stylesheets/design-system/colors';

.container {
  color: $ay-grey-64-color;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  margin-top: 0.5rem;
  padding: 0;

  &.line {
    display: flex;
    margin-bottom: 0.3125rem;
  }

  .col {
    flex-wrap: wrap;
  }
}

.small-text {
  font-size: 0.75rem;
}

.margin-top-updated-user-small {
  margin-top: 0.25rem;
}

.margin-top-updated-user {
  margin-top: 1rem;
}

.user-name {
  display: inline-block;
}

.colored {
  color: $primary-color-500;
}

.owner-separator {
  background-color: $ui-color-300;
  margin: 0 1rem;
  width: 0.0625rem;
}

@media only screen and (min-width: 75rem) {
  .container-flex {
    display: flex;
  }
}
