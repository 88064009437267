@import 'src/assets/stylesheets/shadows';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/design-system/colors';

.container {
  background-color: $ui-color-white;
  bottom: 4.5rem;
  box-shadow: $shadow-top;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  left: 0;
  padding: 0.25rem 0;
  position: fixed;
  right: 0;
  z-index: $z-index-11;
}

.pages {
  color: $primary-color-500;
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  margin: 0 2.5rem;
}
