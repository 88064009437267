@import '../../assets/stylesheets/design-system/colors';

.container {
  margin-left: 1.5rem;
  margin-top: 5rem;
}

.header {
  color: $ay-grey-80-color;
  font-size: 1.5rem;
  line-height: 2rem;
}

.message {
  color: $ay-grey-64-color;
  font-size: 1rem;
  line-height: 1.25rem;
}

.link {
  color: $primary-color-200;
  font-size: 1rem;
  line-height: 1.25rem;
}
