@import 'src/assets/stylesheets/design-system/colors';

.loading {
  align-items: center;
  background-color: rgba($primary-color-900, 0.8);
  border-radius: 0.25rem;
  color: $ay-white-color;
  display: flex;
  flex-direction: column;
  height: 7rem;
  justify-content: center;
  left: 50%;
  max-width: 12.5rem;
  padding: 0 2rem;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}
