@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/variables';

@keyframes showSuggestions {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

$search-input-height-small: 2.5rem;
$search-input-height-medium: 3rem;
$search-input-height-large: 4rem;

.search-input-wrapper {
  background: $ui-color-white;
  border-radius: 0.25rem;
  position: relative;
  width: 100%;

  &-small {
    min-height: $search-input-height-small;
  }

  &-medium {
    min-height: $search-input-height-medium;
  }

  &-large {
    min-height: $search-input-height-large;
  }

  &-editable {
    width: 100%;
  }

  &-focused {
    z-index: $z-index-9;
  }

  .search-input {
    border: 0;
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: $ay-grey-64-color;
    flex: 2;
    font-family: inherit;
    font-size: 1rem;
    outline: none;
    padding-left: 1rem;
    width: 100%;

    &-small {
      height: $search-input-height-small;
      line-height: $search-input-height-small;
    }

    &-medium {
      height: $search-input-height-medium;
      line-height: $search-input-height-medium;
    }

    &-large {
      height: $search-input-height-large;
      line-height: $search-input-height-large;
    }

    &::placeholder {
      color: $ay-grey-64-color;
    }

    &-icon {
      background: $ui-color-white url('../../assets/images/icons/search-inactive.svg') no-repeat 20px 50%;
      padding-left: 3.5rem;
    }

    &-disabled {
      opacity: 0.4;
    }
  }

  .input-container {
    align-items: center;
    display: flex;
    position: relative;
  }

  .selectable {
    background-color: $ay-grey-8-color;
  }

  .suggestions-container {
    @include input-border-style;

    background: $ui-color-white;
    border-radius: 0.25rem;
    height: auto;
    overflow: hidden;
    position: absolute;
    width: 100%;

    &-disabled:hover {
      border-color: $ay-grey-16-color !important;
    }

    &.suggestions-container-active {
      border: 1px solid $primary-color-400;
      box-shadow: 0 8px 24px rgba(0, 32, 91, 0.24);

      .search-input-icon {
        background: $ui-color-white url('../../assets/images/icons/search-active.svg') no-repeat 20px 50%;
      }
    }

    &.suggestions-container-navbar {
      border: 0;
      border-radius: $ay-border-radius;

      .search-input {
        border-radius: 0;
      }
    }

    &.suggestions-container-page-bottom {
      margin-bottom: 5.5rem;
    }
  }

  .separator-line {
    border-top: 1px solid $ay-grey-16-color;
    height: 0.0625rem;
    margin: 0 1.5rem;
  }

  .separator {
    background-color: #f4f4f5;
    color: #777886;
    font-size: 0.8rem;
    padding-bottom: 0.25rem;
    padding-left: 1rem;
    padding-top: 0.25rem;
  }

  .search-suggestions {
    animation: showSuggestions 100ms linear both;
    display: block;
    height: auto;
    list-style: none;
    margin: 0;
    max-height: 16rem;
    overflow: auto;
    padding: 0;

    &.search-suggestions-hidden {
      display: none;
    }

    &.with-custom-option {
      max-height: 14rem;
    }
  }

  .search-suggestion-item {
    cursor: pointer;
    display: flex;
    padding-top: 0.5rem;
    text-align: left;

    p {
      margin: 0;
      padding: 0;
    }

    .source-icon {
      margin-right: 1rem;
      width: 3rem;
    }

    .suggestion-value {
      border-radius: $ay-border-radius;
      display: flex;
      flex: 1;
      padding: 0.5rem 0.5rem 0.5rem 1rem;

      &.custom-option {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 0.75rem 0;

        &.with-border {
          border-top: 0.0625rem $ay-grey-16-color solid;
        }
      }

      &:hover {
        background: $ay-grey-8-color;
      }
    }

    &.search-suggestion-item-active {
      .suggestion-value {
        background: $ay-grey-8-color;
      }
    }

    .suggestion-text-primary {
      color: $ay-grey-64-color;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .suggestion-text-secondary {
      color: $ay-grey-40-color;
      font-size: 0.75rem;
      line-height: 1rem;
    }

    .text-capitalized {
      text-transform: capitalize;
    }
  }

  .results-feedback {
    color: $ay-grey-64-color;
    font-size: 0.75rem;
    line-height: 1rem;
    margin: 0;
    padding: 1rem 0 1rem 1.5rem;
    text-align: left;
  }

  .text-contanier {
    flex: 1;
  }

  .clear-search {
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    right: 1.5rem;

    &-small {
      top: 0.8rem;
    }

    &-medium {
      top: 1rem;
    }

    &-large {
      top: 1.7rem;
    }
  }
}

.img-profile {
  height: 0.75rem;
  margin-right: 0.5rem;
  width: 0.75rem;
}

.icon {
  margin-right: 0.5rem;
}

.merge-icon {
  margin: 0 0.5rem -0.2rem;
}

.editable-button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 2rem;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  width: 2rem;
}

.custom-option {
  border-radius: 0;
  display: flex;
  justify-content: space-between;
}

.custom-option-container {
  border-radius: 0;
  padding-left: 1rem;
  padding-right: 1rem;

  &:hover {
    background: $ay-grey-8-color;
  }

  .search-suggestion-item {
    padding-left: 0;
    padding-top: 0;
  }
}

.market-switch-container {
  position: absolute;
  right: 1.5rem;
}

.highlight {
  color: $ay-black-color;

  &.bold {
    font-weight: 600;
  }
}
