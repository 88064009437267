@import 'src/assets/stylesheets/design-system/colors';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.activity-date {
  color: $ay-grey-64-color;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  margin: 0;
  padding: 0;

  &.with-margin {
    margin: 1rem 0 0.5rem;
  }
}

.activity-headline {
  background-color: $ay-grey-8-color;
  border-radius: 0.25rem;
  color: $ui-color-black;
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  padding: 0.25rem;
}
