@import 'src/assets/stylesheets/design-system/colors';

.container {
  background-color: $ay-grey-8-color;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.filters,
.action-group {
  display: inline-block;
}

.dropdown-container {
  display: inline-block;
  margin-right: 0.5rem;
}

.action-button {
  font-size: 1rem;
  height: 2.5rem;
  line-height: 1.5rem;
  width: 4.5rem;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.reset-selection-button {
  background-color: transparent;
  border: 0;
}

.apply-selection-button {
  background-color: $ay-white-color;
  border: 0;
}

.hide-reset-button {
  display: none;
}
