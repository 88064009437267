@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';

.information-block {
  padding: 0.25rem 0.75rem;
}

.information-row {
  padding: 0.25rem 0.75rem;

  &.full-width {
    padding-left: 0;
    padding-right: 0;
  }
}

.body {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}

.primary-information {
  .address {
    color: $primary-color-500;
    display: flex;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
    padding-top: 0.75rem;
    text-decoration: none;
  }

  &-container {
    flex: 1 0 auto;
  }

  &,
  &-body {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }
}

.address-icon {
  margin-top: 0.1rem;
}

.owner,
.tenant {
  color: $ay-grey-80-color;
  font-size: 0.75rem;
  margin: 0;
  padding-bottom: 0.25rem;
  text-decoration: none;
}

.separator {
  background-color: $ay-grey-24-color;
  height: 0.0625rem;
  width: 100%;

  &.full-width {
    padding-left: 0;
    padding-right: 0;
  }

  &.tenant-prospect {
    background-color: $ay-grey-16-color;
  }
}

.general-information {
  font-size: 0.75rem;
  margin: 0;
  min-height: 1rem;
}

.link {
  &:hover {
    color: $primary-color-900;
  }
}

.company-link {
  font-weight: 600;

  &:hover {
    color: $primary-color-400;
  }
}

.text-info {
  color: $ay-grey-80-color;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0;
}

// Largest industries row
.industries-container {
  display: flex;
  flex-direction: column;
}

.industry-graph-row {
  display: flex;
}

.industries-tags {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  > .industry-content {
    border-bottom: 0.0625rem solid $ay-grey-16-color;
    padding-bottom: 0.25rem;
  }
}

.industry-content-wrapper {
  border-radius: 0.25rem;
  height: 100%;
  margin: 0.75rem;
  padding-top: 0.75rem;
}

.loading-content,
.empty-content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.industry-tag {
  border-radius: 0.25rem;
  color: $ay-pure-white-color;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  margin: 0.5rem 0;
  padding: 0.25rem;
  text-transform: uppercase;

  &-top {
    background-color: $primary-color-500;
    margin-top: 0;
  }

  &-second {
    background-color: $primary-color-400;
  }

  &-third {
    background-color: $primary-color-300;
  }

  &-others {
    background-color: $ui-color-700;
  }
}

.industry-graph {
  margin: 0.25rem auto 0;
}

.industry-details {
  color: $ay-pure-white-color;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  margin: 0;
}

.chart {
  background-color: $primary-color-900;
  border: 0.0625rem solid $primary-color-900;
}

.missing-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 19rem;
  padding: 0.5rem;

  img {
    max-width: 6rem;
  }
}

.missing-industry-data-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 19rem;
  padding: 0.5rem;

  img {
    max-width: 6rem;
  }
}

.amenities {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.amenity {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.add-data-button {
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  z-index: $z-index-2;
}

.heading-title {
  padding-bottom: 1rem;
}

.static-map {
  height: 5.5rem !important;
}
