@import 'src/assets/stylesheets/design-system/colors';

.badge {
  align-items: center;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.5rem;

  .badge-icon {
    margin-right: 0.5rem;
  }
}
