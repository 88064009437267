@import 'src/assets/stylesheets/design-system/colors';

.container {
  margin-bottom: 5rem;
}

.title {
  color: $ay-total-black-color;
  font-size: 2.5rem;
  font-weight: bold;
}

.compressed {
  margin: 0;

  .title {
    font-size: 1.75rem;
    margin: 0;
  }
}

.error-message {
  align-items: center;
  background-color: $ay-pure-white-color;
  border: 0.0625rem solid $system-color-warning;
  border-radius: 0.25rem;
  color: $ui-color-900;
  display: flex;
  padding-left: 1rem;

  p {
    padding-left: 1rem;
  }
}
