@import 'src/assets/stylesheets/variables';
@import 'src/assets/stylesheets/design-system/colors';

@mixin text {
  font-weight: 400;
  line-height: 150%;
  margin: 0;
  padding: 0;
}

.boxes-list {
  @include text;

  display: flex;
  list-style-type: none;

  .box {
    border: 0.0625rem solid $ui-color-300;
    border-radius: $ay-border-radius;
    flex: 1;
    padding: 1rem;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    .box-title {
      @include text;

      font-size: 1rem;
    }
  }

  .box-details {
    > dt {
      margin-bottom: 1rem;
    }

    > dd {
      @include text;

      font-size: 1.75rem;
    }

    > .hint {
      color: $ui-color-600;
      font-size: 0.75rem;
      line-height: 0;
      margin-bottom: -0.8rem;
    }
  }

  .kpi-value {
    white-space: nowrap;
  }
}

.card-tag {
  align-items: center;
  background-color: $ui-color-100;
  color: $system-color-error;
  display: flex;
  font-style: normal;
  justify-content: flex-start;
  margin-top: 0.25rem;
  padding: 0.5rem;

  &.positive {
    color: $supportive-color-500;
  }
}
