.scoop-types {
  margin-left: -0.5rem;
  margin-top: 0.5rem;
  padding-left: 0;
  padding-right: 0;

  > * { // FormCards
    .checkboxes {
      margin: 0;
      padding-left: 0;
      padding-right: 1rem;
    }
  }
}
