@import 'src/assets/stylesheets/design-system/colors';

.companies-container {
  display: flex;
  flex-wrap: wrap;
  left: -0.25rem;
  margin-bottom: 1rem;
  position: relative;

  .company {
    background-color: $primary-color-100;
    border-radius: 0.25rem;
    color: $ui-color-black;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin: 0.25rem;
    padding: 0.25rem 0.5rem;
    text-decoration: none;
    width: auto;

    &:hover {
      background-color: $primary-color-500;
      color: $ui-color-white;
    }
  }
}
