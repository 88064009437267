@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/shadows';
@import 'src/assets/stylesheets/variables';

.card {
  background-color: $ay-white-color;
  border: 0.0625rem solid $ay-grey-16-color;
  border-radius: $ay-border-radius;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  position: relative;

  &.small {
    min-height: 7.5rem;
  }

  &.regular {
    min-height: 12rem;
  }

  &:hover {
    box-shadow: $shadow-level-1;
  }

  &.extra-small {
    background-color: transparent;
    border: 0;
    min-height: auto;
    padding: 0.5rem;

    &:hover {
      box-shadow: none;
    }
  }
}

.header-wrapper {
  display: inline-block;
  padding-right: 2.5rem;
}

.sign-date {
  color: $ay-grey-40-color;
  font-size: 0.75rem;
  line-height: 1rem;
}

.primary-address {
  color: $primary-color-900;
  display: flex;
  font-size: 1.25rem;
  line-height: 2rem;
  margin: 0;
}

.background-ay-frozen-grass-color {
  background-color: $ay-frozen-grass-color;
}

.background-ay-white-color {
  background-color: $ay-white-color;
}

.background-ay-pepa-pig-red-color {
  background-color: $ay-pepa-pig-red-color;
}

.background-ay-grey-8-color {
  background-color: $ay-grey-8-color;
}

.background-ay-gold-8-color {
  background-color: $ay-gold-8-color;
}

.background-supportive-color-100 {
  background-color: $supportive-color-100;
}

.header-with-property {
  justify-content: space-between;
  margin-bottom: 1rem;
  min-height: 2.5rem;
  padding-bottom: 0.5rem;
  position: relative;

  &::before {
    background: $ay-grey-16-color;
    bottom: 0;
    content: '';
    height: 0.0625rem;
    left: 0;
    position: absolute;
    right: -2.5rem;
  }

  .header-container {
    display: flex;
    flex-direction: row;
    max-height: 4rem;
    width: 100%;
  }

  .image {
    border-radius: $ay-border-radius;
    height: 3rem;
    width: 3rem;
  }

  .image,
  .map {
    margin-right: 1rem;
  }

  .content-title {
    flex: 1 1 100%;
    min-width: 0;
    vertical-align: top;
  }

  .title {
    color: $ay-black-color;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
    margin: 0;
    padding-bottom: 0.25rem;
    text-decoration: none;

    &:hover {
      color: $primary-color-400;
    }
  }

  .title-icon {
    margin-top: 0.06rem;
    width: 1rem;
  }

  .subtitle {
    color: $ay-grey-64-color;
    font-size: 0.75rem;
    line-height: 1rem;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .action-buttons {
    .act-button {
      background-color: transparent;
      border: 0;
      border-radius: 0.25rem;
      cursor: pointer;
      height: 1.25rem;
      padding: 0.75rem;
      width: 1.25rem;
    }

    .delete-button {
      @include eager-background-image(
        'icons/delete.svg',
        'icons/delete-white.svg'
      );

      background-position: center;
      background-repeat: no-repeat;
      margin-right: 1rem;

      &:hover {
        background-color: $ay-main-red-color;
      }
    }

    .edit-button {
      background:
        url('../../assets/images/icons/edit-icon-main-blue.svg')
        no-repeat center;
      background-size: 1.25rem;

      &:hover {
        background-color: $primary-color-100;
      }
    }
  }
}

.company-name {
  display: inline-block;

  &:not(:first-child) {
    margin-left: 0.125rem;
  }
}

.body-line {
  color: $ay-black-color;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-top: 0.25rem;
  text-decoration: none;
}

.headline {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.subheadline {
  font-size: 1rem;
  font-weight: lighter;
  margin-left: 0.5rem;
}

.metadata {
  color: $ay-grey-40-color;
  font-size: 0.8rem;
  line-height: 1rem;
}

.link {
  color: $primary-color-500;
  font-weight: bold;
  text-decoration: none;
}

.dropdown.dropdown {
  position: absolute;
  right: 1rem;
}

.extra-small {
  .metadata {
    font-size: 0.675rem;
    line-height: 1rem;
    padding-bottom: 0.25rem;
  }

  .body-line {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}
