@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.container {
  max-height: 30rem;
  overflow-x: hidden;

  .header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin: 0 3rem 0.5rem 0;
    min-height: 1.5rem;
  }

  .content-overflow {
    max-height: 22rem;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .title-container {
    max-width: 28rem;
  }

  .title {
    color: $ay-black-color;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
    margin: 0;
  }

  .subtitle {
    @include text-truncate(30rem);

    color: $ui-color-600;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
    padding-bottom: 1rem;
  }

  .loading {
    align-items: center;
    display: flex;
    justify-content: center;

    .loading-label {
      margin-left: 1rem;
    }
  }

  [class*='text-margin-left'] {
    margin-left: 0.8rem;
  }

  .card-item {
    position: relative;
  }

  .card-item:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
