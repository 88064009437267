// New design system colors
$primary-color-100: #eae9f3;
$primary-color-200: #cecde3;
$primary-color-300: #a7a3cd;
$primary-color-400: #817db8;
$primary-color-500: #55489e;
$primary-color-600: #3b377a;
$primary-color-700: #21214b;
$primary-color-800: #0d0c33;
$primary-color-900: #06061a;

$secondary-color-100: #f7deca;
$secondary-color-200: #f2c29f;
$secondary-color-300: #eca57a;
$secondary-color-400: #e68d59;
$secondary-color-500: #ef6528;
$secondary-color-600: #b05a2c;
$secondary-color-700: #8c4722;
$secondary-color-800: #5a2e16;
$secondary-color-900: #2e180b;

$supportive-color-100: #dcebe4;
$supportive-color-200: #c0dcd1;
$supportive-color-300: #a3cdbc;
$supportive-color-400: #84c0a9;
$supportive-color-500: #4db595;
$supportive-color-600: #447f6b;
$supportive-color-700: #2d594a;
$supportive-color-800: #1c322b;
$supportive-color-900: #0f1b18;

$supportive-blue-400: #5fb1ff;
$supportive-pink-500: #ec407a;

$ui-color-white: #fff;
$ui-color-50: #fafafa;
$ui-color-100: #efeff1;
$ui-color-200: #dfdee3;
$ui-color-200-ligther: #f8f8f9;
$ui-color-300: #c7c5cb;
$ui-color-400: #9b9ba5;
$ui-color-500: #777886;
$ui-color-600: #585c6c;
$ui-color-700: #3a4458;
$ui-color-800: #222a3a;
$ui-color-900: #12161f;
$ui-color-black: #000;

$system-color-error: #dc1631;
$system-color-success: #2fbd42;
$system-color-warning: #f5ad1d;

// Old design system colors
$ay-navy-blue-color: #010e28;
$ay-main-blue-color: #00205b;
$ay-night-blue-color: #0337a0;
$ay-madrid-blue-color: #0553f0;
$ay-berlin-blue-color: #73a1fc;
$ay-london-blue-color: #c3d7fe;

$ay-wine-red-color: #6e130c;
$ay-main-red-color: #da291c;
$ay-ellie-red-color: #f9a59f;
$ay-pepa-pig-red-color: #fddbd9;

$ay-leaf-green-color: #31725c;
$ay-grass-green-color: #40b58e;
$ay-pear-green-color: #90e0c2;
$ay-frozen-grass-color: #d2efe5;

$ay-pure-white-color: #fff;
$ay-white-color: #fcfcfc;
$ay-black-color: #131416;
$ay-total-black-color: #000;

$ay-grey-80-color: #383c42;
$ay-grey-64-color: #5e636e;
$ay-grey-40-color: #868c98;
$ay-grey-24-color: #b2b6bd;
$ay-grey-16-color: #d8dade;
$ay-grey-8-color: #f4f4f5;
$ay-winter-grey-color: #dbdbd3;

$ay-success-color: #2cc92c;
$ay-error-color: #f02405;
$ay-warning-color: #f2952c;

$ay-violet-color: #9291c4;
$ay-dark-gold-color: #a05b0d;
$ay-main-gold-color: #f18f1f;
$ay-gold-8-color: #f9e1c6;

$primary-on-dark: #a08fff;
