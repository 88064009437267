@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

$container-height: 2.5rem;

.container {
  height: $container-height;
}

.date-input {
  @include input-border-style;

  align-items: center;
  background-color: $ui-color-white;
  border-radius: 0.25rem;
  display: flex;
  height: $container-height;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 10rem;

  &.disabled {
    opacity: 0.4;
  }

  &.focus {
    border-color: $primary-color-400;
  }

  &.focus.error,
  &.error {
    border-color: $ay-error-color !important;
  }

  input {
    background-color: transparent;
    border: 0;
    font-size: 1rem;
    text-align: center;

    &:focus {
      outline: none;
    }
  }

  .month,
  .day {
    height: 100%;
    width: 2.5rem;
  }

  .year {
    height: 100%;
    width: 3.5rem;
  }

  .separator {
    color: $ay-grey-64-color;
    font-size: 1rem;
  }
}

.error-message {
  color: $ay-error-color;
  display: block;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0;
  margin-top: 0.25rem;
  padding: 0;
  position: absolute;
}
