@import 'src/assets/stylesheets/design-system/colors';

.legend {
  align-self: center;
  color: $ay-grey-64-color;
  padding: 0 1rem;

  &.disabled {
    opacity: 0.4;
  }
}
