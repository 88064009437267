@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/shadows';

.container {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow-y: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $modal-z-index;
}

.backdrop {
  background: $ay-grey-80-color;
  bottom: 0;
  height: 100vh;
  left: 0;
  opacity: 0.8;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: $modal-z-index;
}

.background-ay-white-color {
  background-color: $ay-white-color;
}

.background-ay-pure-white-color {
  background-color: $ay-pure-white-color;
}

.modal {
  border-radius: 0.25rem;
  box-shadow: $shadow-level-3;
  box-sizing: border-box;
  max-height: 25rem;
  overflow-y: auto;
  padding: 2rem;
  position: relative;
  width: 34rem;
  z-index: $modal-z-index;

  &-with-opacity {
    background-color: rgba($ay-pure-white-color, 0.96);
  }

  &-large {
    padding: 1rem;
    width: 46rem;
  }

  &-extra-large {
    padding: 1rem;
    width: 58rem;
  }

  &-dark {
    background-color: rgba(19, 20, 22, 0.96);
  }

  &-full-screen {
    border-radius: 0;
    min-height: 100%;
    min-width: 100%;
    position: fixed;
  }

  &-no-padding {
    padding: 0;
  }
}

.overflow-visible {
  max-height: none;
  overflow-y: visible;
}

.close-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 1rem;
  top: 1.25rem;

  &.with-header {
    position: sticky;
    right: 0.25rem;
  }

  .close-icon {
    height: 1rem;
    width: 1rem;
  }

  .close-label {
    color: $ay-grey-64-color;
    line-height: 1rem;
    padding-right: 0.5rem;
  }

  &.close-dark-mode {
    border-radius: 0.25rem;
    font-size: 1rem;
    height: 1.5rem;
    margin-right: 1rem;
    margin-top: 1rem;
    padding-left: 0.325rem;
    width: 4rem;

    .close-icon {
      content: url('../../assets/images/icons/close-modal-white.svg');
    }

    .close-label {
      color: $ay-white-color;
    }

    &:hover {
      background-color: $ay-black-color;

      .close-label {
        color: $ay-main-red-color;
      }

      .close-icon {
        content: url('../../assets/images/icons/close-modal-red.svg');
      }
    }
  }
}

.content {
  display: block;
}

.footer {
  background-color: $ui-color-white;
  bottom: 0;
  position: sticky;
}

.header {
  align-items: center;
  background-color: $ui-color-white;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: $z-index-1;

  .header-text {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    margin: 0;
  }
}
