@import 'src/assets/stylesheets/design-system/colors';

.container {
  background-color: $primary-color-900;
  font-size: 0.75rem;
  margin: 0 auto;
  margin: 0.75rem;
  position: relative;
}

.graph {
  height: 100%;
  width: 100%;
}

.legend-wrapper {
  display: inline-flex;
  flex-direction: column;
  position: absolute;
}

.top-legend-wrapper {
  left: 0.5rem;
  top: 1rem;
}

.bottom-legend-wrapper {
  bottom: 2.25rem;
  left: 0.75rem;
}

.legend {
  margin-bottom: 0.375rem;
}

.label {
  color: $ui-color-white;
}
