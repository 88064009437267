@import 'src/assets/stylesheets/design-system/colors';

// segment control notification
.segment {
  &-control {
    background-color: $ui-color-100;
    border-radius: 0.25rem;
    display: flex;
    gap: 0.5rem;
    padding: 1rem;
  }

  &-title {
    color: $ui-color-400;
  }

  &-description {
    color: $ui-color-600;
  }
}

//rent type time measurement controls
.radio-controls {
  display: flex;
  justify-content: space-between;

  &-section {
    display: flex;
    gap: 0.5rem;
  }
}
