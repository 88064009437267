@import 'src/assets/stylesheets/design-system/colors';

.button {
  border: 0;
  border-radius: 0.25rem;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;

  &:disabled {
    cursor: default;
  }

  &-icon {
    pointer-events: none;
  }

  &-full-width {
    width: 100%;
  }

  &-min-width {
    min-width: 10rem;
  }

  &-with-text-and-icon {
    align-items: center;
    display: flex;
    justify-content: center;

    &.reverse,
    &.between {
      flex-direction: row-reverse;
    }

    &.between {
      justify-content: space-between;
    }

    .text-margin-right {
      margin-right: 0.5rem;
    }

    .text-margin-left {
      margin-left: 0.5rem;
    }

    .text-padding {
      padding-right: 0.5rem;
    }
  }

  // sizes

  // @TODO - add missing sizes

  &-size-xs {
    font-size: 0.75rem;
    line-height: 1rem;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;

    .text-margin-left {
      margin-left: 0.5rem;
    }
  }

  &-size-s {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;

    .text-margin-left {
      margin-left: 0.5rem;
    }
  }

  &-size-m {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }

  &-size-l {
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
  }

  &-size-xl {
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 1rem;
  }

  // types

  // @TODO - add missing types

  &-type-main {
    background-color: $primary-color-500;
    color: $ay-white-color;

    .truncate {
      background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
    }

    &:disabled {
      background-color: $ay-grey-8-color;
      color: $ay-grey-24-color;

      .truncate {
        background-image: linear-gradient(90deg, $ay-grey-24-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        background-color: $primary-color-400;
        color: $ay-white-color;

        .truncate {
          background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
        }
      }

      &:hover:focus.active {
        background-color: $primary-color-900;
      }
    }
  }

  &-type-secondary {
    background-color: transparent;
    border: 0.0625rem solid $primary-color-200;
    color: $primary-color-400;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $primary-color-400 85%, rgba(0, 0, 0, 0));
    }

    &:disabled {
      border: 0.0625rem solid $ay-grey-16-color;
      color: $ay-grey-24-color;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ay-grey-24-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        background-color: $primary-color-500;
        border: 0.0625rem solid $primary-color-500;
        color: $ay-white-color;

        .truncate {
          background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
        }
      }

      &:hover:focus.active {
        background-color: $primary-color-400;
        color: $ay-white-color;

        .truncate {
          background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
        }
      }
    }
  }

  &-type-ghost {
    background-color: transparent;
    color: $primary-color-500;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $primary-color-500 85%, rgba(0, 0, 0, 0));
    }

    &:disabled {
      color: $ay-grey-24-color;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ay-grey-24-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        background-color: $primary-color-100;
        color: $primary-color-500;

        .truncate { /* stylelint-disable-line */
          background-image: linear-gradient(90deg, $primary-color-500 85%, rgba(0, 0, 0, 0));
        }
      }

      &:hover:focus.active {
        background-color: $primary-color-500;
        color: $ay-white-color;

        .truncate {
          background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
        }
      }
    }
  }

  &-type-ghost-light {
    background-color: transparent;
    color: $primary-color-100;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $primary-color-100 85%, rgba(0, 0, 0, 0));
    }

    &:disabled {
      color: $ay-grey-24-color;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ay-grey-24-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        background-color: $primary-color-100;
        color: $primary-color-500;

        .truncate { /* stylelint-disable-line */
          background-image: linear-gradient(90deg, $primary-color-500 85%, rgba(0, 0, 0, 0));
        }
      }

      &:hover:focus.active {
        background-color: $primary-color-500;
        color: $ay-white-color;

        .truncate {
          background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
        }
      }
    }
  }

  &-type-contextual {
    background-color: $primary-color-100;
    color: $primary-color-500;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $primary-color-500 85%, rgba(0, 0, 0, 0));
    }

    &:disabled {
      background-color: $ay-grey-8-color;
      color: $ay-grey-24-color;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ay-grey-24-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        background-color: $primary-color-500;
        color: $ay-white-color;

        .truncate { /* stylelint-disable-line */
          background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
        }
      }

      &:hover:focus.active {
        background-color: $primary-color-900;
        color: $ay-white-color;

        .truncate {
          background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
        }
      }
    }
  }

  &-type-contextual-transparent {
    background-color: transparent;
    color: $primary-color-500;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $primary-color-500 85%, rgba(0, 0, 0, 0));
    }

    &:disabled {
      background-color: $ay-grey-8-color;
      color: $ay-grey-24-color;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ay-grey-24-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        background-color: $primary-color-500;
        color: $ay-white-color;

        .truncate { /* stylelint-disable-line */
          background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
        }
      }

      &:hover:focus.active {
        background-color: $primary-color-900;
        color: $ay-white-color;

        .truncate {
          background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
        }
      }
    }
  }

  &-type-contextual-light {
    background-color: $ay-white-color;
    color: $primary-color-500;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $primary-color-500 85%, rgba(0, 0, 0, 0));
    }

    &:disabled {
      background-color: $ay-grey-8-color;
      color: $ay-grey-24-color;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ay-grey-24-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        background-color: $primary-color-100;
        color: $primary-color-500;

        .truncate { /* stylelint-disable-line */
          background-image: linear-gradient(90deg, $primary-color-500 85%, rgba(0, 0, 0, 0));
        }
      }

      &:hover:focus.active {
        background-color: $primary-color-500;
        color: $ay-white-color;

        .truncate {
          background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
        }
      }
    }
  }

  &-type-contextual-positive {
    background-color: $primary-color-100;
    color: $primary-color-500;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $primary-color-500 85%, rgba(0, 0, 0, 0));
    }

    &:hover,
    &:focus {
      background-color: $primary-color-500;
      color: $ui-color-white;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ui-color-white 85%, rgba(0, 0, 0, 0));
      }
    }

    &:active {
      background-color: $ui-color-black;
      color: $ay-white-color;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:disabled {
      background-color: $ui-color-100;
      color: $ui-color-400;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ui-color-400 85%, rgba(0, 0, 0, 0));
      }
    }
  }

  &-type-positive {
    background-color: $supportive-color-100;
    color: $supportive-color-700;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $supportive-color-700 85%, rgba(0, 0, 0, 0));
    }

    &:hover,
    &:focus {
      background-color: $supportive-color-500;
      color: $ui-color-white;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ui-color-white 85%, rgba(0, 0, 0, 0));
      }
    }

    &:active {
      background-color: $supportive-color-700;
      color: $ay-white-color;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:disabled {
      background-color: $ui-color-100;
      color: $ui-color-400;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ui-color-400 85%, rgba(0, 0, 0, 0));
      }
    }
  }

  &-type-link {
    background-color: transparent;
    color: $primary-color-400;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $primary-color-400 85%, rgba(0, 0, 0, 0));
    }

    &:disabled {
      color: $ay-grey-24-color;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ay-grey-24-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        color: $primary-color-500;
        text-decoration: underline;
        text-underline: $primary-color-100;

        .truncate { /* stylelint-disable-line */
          background-image: linear-gradient(90deg, $primary-color-500 85%, rgba(0, 0, 0, 0));
        }
      }
    }
  }

  &-type-link-strong {
    background-color: transparent;
    color: $primary-color-500;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $primary-color-500 85%, rgba(0, 0, 0, 0));
    }

    &:disabled {
      color: $ay-grey-24-color;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ay-grey-24-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        color: $primary-color-600;
        text-decoration: underline;
        text-underline: $primary-color-100;

        .truncate { /* stylelint-disable-line */
          background-image: linear-gradient(90deg, $primary-color-600 85%, rgba(0, 0, 0, 0));
        }
      }
    }
  }

  &-type-delete {
    background-color: transparent;
    color: $ay-error-color;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $ay-error-color 85%, rgba(0, 0, 0, 0));
    }

    &:disabled {
      color: $ay-grey-24-color;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ay-grey-24-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        background-color: $ay-error-color;
        color: $ay-white-color;

        .truncate { /* stylelint-disable-line */
          background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
        }
      }

      &:hover:focus.active {
        background-color: $ay-error-color;
        color: $ay-white-color;

        .truncate {
          background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
        }
      }
    }
  }

  &-type-delete-neutral {
    background-color: $ui-color-100;
    color: $ay-error-color;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $ay-error-color 85%, rgba(0, 0, 0, 0));
    }

    &:disabled {
      color: $ay-grey-24-color;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ay-grey-24-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        background-color: $ay-error-color;
        color: $ay-white-color;

        .truncate { /* stylelint-disable-line */
          background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
        }
      }

      &:hover:focus.active {
        background-color: $ay-error-color;
        color: $ay-white-color;

        .truncate {
          background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
        }
      }
    }
  }

  &-type-supportive-neutral {
    background-color: $ui-color-100;
    color: $ui-color-black;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $ui-color-black 85%, rgba(0, 0, 0, 0));
    }

    &:hover,
    &:focus {
      background-color: $ui-color-600;
      color: $ui-color-white;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ui-color-white 85%, rgba(0, 0, 0, 0));
      }
    }

    &:active {
      background-color: $ui-color-black;
      color: $ay-white-color;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ay-white-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:disabled {
      background-color: $ui-color-100;
      color: $ui-color-400;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ui-color-400 85%, rgba(0, 0, 0, 0));
      }
    }
  }

  &-type-navigation {
    background-color: transparent;
    color: $ui-color-500;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $ui-color-500 85%, rgba(0, 0, 0, 0));
    }

    &:hover,
    &:focus {
      background-color: $primary-color-500;
      color: $ay-pure-white-color;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ay-pure-white-color 85%, rgba(0, 0, 0, 0));
      }
    }

    &:active {
      background-color: transparent;
      color: $ui-color-500;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ui-color-500 85%, rgba(0, 0, 0, 0));
      }
    }

    &:disabled {
      background-color: $primary-color-100;
      color: $primary-color-500;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $primary-color-500 85%, rgba(0, 0, 0, 0));
      }
    }
  }

  &-type-upload {
    background-color: $ay-grey-8-color;
    color: $ui-color-600;

    .truncate { /* stylelint-disable-line */
      background-image: linear-gradient(90deg, $ui-color-600 85%, rgba(0, 0, 0, 0));
    }

    &:hover,
    &:focus {
      background-color: $ay-grey-8-color;
      color: $ui-color-600;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ui-color-600 85%, rgba(0, 0, 0, 0));
      }
    }

    &:active {
      background-color: $ay-grey-8-color;
      color: $ui-color-600;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ui-color-600 85%, rgba(0, 0, 0, 0));
      }
    }

    &:disabled {
      background-color: $ay-grey-8-color;
      color: $ui-color-600;

      .truncate { /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ui-color-600 85%, rgba(0, 0, 0, 0));
      }
    }
  }
}
