@import 'src/assets/stylesheets/design-system/colors';

.action-buttons {
  display: flex;
  gap: 0.625rem;
  justify-content: center;
  margin: 0.5rem 0;

  .action-button {
    font-size: 0.875rem;
    font-weight: 600;
    min-height: unset;
    min-width: unset;
    padding: 0.375rem 0.5rem;
  }
}

.free-rent-label {
  margin-right: 1.25rem;
}

.termination-penalty {
  align-items: center;
  display: flex;
  padding-left: 0;
}

.termination-row {
  align-items: center;
  display: flex;
  gap: 1.25rem;
  margin-left: 1.5rem;

  div {
    flex-wrap: nowrap;
  }
}

.initial-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.lease-options {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.option-container {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.option-button {
  margin-top: 0.25rem;
  padding: 0;
}

.option-item {
  align-items: center;
  background-color: $ui-color-200;
  border: 0.0625rem solid $ui-color-300;
  border-radius: 0.25rem;
  color: $ui-color-600;
  display: flex;
  gap: 0.625rem;
  padding: 0.25rem 0.5rem;
  width: max-content;
}
