@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/shadows';

.modal-container {
  max-height: none;
}

.picture-container {
  background: $ay-white-color;
  border: 0.0625rem solid $ay-grey-16-color;
  border-radius: 0.25rem;
  float: left;
  margin: 1rem 1rem 1rem 0;
  overflow: hidden;
  position: relative;
  width: 16rem;

  &.picture-cover {
    border: 0.0625rem solid $primary-color-900;
  }

  &:hover {
    border: 0.0625rem solid $primary-color-200;
    box-shadow: $shadow-level-1;
  }

  .container-img {
    background-size: cover;
    cursor: pointer;
    height: 7.5rem;

    .item-img {
      height: 7.5rem;
      width: 100%;
    }
  }

  .cover-icon-container {
    align-items: center;
    background: $primary-color-900;
    display: flex;
    height: 1.5rem;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 1.5rem;
  }
}

.actions-container {
  align-items: center;
  background-color: $ay-white-color;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

  .category {
    color: $ay-grey-80-color;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    margin: 0;
    padding-left: 0.5rem;

    .sub-category {
      @include text-truncate(9rem);

      color: $ay-grey-64-color;
      line-height: 1rem;
    }
  }

  .action-buttons {
    margin: 0.5rem;
  }

  .image-edit-btn {
    background: $ay-grey-8-color url('../../../../assets/images/icons/edit-icon-night-blue.svg') no-repeat top 0.25rem left 0.25rem;
    margin-right: 0.25rem;
  }

  .image-download-btn {
    background: $ay-grey-8-color url('../../../../assets/images/icons/download.svg') no-repeat top 0.25rem left 0.25rem;
    margin-right: 0.25rem;
  }

  .image-delete-btn,
  .image-edit-btn,
  .image-download-btn {
    background-size: 1rem;
    height: 1.5rem;
    width: 1.5rem;
  }

  .image-delete-btn {
    @include eager-background-image(
      'icons/delete.svg',
      'icons/delete-white.svg'
    );

    background-color: $ay-grey-8-color;
    background-position: top 0.25rem left 0.25rem;
    background-repeat: no-repeat;
    background-size: 1rem;

    &:hover {
      background-color: $ay-main-red-color;
    }
  }
}

.edit-tags-buttons-container {
  display: grid;
  grid-column-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
}

.edit-note-container {
  margin-bottom: 2rem;

  .edit-note-input {
    margin-top: 0.5rem;
  }

  textarea {
    border: 0.0625rem solid $ay-grey-16-color;
    border-radius: 0.25rem;
    height: 5.125rem;
    resize: none;
  }
}
