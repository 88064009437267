@import 'src/assets/stylesheets/design-system/colors';

.radio-group-title {
  color: $ay-grey-80-color;
  font-size: 1rem;
  line-height: 1.5rem;
}

.radio-group-container {
  display: flex;
  flex-flow: wrap;
  row-gap: 0.75rem;
  width: 100%;

  &.vertical {
    width: auto;
  }

  .radio-group-item {
    background-color: $ui-color-white;
    border: 0.0625rem solid $ay-grey-16-color;
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: $ay-grey-64-color;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 0.75rem 0.75rem 0;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: capitalize;
    user-select: none;
    white-space: nowrap;

    &.vertical {
      margin: 0 0 0.25rem;
      text-align: left;
    }

    &.regular-size {
      height: 2.5rem;
      margin-bottom: 0;
      padding: 0.5rem 1.5rem;
    }

    &.big-size {
      height: 3rem;
      min-width: 13rem;
      padding: 0.75rem 1.5rem;
    }

    &:hover:not(.disabled) {
      border: 0.0625rem solid $ay-grass-green-color;
      color: $primary-color-900;
    }
  }

  .disabled {
    background-color: $ui-color-white;
    border: 0.0625rem solid $ay-grey-16-color;
    color: $ay-grey-64-color;
    cursor: initial;
    opacity: 0.4;
  }

  .selected {
    background-color: $ay-frozen-grass-color;
    border: 0.0625rem solid $ay-grass-green-color;
    color: $primary-color-900;
  }
}

.padded {
  padding-top: 0.6rem;
}

.three-line-padded {
  padding-top: 1.2rem;
}

.suggested-title {
  width: 100%;
}
