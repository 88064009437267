@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.link {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 0.5rem;
  padding: 0;

  &,
  &:visited {
    color: $primary-color-400;
  }
}

.footer {
  color: $ay-grey-80-color;
  font-size: 0.75rem;
  line-height: 1rem;
  position: relative;
}

.card-separator {
  @include card-separator;

  margin: 0.5rem 0;
}

@mixin btn {
  border: 0.0625rem solid $ay-grey-16-color;
  color: $ui-color-600;
  margin-bottom: 1rem;
  padding: 0.2rem 1rem;

  &:hover {
    color: $ui-color-600;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      color: $ui-color-600;
      text-decoration: none;
      text-underline: $primary-color-100;

      .truncate {
        /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ui-color-600 85%, rgba(0, 0, 0, 0));
      }
    }
  }
}

.content {
  .user-wrapper {
    margin: 0;
    padding: 1rem 0;
  }

  .btn-card {
    @include btn;
  }

  .btn-card-mobile {
    @include btn;

    margin-bottom: 0;
  }

  .mobile-section-container {
    display: none;
  }

  .text {
    font-size: 1rem;
  }
}

.section-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  &:hover {
    cursor: pointer;
  }
}

.tag-share {
  background-color: $ui-color-200;
}

.share {
  background-color: $ay-grey-8-color;
}

.scoop-media {
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.25rem;
  height: 23rem;
  margin-bottom: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.scoop-media-expanded {
  height: 33.15rem;
}

.see-documents-button {
  padding: 0.5rem 1rem;
}

@media only screen and (max-width: 48rem) {
  .content {
    .btn-card {
      display: none;
    }

    .mobile-section-container {
      display: block;

      .btn-card-mobile {
        margin-right: 1rem;
      }
    }
  }
}
