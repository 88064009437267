@import 'src/assets/stylesheets/design-system/colors';

$footer-height: 3.5rem;

.modal-container {
  background-color: $ay-grey-8-color;
  margin-bottom: $footer-height;
  max-height: 40rem;
  min-height: 40rem;
  padding: 1rem 1rem 0.5rem;
  width: 46rem;
}

.header {
  color: $ay-grey-64-color;
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1.5rem;
  position: relative;

  .header-actions-container {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.action-button {
  background-color: transparent;
  border: 0;
  font-size: 1rem;

  &:disabled {
    background-color: transparent;
    color: $ay-grey-24-color;
  }
}

.reset-market {
  line-height: 1rem;
  margin-right: 0.25rem;
}

.remove-selection {
  line-height: 1.5rem;

  &:not(:disabled) {
    &:hover {
      background-color: $ay-error-color;
    }
  }
}

.search-input-container {
  display: block;
  position: relative;

  .search-input {
    background:
      $ay-white-color
      url('../../assets/images/icons/search-grey-64.svg') no-repeat 1rem 50%;
    background-size: 1rem;
    width: 100%;
  }
}

.options-label-container {
  display: flex;

  .flag-image {
    border-radius: 50%;
    height: 1.313rem;
    margin-right: 0.5rem;
    width: 1.313rem;
  }

  .options-label {
    color: $ay-grey-80-color;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }
}

.options {
  display: block;
  margin-top: 1.5rem;
  max-height: 28rem;
  overflow: auto;
}

.select-deselect {
  left: 10rem;
  position: absolute;
}

.modal-footer {
  align-items: center;
  background-color: $ay-white-color;
  bottom: 0;
  display: flex;
  height: $footer-height;
  justify-content: center;
  left: 0;
  margin: auto;
  position: absolute;
  width: 100%;
}

.radio-item {
  max-width: 10.25rem;
  width: 10.25rem;
}

.tab-button {
  font-size: 1rem;
  height: 2.5rem;
  line-height: 1.5rem;
  width: 7.5rem;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}
