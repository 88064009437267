@import 'src/assets/stylesheets/design-system/colors';

.container {
  background-color: $primary-color-900;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  justify-content: center;
  margin: 0.75rem;
  min-height: 10.5rem;
}

.top-bar {
  margin-bottom: 0.5rem;
}

.title-legend {
  margin-left: 0.5rem;
}

.graph-container {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.availability-legend {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
