@import 'src/assets/stylesheets/design-system/colors';

.transaction-volume-container {
  cursor: default;
  height: 17.5rem;
}

.svg-container {
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;

  .svg-graph {
    margin-left: 1.5rem;
    margin-top: 1.75rem;
    overflow: visible;

    .year-value {
      fill: $ay-white-color;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
}

.title {
  align-self: start;
  color: $ay-pure-white-color;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin: 1.5rem 1.25rem 0;
}

.legend-top,
.legend-bottom {
  color: $ay-white-color;
  font-size: 0.75rem;
  left: 1.5rem;
  line-height: 1rem;
  position: absolute;
}

.legend-bottom {
  bottom: 1.75rem;
}

.legend-top {
  top: 2.75rem;
}

.tooltip-container {
  border-radius: 0.25rem;
  color: $ui-color-white;
  float: right;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.25rem 0.5rem;
  position: absolute;
  right: 1.5rem;

  &.purchased {
    background-color: $supportive-color-500;
    top: 1.5rem;
  }

  &.sold {
    background-color: $primary-color-500;
    bottom: 1.5rem;
  }

  .tooltip-main-text {
    font-weight: 600;
  }

  .tooltip-separator {
    font-weight: 600;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
