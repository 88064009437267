@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/shadows';

.container {
  max-height: max-content;
  padding-bottom: 1rem;
}

.edit-tags-buttons-container {
  display: grid;
  grid-column-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
}

.document-container {
  border: 0.1rem solid $ay-grey-16-color;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  min-height: 100%;

  &:hover {
    border: 0.0625rem solid $primary-color-200;
    box-shadow: $shadow-level-1;
  }
}

.preview-container {
  align-items: center;
  background-color: $ay-grey-8-color;
  display: flex;
  flex: 1;
  justify-content: center;

  img {
    object-fit: contain;
    width: 100%;
  }

  .document-icon {
    width: 50%;
  }
}

.info-container {
  display: flex;
  flex: 3;
  flex-direction: column;
  min-width: 0;
  padding: 1rem 0.5rem;
}

.header-container {
  display: flex;
  flex-direction: row;
}

.document-name-container {
  flex: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.document-actions-container {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;

  .document-edit-btn {
    background:
      $ay-grey-8-color
      url('../../../../assets/images/icons/edit-icon-night-blue.svg') no-repeat
      top 0.25rem left 0.25rem;
    margin-right: 0.25rem;
  }

  .document-download-btn {
    background:
      $ay-grey-8-color
      url('../../../../assets/images/icons/download.svg') no-repeat top 0.25rem
      left 0.25rem;
    margin-right: 0.25rem;
  }

  .document-delete-btn,
  .document-edit-btn,
  .document-download-btn {
    background-size: 1rem;
    height: 1.5rem;
    width: 1.5rem;
  }

  .document-delete-btn {
    @include eager-background-image(
      'icons/delete.svg',
      'icons/delete-white.svg'
    );

    background-color: $ay-grey-8-color;
    background-position: top 0.25rem left 0.25rem;
    background-repeat: no-repeat;
    background-size: 1rem;

    &:hover {
      background-color: $ay-main-red-color;
    }
  }
}

.category-container {
  color: $ay-grey-40-color;
  display: flex;
  font-size: 0.75rem;
}

.user-container {
  color: $ay-grey-40-color;
  display: flex;
  font-size: 0.75rem;

  span {
    color: $primary-color-500;
  }
}
