/* stylelint-disable */
@import 'src/assets/stylesheets/css-library/brand/fonts';
@import 'src/assets/stylesheets/design-system/colors';

.wrapper {
  color: $ay-grey-64-color;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: normal;

  .label {
    color: $ay-grey-64-color;
    display: block;
    margin-bottom: 0.5rem;
  }

  input,
  textarea {
    background-color: $ay-white-color;
    border: 1px solid $ay-grey-24-color;
    border-radius: 0.125rem;
    box-sizing: border-box;
    color: $ay-grey-64-color;
    font-size: 1rem;
    max-width: 100%;
    outline: none;
    padding: 0.5rem 1rem;
    width: 100%;

    &.error-status {
      border-color: $ay-error-color;
    }

    &.success-status {
      border-color: $ay-success-color;
    }
  }

  .status-message {
    font-size: 0.75rem;
    margin-top: 0.5rem;

    &.status-message-success {
      color: $ay-success-color;
    }

    &.status-message-error {
      color: $ay-error-color;
    }
  }
}

/* stylelint-enable */
