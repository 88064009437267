.modal-container.links-container {
  height: 30rem;
  max-height: 30rem;
  min-width: 46rem;
  overflow-y: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 44rem;
}
