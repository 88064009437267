@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.container {
  height: 37rem;
  position: relative;

  &.attribution-hidden {
    :global(.mapboxgl-control-container) {
      display: none;
    }
  }
}

.map {
  border-radius: 0.5rem;
  height: 100%;
  margin-bottom: 2.5rem;
  width: auto;

  * {
    outline: none;
  }
}

@mixin map-buttons-style {
  background-color: $ay-white-color;
  border: 0.0625rem solid $ay-white-color;
  cursor: pointer;
  height: 2rem;
  position: absolute;
  width: 2rem;

  &:focus {
    outline: none;
  }
}

.open-map {
  align-items: center;
  height: 4.2rem;
  left: 0;
  margin: 1rem 0 1.25rem 1rem;
  position: absolute;
  top: 0;
  width: 6rem;
  z-index: $z-index-1;

  button {
    @include map-buttons-style;

    border-radius: 0.3rem;
    color: $primary-color-500;
    font-size: 0.975rem;
    font-style: normal;
    font-weight: 600;
    height: 2.5rem;
    width: 6rem;
  }

  &.open-map-non-english {
    white-space: nowrap;

    button {
      @include map-buttons-style;

      width: 6.5rem;
    }
  }
}

.controls {
  align-items: center;
  height: 4.2rem;
  margin: 1rem 1rem 1.25rem 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 4.5rem;
  z-index: $z-index-1;

  .button-icon {
    position: absolute;
    transform: translate(-50%, -50%);
  }

  .button-up {
    @include map-buttons-style;

    border-radius: 0.25rem 0.25rem 0 0;
    left: calc(50% + 0.25rem);
  }

  .button-down {
    @include map-buttons-style;

    border-radius: 0 0 0.25rem 0.25rem;
    bottom: 0;
    left: calc(50% + 0.25rem);
  }

  .button-heatmap {
    @include map-buttons-style;

    border-radius: 0.25rem;
    left: calc(50% + 0.25rem);
    top: 4.625rem;
  }
}

.heatmap-legends {
  align-items: center;
  background-color: $ay-white-color;
  border-radius: 0.25rem;
  bottom: 1rem;
  display: flex;
  height: 2.25rem;
  justify-content: space-evenly;
  left: 1rem;
  position: absolute;
  width: 16.25rem;

  .heatmap-box-colors {
    border-radius: 0.125rem;
    height: 1.125rem;
    width: 10.625rem;
  }

  span {
    color: $ui-color-500;
    font-size: 0.75rem;
    font-weight: 400;
  }
}

.marker {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  position: absolute;

  &::before {
    @include map-marker($supportive-color-800);

    background: $supportive-color-500;
  }
}

.star-marker {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  position: absolute;

  svg {
    z-index: $z-index-1;
  }

  &::before {
    @include map-marker($primary-on-dark);

    background: $primary-on-dark;
    position: absolute;
  }
}
