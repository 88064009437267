@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/shadows';

//Tenant Card
.container {
  background-color: $ay-white-color;
  border: 0.0625rem solid $ay-grey-16-color;
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 0.5rem;
  padding: 1rem;
  position: relative;
  width: 100%;
  word-wrap: break-word;

  &:hover {
    border-color: $primary-color-200;
    box-shadow: $shadow-level-1;
    transition: 0.15s ease-in all;
  }

  .card-separator {
    @include card-separator;

    margin: 1rem 0;
  }
}

//Tenant Header
.header-wrapper {
  display: flex;
  justify-content: space-between;

  .header-content {
    display: flex;
    gap: 1rem;
  }

  .logo-container {
    img {
      border-radius: $ay-border-radius-sm;
      object-fit: contain;
    }

    .company-logo-default {
      max-height: 8rem;
      max-width: 8rem;
      min-height: 8rem;
      min-width: 8rem;
    }

    .company-logo-small {
      max-height: 3.5rem;
      max-width: 4.5rem;
      min-height: 3.5rem;
      min-width: 4.5rem;
      object-fit: fill;
    }
  }

  .action-buttons {
    align-items: center;
    display: flex;

    .act-button {
      background-color: transparent;
      border: 0;
      border-radius: 0.25rem;
      cursor: pointer;
      height: 1.25rem;
      padding: 0.75rem;
      width: 1.25rem;
    }

    .delete-button {
      @include eager-background-image('icons/delete.svg', 'icons/delete-white.svg');

      background-position: center;
      background-repeat: no-repeat;
      margin-right: 1rem;

      &:hover {
        background-color: $ay-main-red-color;
      }
    }

    .edit-button {
      padding-bottom: 0;
    }
  }
}

.header-info {
  display: flex;
  flex-direction: column;

  .title {
    color: $ay-black-color;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
    margin: 0;
    text-decoration: none;

    &:hover {
      color: $primary-color-400;
    }
  }
}

.chip {
  background-color: $ay-grey-8-color;
  border-radius: 0.25rem;
  color: $ay-grey-64-color;
  font-size: 1rem;
  line-height: 1rem;
  margin: 0;
  overflow: hidden;
  padding: 0.25rem 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:last-child.expanding-tag {
    background-color: $ay-frozen-grass-color;
  }

  &:last-child.contracting-tag {
    background-color: $ay-pepa-pig-red-color;
  }
}

.chip-margin {
  margin-left: 0.25rem;
}

// Tenant Body
.card-body {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  height: 100%;

  .tenant {
    color: $ay-black-color;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-top: 0.25rem;
    text-decoration: none;
  }

  .tenant-status {
    background-color: $ay-grass-green-color;
    border-radius: 0.25rem;
    color: $ay-pure-white-color;
    display: inline-block;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-right: 0.8rem;
    padding: 0.25rem 0.5rem;
    width: max-content;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  .hidden-notes {
    margin-top: auto;
  }

  .comments {
    color: $ay-black-color;
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .comments-title {
    font-weight: 600;
  }
}

// Common
.subtitle {
  color: $ay-grey-64-color;
  font-size: 1rem;
  margin-top: 0.25rem;
}
