@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.container {
  margin-bottom: 2rem;
}

.mapbox {
  height: 36rem;

  &.small {
    height: 2.5rem;
    width: 2.5rem;
  }
}
