@import 'src/assets/stylesheets/shadows';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/design-system/colors';

/* stylelint-disable no-descending-specificity */

@mixin contextual-light {
  background: $ay-white-color;
  color: $primary-color-500;
  padding: 0.5rem;

  &.small {
    height: 1.5rem;
    padding: 0.25rem;
    width: 1.5rem;
  }

  &.medium {
    height: 2rem;
    padding: 0.5rem;
    width: 2rem;
  }

  &:hover {
    background-color: $primary-color-100;
    color: $primary-color-500;
  }

  &.active {
    background-color: $primary-color-500;
    color: $ay-white-color;
  }
}

.options-container {
  position: relative;

  .options-button {
    align-items: center;
    border-radius: 0.25rem;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 2.5rem;
    justify-content: center;
    text-align: center;

    &.contextual {
      @include contextual-light;

      background-color: $primary-color-100;
      font-weight: 600;

      &:hover,
      &:focus {
        background-color: $primary-color-500;
        color: $ui-color-white;

        path {
          stroke: $ui-color-white;
        }
      }
    }

    &.contextual-light {
      @include contextual-light;

      &.dark {
        background-color: $ay-grey-8-color;
        color: $primary-color-500;
      }
    }

    &.contextual-positive {
      @include contextual-light;

      background-color: $primary-color-100;
      color: $primary-color-500;
      font-weight: 600;
      padding: 0.5rem;

      &:hover,
      &:focus {
        background-color: $primary-color-500;
        color: $ui-color-white;
      }

      &.active,
      &:active {
        background-color: $ui-color-black;
        color: $ay-white-color;
      }
    }

    &.main {
      background: $primary-color-500;
      color: $ay-white-color;
      padding: 0.5rem;

      &:hover {
        background-color: $primary-color-400;
      }

      &.active {
        background-color: $primary-color-900;
      }

      &.light-button {
        @include contextual-light;
      }
    }

    &.disabled {
      background-color: $ay-grey-8-color;
      color: $ay-grey-24-color;
      pointer-events: none;

      path {
        stroke: $ay-grey-24-color;
      }
    }

    .options-button-icon-container {
      align-items: center;
      display: flex;
      margin: 0 0.3rem;
    }

    .options-button-text {
      align-items: center;
      display: flex;
      font-size: 1.3rem;
      justify-content: center;
      padding-bottom: 0.8rem;
      width: 100%;
    }
  }

  .options {
    background: $ay-grey-8-color;
    border-radius: 0.25rem;
    box-shadow: $shadow-level-2;
    box-sizing: border-box;
    display: none;
    line-height: 1.5rem;
    margin-top: 0.5rem;
    min-width: 14rem;
    position: absolute;
    right: 0;
    z-index: $navbar-z-index;

    &.left {
      left: 0;
    }

    &.show {
      display: block;
    }

    &.contextual-light {
      padding: 0.25rem 0.5rem;
    }
  }

  .option {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    outline: none;
    width: 100%;

    &.contextual-light {
      padding: 0.75rem 0.5rem;

      &:hover {
        background-color: $ay-white-color;
        border-radius: 0.25rem;
        cursor: pointer;
      }

      &.option-primary-color-400 {
        color: $primary-color-400;

        &:hover {
          color: $primary-color-500;
        }
      }

      &.option-ay-main-red-color {
        color: $ay-main-red-color;

        &:hover {
          color: $ay-main-red-color;
        }
      }
    }

    &.contextual-positive {
      background-color: $ui-color-white;
      color: $ui-color-black;
      font-weight: 600;
      padding: 1rem;

      &:first-child {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }

      &:last-child {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }

      &:hover,
      &:focus {
        background-color: $ui-color-black;
        color: $ui-color-white;
      }

      &:active {
        background-color: $ui-color-black;
        color: $ay-white-color;
      }
    }

    &.main {
      padding: 0.75rem 1rem;

      &:hover {
        background-color: $primary-color-500;
        cursor: pointer;
      }

      &.option-primary-color-400 {
        color: $primary-color-400;

        &:hover {
          color: $ay-white-color;

          &:first-child {
            border-radius: 0.25rem 0.25rem 0 0;
          }

          &:last-child {
            border-radius: 0 0 0.25rem 0.25rem;
          }
        }
      }
    }
  }
}
