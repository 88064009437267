@import 'src/assets/stylesheets/design-system/colors';

.table-wrapper {
  margin-bottom: 5rem;
  margin-top: 2rem;
}

.cell-address {
  font-weight: 600;
  width: 14rem;
}

.cell-name {
  white-space: normal;
  width: 14rem;
}

.link {
  -webkit-box-orient: vertical;
  color: $ay-grey-80-color;
  cursor: pointer;
  display: -webkit-box;
  font-weight: 600;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: pre-wrap;

  &:hover {
    color: $primary-color-400;
  }
}

.scroll-position {
  bottom: 4.5rem;
}
