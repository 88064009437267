@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/navbar';

.button-label {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: $ui-color-500;
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  line-height: 150%;
  padding: 0 1.5rem;
  white-space: nowrap;

  &:hover {
    color: $primary-color-500;
    cursor: pointer;
  }

  &.opened-table {
    background-color: $ui-color-white;
    color: $primary-color-500;
  }
}

.link-title {
  align-items: center;
  color: $ui-color-black;
  display: flex;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.link-item {
  align-items: center;
  background-color: $ui-color-white;
  border: 0;
  color: $ui-color-700;
  display: flex;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  margin: 1rem 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-transform: capitalize;

  &:hover {
    color: $primary-color-500;
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
  }
}

.link-item-no-hover {
  align-items: center;
  background-color: $ui-color-white;
  border: 0;
  color: $ui-color-700;
  display: flex;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  margin: 1rem 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-transform: capitalize;
}

.arrow {
  margin-left: 0.5rem;
  margin-top: 0.1rem;
}

.menu-div {
  background-color: $ui-color-white;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(137, 136, 147, 0.32);
  display: flex;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  grid-template-columns: var(--subnavbar-grid-template-columns, repeat(2, 14.5rem));
  grid-template-rows: var(--subnavbar-grid-template-rows, repeat(3, 6.75rem));
  justify-content: center;
  left: 0;
  padding: 1.75rem 0 1.3125rem;
  position: fixed;
  top: $navbar-height + $subnavbar-height;
  width: 100%;
}

.separator {
  background: $ui-color-100;
  height: 0.0625rem;
  width: 6rem;
}

.menu-group {
  position: relative;
}

.search-comps {
  grid-column-end: 1;
  grid-column-start: 1;
  grid-row: 1;
}

.leases-comps {
  grid-column-end: 1;
  grid-column-start: 1;
  grid-row: 2;
  margin-top: -1.875rem;
}

.properties-comps {
  grid-column-end: 2;
  grid-column-start: 2;
  grid-row-end: 2;
  grid-row-start: 1;
}

.sales-comps {
  grid-column-end: 2;
  grid-column-start: 2;
  grid-row: 3;
  margin-top: -0.8125rem;
}
