@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/shadows';

.container-page {
  left: calc(50% - 3.5rem); // 50% - (button width / 2) to align center
  position: fixed;
  top: -2.5rem;
  transition: top 200ms ease-in-out;
  z-index: 10;

  &-visible {
    top: 7rem;
  }
}

.container-relative {
  height: 0;
  margin-left: auto;
  margin-right: auto;
  position: sticky;
  top: 1rem;
  transform: translateY(-6rem);
  transition: all 200ms ease-in-out;
  z-index: 10;

  &-visible {
    transform: translateY(0);
  }
}

.button {
  background: $primary-color-500;
  border: 0;
  border-radius: 0.25rem;
  box-shadow: $shadow-level-2;
  box-sizing: border-box;
  color: $ay-white-color;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  min-width: 7.25rem;
  opacity: 1;
  outline: none;
  padding: 0.5rem 1rem;
  text-align: center;
}
