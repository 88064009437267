@import 'src/assets/stylesheets/design-system/colors';

.container {
  background-color: $ay-grey-8-color;
  border-radius: 0.5rem;
  padding: 1rem;
  padding-right: 4.5rem;
  position: relative;

  &.disabled {
    opacity: 0.4;
  }
}

.actions {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.comment-info {
  color: $ay-grey-80-color;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0 0 0.5rem;

  .user-name {
    font-weight: 600;
  }
}

.text {
  color: $ui-color-black;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
}
