@import 'src/assets/stylesheets/design-system/colors';

.container {
  display: flex;
  flex-flow: wrap;
  gap: 0.25rem;
}

.tag {
  border-radius: 0.25rem;
  color: $ay-grey-80-color;
  display: inline-block;
  font-size: 1rem;
  font-weight: normal;
  line-height: 2rem;
  padding: 0.2rem 0.5rem;
  position: relative;
  width: auto;

  &.extra-small {
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1.5rem;
    padding: 0 0.25rem;
  }

  &.see-more {
    color: $primary-color-500;
    cursor: pointer;
  }

  .fade-tag {
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
