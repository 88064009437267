@import 'src/assets/stylesheets/design-system/colors';

.thumbnail-container {
  background-color: $ay-grey-8-color;
  display: flex;
  justify-content: center;
}

.thumbnail-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 9.4375rem;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.thumbnail-new {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 7.5rem;
  padding: 1.75rem 0;
  width: 3.125rem;

  img {
    object-fit: none;
  }
}
