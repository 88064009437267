h1 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 4rem;
}

h2 {
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
}

h3 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3rem;
}

h4 {
  font-size: 2rem;
  font-weight: normal;
  line-height: 2.5rem;
}

h5 {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.25rem;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.5rem;
  }

  h2 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 3rem;
  }

  h3 {
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 2.5rem;
  }

  h4 {
    font-size: 1.75rem;
    font-weight: normal;
    line-height: 2.25rem;
  }

  h5 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2rem;
}

.body-content {
  font-size: 1rem;
  line-height: 1.5rem;

  &.bold {
    font-weight: 500;
  }
}

.body-content-big {
  font-size: 1.25rem;
  line-height: 2rem;
}

.caption {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.label-cta {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 28px;
}

.label-button {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.5rem;

  &.small {
    font-size: 1rem;
  }
}

.label-link {
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: underline;
}
