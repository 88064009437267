@import '../../assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/variables';

.container {
  align-items: center;
  background-color: $ay-grey-8-color;
  border: 1px solid $ay-grey-8-color;
  border-radius: $ay-border-radius;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;

  &:hover {
    border: 1px solid $primary-color-200;
  }

  &.small {
    height: 5rem;
  }
}

.upload-text {
  color: $ay-grey-64-color;
  font-size: 1.25rem;
  line-height: 1.875rem;
  margin: 1.5rem;

  .strong-text {
    color: $primary-color-400;
  }
}

.upload-logo {
  display: flex;
  justify-content: center;

  .image {
    height: 3.5rem;
    width: 4.25rem;
  }
}
