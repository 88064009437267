@import 'src/assets/stylesheets/design-system/colors';

.pre-leased-container {
  display: flex;
  padding-left: 1.5rem;

  &:nth-child(2) {
    margin-top: 1.5rem;
  }

  .pre-leased-wrapper {
    display: flex;
    flex-direction: row;
  }

  .pre-leased-column {
    display: flex;
    flex: 0;
    flex-direction: row;
    min-width: 10rem;

    label {
      width: max-content;
    }

    &:nth-child(2) {
      position: relative;
    }
  }
}

.pre-leased-percentage {
  align-self: center;
  color: $ay-grey-64-color;
  padding: 0 1rem;
}

.error-message {
  color: $ay-error-color;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0;
  margin-top: 0.25rem;
  padding: 0;
  position: absolute;
  top: 100%;
  width: max-content;
}
