@import 'src/assets/stylesheets/css-library/brand/fonts';
@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/css-library/brand/grid';

.card-container {
  background-color: $ay-white-color;
  border: 1px solid $ay-grey-16-color;
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  transition: box-shadow;
  width: 100%;

  .image-container {
    align-items: center;
    background-color: $ay-grey-8-color;
    display: flex;
    height: 9.5rem;
    justify-content: center;
    margin: 0.5rem 0.5rem 1rem;

    .image {
      height: 7.5rem;
      width: 7.5rem;
    }
  }

  .title {
    align-items: center;
    display: flex;
    margin: 1rem;
  }

  .description {
    margin: 0 1rem;
  }

  .shortcut {
    align-items: center;
    border-bottom: 1px solid $ay-grey-16-color;
    display: flex;
    height: 2rem;
    margin: 1rem 1rem 0;
    padding-bottom: 1rem;

    button {
      width: 100%;
    }

    &.no-border {
      border-bottom: 0;
    }
  }

  .button-container {
    width: 100%;
  }

  &:hover {
    .image-container {
      &.general {
        background-color: $ay-london-blue-color;
      }

      &.tenant {
        background-color: $ay-frozen-grass-color;
      }

      &.agency {
        background-color: $ay-pepa-pig-red-color;
      }
    }
  }
}

.primary-button {
  font-size: 1.12rem;
  font-weight: 500;
}

.card-title {
  font-size: 1.75rem;
  line-height: 2rem;
}

.card-description {
  font-size: 1rem;
  line-height: 1.5rem;
}

@media screen and (min-width: $view-width-small-desktop-min) {
  .card-container {
    &:hover {
      box-shadow: 0 0.5rem 1.5rem rgba(1, 14, 40, 0.24);
    }
  }
}

:export {
  view-width-small-desktop-min: $view-width-small-desktop-min;
}
