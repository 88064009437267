@import 'src/assets/stylesheets/design-system/colors';

.container,
.loader-container {
  background-color: $ay-grey-8-color;
  border-radius: 0.25rem;
  box-sizing: border-box;
  margin-top: 1rem;
  padding: 1rem;

  &.disabled {
    opacity: 0.4;
  }
}

.loader-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.loader-label {
  margin-left: 1rem;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.title {
  color: $ay-black-color;
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
}

.title,
.subtitle {
  margin: 0;
}

.subtitle,
.loader-label {
  color: $ay-grey-64-color;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.pagination-actions {
  .pagination-button {
    background-color: transparent;
    margin-left: 0.5rem;

    &:hover,
    &:hover:focus.active {
      background-color: transparent;
    }
  }
}

.button-add-property {
  background: $ay-grey-8-color url('../../../../../../assets/images/icons/add.svg') no-repeat center;

  &:hover:not(:disabled) {
    background-color: $primary-color-100;
  }
}
