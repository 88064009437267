@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/navbar';
@import 'src/assets/stylesheets/zIndexes';

.menu-container {
  align-items: center;
  background-color: $ay-grey-8-color;
  border-top: 0.0625rem solid $ui-color-200;
  display: flex;
  height: $subnavbar-height;
  justify-content: center;
  position: fixed;
  top: $navbar-height;
  width: 100%;
  z-index: $subnavbar-z-index;
}

.menu-item {
  align-items: center;
  display: flex;
  height: 100%;

  &.activity {
    margin-right: 1.5rem;
  }
}

.menu-link {
  color: $ui-color-500;
  cursor: pointer;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: $primary-color-500;
  }
}

.market-analytics-dropdown,
.mapping-dropdown {
  --subnavbar-grid-template-columns: 14.5rem;
  --subnavbar-grid-template-rows: repeat(2, 6.75rem);
}
