@import 'src/assets/stylesheets/design-system/colors';

.checkbox-group-container {
  display: flex;
  flex-flow: wrap;
  width: 100%;
}

.checkbox-group-item {
  align-items: center;
  border-bottom: 0.0625rem solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  height: 2.5rem;
  overflow: hidden;
  padding: 0.5rem;
  position: relative;
  text-overflow: ellipsis;
  text-transform: capitalize;
  user-select: none;
  white-space: nowrap;

  &.active {
    border-bottom-color: $ay-grey-16-color;
  }

  .checkbox {
    background-color: $ay-white-color;
    border: 0.0625rem solid $ay-grey-24-color;
    border-radius: 0.25rem;
    box-sizing: border-box;
    height: 1rem;
    min-width: 1rem;
  }

  &:hover .checkbox {
    border: 0.0625rem solid $ay-grass-green-color;
  }

  .checked {
    background: $ay-grass-green-color url('../../../assets/images/icons/checked.svg') no-repeat center;
    border: 0.0625rem solid $ay-grass-green-color;
  }

  .partially-checked {
    background: $ay-grey-24-color url('../../../assets/images/icons/checked.svg') no-repeat center;
    border: 0.0625rem solid $ay-grey-24-color;
  }

  .label {
    overflow: hidden;
    padding-left: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .arrow-container {
    height: 2.5rem;
    position: absolute;
    right: 0;
    width: 2rem;

    .arrow {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.disabled,
.disabled:hover {
  background-color: $ay-white-color;
  border: 0.0625rem solid $ay-grey-16-color;
  color: $ay-grey-64-color;
  cursor: initial;
}

.disabled:hover .checkbox {
  border: 0.0625rem solid $ay-grey-24-color;
}

.checkbox-group-item-container {
  background-color: $ay-white-color;
  border: 0.0625rem solid $ay-grey-16-color;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: $ay-grey-64-color;
  height: 2.5rem;
  margin: 0 0.75rem 1rem 0;

  &.active {
    height: 100%;
  }

  &:hover {
    border: 0.0625rem solid $ay-grass-green-color;
    color: $primary-color-900;
  }
}

.selected {
  background-color: $ay-frozen-grass-color;
  border: 0.0625rem solid $ay-grass-green-color;
  color: $primary-color-900;
}

.partially-selected {
  border: 0.0625rem solid $ay-grass-green-color;
  color: $primary-color-900;

  .checkbox-group-item:hover .checkbox {
    border: 0.0625rem solid $ay-grey-24-color;
  }
}

.sub-items-options {
  display: none;
  max-height: 28rem;
  overflow: auto;
  padding: 0.5rem 0 0 0.5rem;

  &.active {
    display: block;
  }
}

.sub-items-container {
  width: 100%;

  .sub-item-checkbox-group {
    color: $ay-grey-64-color;
    cursor: pointer;
    display: flex;
    padding-bottom: 0.5rem;

    &:hover {
      color: $primary-color-900;
    }

    .sub-item-checkbox {
      background-color: $ay-white-color;
      border: 0.0625rem solid $ay-grey-24-color;
      border-radius: 0.25rem;
      box-sizing: border-box;
      height: 1rem;
      min-width: 1rem;
      width: 1rem;
    }

    &:hover .sub-item-checkbox {
      border: 0.0625rem solid $ay-grass-green-color;
    }

    .sub-item-checked {
      background: $ay-grass-green-color url('../../../assets/images/icons/checked.svg') no-repeat center;
      border: 0.0625rem solid $ay-grass-green-color;
    }

    .sub-item-label {
      padding-left: 0.5rem;
    }
  }

  .sub-item-selected {
    color: $primary-color-900;
  }
}
