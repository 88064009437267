@import 'src/assets/stylesheets/design-system/colors';

.list-container {
  color: $ay-grey-64-color;
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  .field-text {
    margin: 0;
  }
}

.item-container {
  display: flex;
}
