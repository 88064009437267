@import 'src/assets/stylesheets/design-system/colors';

.container {
  display: flex;

  .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.25rem;
    flex-shrink: 0;
    height: 7.125rem;
    margin-right: 1rem;
    object-fit: cover;
    width: 9.5rem;
  }

  .header-submarket {
    font-size: 1rem;
  }

  .map {
    margin-right: 1rem;
  }

  .text-container {
    flex: 1;

    .activity-market-info {
      margin-bottom: 0.5rem;
    }

    .market-address {
      color: $ay-black-color;
      display: block;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.5rem;
      padding: 0;
      text-decoration: none;

      &-clickable:hover {
        color: $primary-color-400;
        cursor: pointer;
      }
    }

    .property-name {
      color: $ay-grey-64-color;
      font-size: 0.875rem;
      margin: 0 0 0.25rem;
    }
  }

  .title-icon {
    margin-top: 0.06rem;
    width: 1rem;
  }

  .title {
    display: flex;
  }

  .submarket-cover {
    height: 7.125rem;
    margin-right: 1rem;
    width: 9.5rem;

    section {
      height: 7.125rem;
    }
  }
}

.market-country {
  color: $ay-grey-64-color;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1rem;
  margin: 0.25rem 0;
  text-align: left;
}
