@import 'src/assets/stylesheets/design-system/colors';

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.75rem;
  position: relative;
  width: 100%;

  .header-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .header-title-container {
    display: flex;
    flex-direction: column;
  }

  .header-actions {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 2rem;
    padding-top: 1rem;
  }

  .header-content {
    &.switch-actions {
      margin-bottom: -1.35rem;
      margin-top: -0.5rem;
      padding-right: 0.5rem;
    }
  }

  .header-legends {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 0.35rem;
  }

  .title {
    color: $ay-pure-white-color;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    margin: 1.5rem 0 1rem 1.5rem;

    &.switch-actions {
      margin-bottom: 1.25rem;
    }
  }

  .subtitle {
    color: $ui-color-200;
    font-size: 0.75rem;
    line-height: 1rem;
    margin: -1rem 0 0 1.5rem;
  }
}
