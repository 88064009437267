@import 'src/assets/stylesheets/design-system/colors';

.brokers-container {
  margin-top: 1rem;

  .broker-text {
    color: $ay-grey-40-color;
    display: flex;
    font-size: 1rem;
    line-height: 1.5rem;

    .broker-name {
      color: $ay-black-color;
      font-weight: normal;
      margin-left: 0.25rem;
    }
  }
}
