@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.background-ui-color-100 {
  background-color: $ui-color-100;
}

.pricing-quote-data {
  margin-bottom: 1rem;
}

.card-separator {
  @include card-separator;
}
