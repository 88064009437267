@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.link {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 0.5rem;
  padding: 0;

  &,
  &:visited {
    color: $primary-color-400;
  }
}

.owner-container {
  margin-bottom: 0;
}

.tenant-company {
  margin: 1rem 0 0.5rem;
}

.footer {
  color: $ay-grey-80-color;
  font-size: 0.75rem;
  line-height: 1rem;
  position: relative;
}

.card-separator {
  @include card-separator;

  margin: 0.5rem 0;
}

@mixin btn {
  border: 0.0625rem solid $ay-grey-16-color;
  color: $ui-color-600;
  margin-bottom: 1rem;
  padding: 0.2rem 1rem;

  &:hover {
    color: $ui-color-600;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      color: $ui-color-600;
      text-decoration: none;
      text-underline: $primary-color-100;

      .truncate {
        /* stylelint-disable-line */
        background-image: linear-gradient(90deg, $ui-color-600 85%, rgba(0, 0, 0, 0));
      }
    }
  }
}

.content {
  .btn-card {
    @include btn;
  }

  .btn-card-mobile {
    @include btn;

    margin-bottom: 0;
  }

  .mobile-section-container {
    display: none;
  }

  .text {
    font-size: 1rem;
  }
}

.section-container {
  display: flex;
  flex-wrap: wrap;
  left: -0.25rem;
  margin-bottom: 1rem;
  position: relative;
}

.tag {
  &:hover {
    cursor: pointer;
  }
}

.tag-share {
  background-color: $ui-color-200;
}

.share {
  background-color: $ay-grey-8-color;
}

.scoop-media {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid $ay-grey-16-color;
  height: 16rem;
  margin-bottom: 1rem;
  width: 19.0625rem;
}

.notes {
  margin: 0.5rem 0 0;
}

@media only screen and (max-width: 48rem) {
  .content {
    .btn-card {
      display: none;
    }

    .mobile-section-container {
      display: block;

      .btn-card-mobile {
        margin-right: 1rem;
      }
    }
  }
}

.lease-tag {
  background-color: $ui-color-100;

  &.share-lease-tag {
    background-color: $ui-color-200;
  }

  &:last-child.expanding-lease-tag {
    background-color: $ay-frozen-grass-color;
  }

  &:last-child.contracting-lease-tag {
    background-color: $ay-pepa-pig-red-color;
  }
}

.brokers-line {
  display: flex;
  flex-flow: wrap;
  gap: 0.25rem;
  margin-top: 0.5rem;
}

.in-market-data {
  align-items: center;
  display: flex;
  line-height: 1.5rem;
  margin-top: 0.5rem;

  .in-market-tag {
    background-color: $supportive-color-500;
    border-radius: 0.25rem;
    color: $ui-color-white;
    display: inline;
    font-weight: 600;
    margin: 0;
    padding: 0.25rem;
  }

  .in-market-text {
    color: $ay-grey-64-color;
    display: inline;
    font-size: 0.875rem;
    margin: 0 0 0 0.25rem;
  }
}
