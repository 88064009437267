@import 'src/assets/stylesheets/design-system/colors';

.label {
  align-items: flex-end;
  bottom: 2.6rem;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  justify-content: space-between;
  position: absolute;
  text-align: right;

  &.left-aligned {
    left: 1.25rem;
  }

  &.right-aligned {
    align-items: flex-start;
  }

  .item {
    color: $ay-pure-white-color;
    margin: 0;
  }
}
