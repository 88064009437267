@import 'src/assets/stylesheets/design-system/colors';

.container {
  height: 30.5rem;
}

.title-container {
  cursor: default;
  margin-bottom: 0.75rem;
  margin-top: 1.5rem;
  min-height: 4.5rem;
  text-align: center;

  .title {
    color: $ui-color-200;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.2rem;
    margin-top: 0;
  }

  .subtitle {
    color: $primary-color-100;
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1rem;
    margin-bottom: 0;
    margin-top: 0;
  }

  .year {
    color: $primary-color-100;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 0.1rem;
    margin-top: 0;
  }
}

.footer-container {
  cursor: default;
  margin-bottom: 2.5rem;
  text-align: center;

  .title {
    color: $ui-color-200;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.1rem;
    margin-top: 0;
  }

  .subtitle {
    color: $supportive-color-400;
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1rem;
    margin-bottom: 0;
    margin-top: 0;
  }

  .year {
    color: $supportive-color-400;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 0.2rem;
    margin-top: 0;
  }
}

.graph-container {
  position: relative;
}

.graph-age-text {
  font-size: 1rem;
  line-height: 3rem;
  text-align: center;
}
