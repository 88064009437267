@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/shadows';

.container {
  position: relative;
}

.btn-clear {
  opacity: 1;
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.input {
  @include input-border-style;

  background: $ui-color-white;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: $ay-grey-80-color;
  cursor: pointer;
  font-size: 1rem;
  height: 2.5rem;
  line-height: 1.5rem;
  padding: 0.5rem 1rem;
  width: 100%;

  &:focus {
    box-shadow: 0 0.5rem 1.5rem rgba(0, 32, 91, 0.24);
    outline: none;
  }

  &.suggestion-list-hover {
    border-color: $primary-color-200 !important;
  }

  &.suggestion-list-open {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: $primary-color-400;
    box-shadow: $shadow-level-2;
  }
}

.separator-container {
  background-color: $ui-color-white;
  height: 0.0625rem;
  left: 0.0625rem;
  position: absolute;
  width: calc(100% - 0.125rem);
  z-index: $maps-places-autocomplete + 1;

  .separator {
    background-color: $ay-grey-16-color;
    height: 0.0625rem;
    left: 0.5rem;
    position: absolute;
    width: calc(100% - 0.5rem * 2); // 0.5 is the space from the edges
  }
}

.suggestion-container {
  border: 0.0625rem solid $primary-color-400;
  border-radius: 0 0 0.25rem 0.25rem;
  border-top: 0;
  box-shadow: 0 2rem 2rem rgba(1, 14, 40, 0.24);
  box-sizing: content-box;
  position: absolute;
  width: calc(100% - 0.125rem);
  z-index: $maps-places-autocomplete;

  &.hover {
    border-color: $primary-color-200;
  }

  .suggestion-item {
    background: $ui-color-white;
    border-radius: 0 0 0.25rem 0.25rem;
    border-top: 1px solid $ay-grey-16-color;
    font-size: 1rem;
    padding: 0.5rem 1rem;

    &:hover {
      background: $ay-grey-8-color;
      cursor: pointer;
    }

    .secondary-text {
      color: $ay-grey-64-color;
      font-family: 'Open Sans', sans-serif;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
}
