@import 'src/assets/stylesheets/design-system/colors';

.container {
  width: 100%;

  .header {
    padding: 2rem 0;
  }

  &.last-sub-section,
  &.single-section {
    padding-bottom: 8rem;
  }
}
