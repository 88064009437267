@import 'src/assets/stylesheets/design-system/colors';

.side-navigation-bar {
  background-color: $ui-color-200-ligther;
  border-right: 1px solid $ui-color-200;
  border-top: 1px solid $ui-color-200;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  position: fixed;
  transition: width 0.25s;
  z-index: 10;
}

.item-list {
  flex: 1;
}

.footer-icon {
  cursor: pointer;
  margin-right: 1rem;
}

.footer {
  align-items: center;
  border-top: 1px solid $ui-color-200;
  display: flex;
  height: 3.5rem;
  justify-content: flex-end;

  &.collapsed {
    justify-content: center;

    .footer-icon {
      margin: 0;
      transform: rotate(180deg);
    }
  }
}
