@import 'src/assets/stylesheets/design-system/colors';

.group-container {
  padding-top: 0.5rem;
}

.checkbox-top-nodes {
  display: flex;
}

.group-key-container {
  display: inline-flex;
  margin: 0;
  width: 100%;
}

.group-key-title {
  color: $ay-grey-80-color;
  flex: 1;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0.75rem 0 0;
}

.select-and-deselect {
  margin: 0;
  padding: 0;
}

.truncate {
  background-image: linear-gradient(90deg, #383c42 100%, rgba(0, 0, 0, 0));
}

.checkbox-group-container {
  display: flex;
  flex-flow: wrap;
  width: 100%;

  &.vertical {
    flex-direction: column;
    width: auto;
  }

  &:hover .checkbox {
    border: 0.0625rem solid $ay-grass-green-color;
  }

  .checkbox-group-item {
    align-items: center;
    box-sizing: border-box;
    color: $ay-grey-64-color;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    height: 2.5rem;
    margin: 0 0.75rem 1rem 0;
    overflow: hidden;
    padding: 0.5rem;
    text-overflow: ellipsis;
    text-transform: capitalize;
    user-select: none;
    white-space: nowrap;

    &.vertical {
      margin: 0 0 0.25rem;
      text-align: left;
    }

    &.regular-size {
      min-width: initial;
    }

    &.big-size {
      min-width: 15.5rem;
    }

    .checkbox {
      background-color: $ui-color-white;
      border: 0.0625rem solid $ay-grey-24-color;
      border-radius: 0.25rem;
      box-sizing: border-box;
      height: 1rem;
      min-width: 1rem;
      width: 1rem;
    }

    &.background {
      background-color: $ui-color-white;
      border: 0.0625rem solid $ay-grey-16-color;
      border-radius: 0.25rem;

      &:hover {
        border: 0.0625rem solid $ay-grass-green-color;
      }

      &.selected {
        background-color: $ay-frozen-grass-color;
        border: 0.0625rem solid $ay-grass-green-color;
      }

      &.disabled,
      &.disabled:hover {
        background-color: $ui-color-white;
        border: 0.0625rem solid $ay-grey-16-color;
      }

      &.disabled:hover .checkbox {
        border: 0.0625rem solid $ay-grey-24-color;
      }
    }

    &:hover,
    &.selected {
      color: $primary-color-900;
    }

    &.disabled,
    &.disabled:hover {
      color: $ay-grey-64-color;
      cursor: initial;
      opacity: 0.4;
    }

    .checked {
      background: $ay-grass-green-color url('../../../assets/images/icons/checked.svg') no-repeat center;
      border: 0.0625rem solid $ay-grass-green-color;
    }

    .label {
      overflow: hidden;
      padding-left: 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
