@import 'src/assets/stylesheets/design-system/colors';

.button-remove-property {
  background: $ay-white-color url('../../assets/images/icons/delete.svg') no-repeat center;
  background-size: 1rem;

  &:hover:not(:disabled) {
    background: $ay-main-red-color url('../../assets/images/icons/delete-white.svg') no-repeat center;
    background-size: 1rem;
  }
}
