@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/navbar';

.container {
  float: right;
  position: relative;
  width: calc(100% - 1rem);

  &::after {
    background: $primary-color-100;
    bottom: 1rem;
    content: ' ';
    left: -1rem;
    position: absolute;
    top: 1rem;
    width: 0.125rem;
  }
}

.group-container {
  padding-bottom: 0.5rem;
}

.group-header {
  background-color: $ui-color-white;
  border-radius: 0.25rem;
  color: $ay-grey-64-color;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.5rem 0;
  position: sticky;
  z-index: 1;

  &-activity-page {
    top: $navbar-height;
  }

  &::before {
    background: $primary-color-200;
    border-radius: 50%;
    content: ' ';
    height: 0.75rem;
    left: -1.3rem;
    margin-top: 0.35rem;
    position: absolute;
    width: 0.75rem;
    z-index: 1;
  }
}

.market-button {
  font-size: 1rem;
  margin-left: 0.5rem;
}

.content {
  align-items: center;
  display: flex;
}
