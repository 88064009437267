@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/variables';
@import 'src/assets/stylesheets/design-system/colors';

@mixin profile-image-overlay-visible {
  align-items: center;
  background: $primary-color-500;
  border-color: $primary-color-200;
  border-radius: $ay-border-radius;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s linear;
  visibility: visible;
  z-index: $z-index-9;
}

.container {
  border-radius: $ay-border-radius;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 11rem;
  overflow: hidden;
  position: relative;
  width: 11rem;

  .upload-image-input {
    bottom: 0;
    cursor: pointer;
    left: 0;
    max-width: 11rem;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $z-index-10;
  }

  .profile-image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .image-overlay {
    border: 0.125rem solid transparent;
    opacity: 0;
    visibility: hidden;

    .image-overlay-icon {
      margin-bottom: 0.8rem;
    }

    .upload-photo-label {
      color: $primary-color-400;
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 0 0 0.8rem;
    }

    .image-overlay-text {
      color: $ay-white-color;
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1rem;
      margin: 0;

      &.image-empty {
        color: $primary-color-500;
      }
    }

    &.image-empty {
      @include profile-image-overlay-visible;

      background-color: transparent;
      border-color: $primary-color-200;
    }

    &.is-uploading {
      @include profile-image-overlay-visible;
    }
  }

  &:hover {
    .image-overlay {
      @include profile-image-overlay-visible;

      .image-overlay-text,
      .upload-photo-label {
        color: $ay-white-color;
      }

      &.has-image {
        background-color: rgba($primary-color-500, 0.88);
      }
    }
  }
}

.hovered-container {
  padding-bottom: 2rem;
}
