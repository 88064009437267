@import 'src/assets/stylesheets/design-system/colors';

.default-color {
  color: $ay-grey-64-color;
}

.highlight {
  color: $ay-black-color;

  &.bold {
    font-weight: 600;
  }
}
