@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.tenant-company {
  color: $ay-black-color;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

.background-ui-color-100 {
  background-color: $ui-color-100;
}

.dropdown-container {
  display: flex;
  justify-content: flex-end;
}

.card-separator {
  @include card-separator;
}
