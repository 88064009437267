@import '../../assets/stylesheets/design-system/colors';
@import '../../assets/stylesheets/zIndexes';

.container {
  display: var(--container-display, flex);
  font-size: 1rem;
}

.input {
  background-image: url('../../assets/images/icons/dropdown-arrow-down.svg');
  background-position: top 50% right 1rem;
  background-repeat: no-repeat;
  border-radius: 0.25rem;
  color: var(--input-color, $ay-grey-80-color);
  min-width: 5rem;

  &.list-opened {
    background-color: var(--list-opened-background-color);
    color: var(--list-opened-color);
  }

  &.hide-arrow {
    background-image: none;
  }
}

.options-container {
  background-color: $ay-white-color;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 2rem rgba(1, 14, 40, 0.24);
  min-width: 15rem;
  position: absolute;
  z-index: $z-index-2;
}

.input-span {
  border: 0;
  color: $ay-grey-80-color;
  font-size: 1rem;
  text-align: left;
  width: 100%;
}

.option {
  border-radius: 0.25rem;
  color: var(--option-color, $ay-grey-64-color);
  cursor: pointer;
  line-height: 3rem;
  padding-left: 1rem;

  &:hover {
    background-color: var(--option-background-color-hover, $ay-grey-8-color);
    color: var(--option-color-hover, $ay-grey-80-color);
  }

  &.selected {
    background-image: url('../../assets/images/icons/check-green.svg');
    background-position: top 50% right 1rem;
    background-repeat: no-repeat;
    color: $ay-black-color;
  }
}
