@import 'src/assets/stylesheets/design-system/colors';

.hold-period-graph-container {
  height: 10rem;
  margin-bottom: 2rem;
}

.container {
  cursor: default;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.title {
  color: $ay-pure-white-color;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  margin-top: 1.5rem;
}

.svg-graph {
  overflow: visible;
  width: fit-content;
}

.legend-container {
  color: $ay-pure-white-color;
  display: flex;
  font-size: 0.75rem;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  .market-legend,
  .property-legend {
    margin-left: 0.25rem;
    padding-bottom: 0;
    padding-top: 0;

    &-list {
      align-items: center;
      display: flex;
      justify-content: space-between;

      > * {
        margin: 0;

        &:not(:last-child) {
          border-right: 0.0625rem solid $primary-color-900;
          margin-right: 0.5rem;
          padding-right: 0.5rem;
        }
      }
    }
  }
}
