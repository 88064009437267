@import 'src/assets/stylesheets/design-system/colors';

.heading-title {
  align-items: center;
  color: $ay-grey-40-color;
  display: flex;
  font-size: 0.75rem;
  height: 1rem;
  margin: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  white-space: pre;
  width: 100%;

  .heading-title-separator {
    background-color: $ay-grey-24-color;
    display: inline-block;
    height: 0.0625rem;
    margin-left: 0.5rem;
    width: 100%;
  }
}
