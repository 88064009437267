@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.documents-container {
  background: $ay-white-color;
  border: 0.0625rem solid $ay-grey-16-color;
  border-radius: 0.25rem;
  float: left;
  margin: 1rem 1rem 1rem 0;
  position: relative;
  width: 16rem;

  .container-doc {
    align-items: center;
    background-color: $ay-grey-8-color;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: flex;
    flex-direction: row;
    height: 7.5rem;
    justify-content: center;

    .delete-btn {
      @include eager-background-image('icons/delete.svg', 'icons/delete-white.svg');

      background-color: $ay-grey-8-color;
      background-position: top 0.25rem left 0.25rem;
      background-repeat: no-repeat;
      background-size: 1rem;
      border-radius: 0.25rem;
      box-shadow: 0 0 0.5rem -0.25rem rgba(0, 0, 0, 0.75);
      height: 1.5rem;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      width: 1.5rem;

      &:hover {
        background-color: $ay-main-red-color;
      }
    }
  }

  .title {
    color: $ay-grey-80-color;
    margin: 0 0 1rem 1rem;
  }
}

.progress-bar {
  background-color: $ay-white-color;
  height: 0.25rem;
  margin-bottom: 0.75rem;
  width: 100%;

  .progress-bar-fill {
    background-color: $ay-success-color;
    border-radius: 0.25rem;
    height: 0.25rem;
  }
}
