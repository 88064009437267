@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';

.title {
  color: $ay-grey-80-color;
  font-size: 1.75rem;
  font-weight: 400;
  text-transform: capitalize;
}

.modal-row {
  margin-top: 2rem;
}

.leasing-assignment-name {
  box-sizing: border-box;
  height: 3rem;
}

.remove-button {
  background: $ay-white-color url('../../assets/images/icons/delete.svg') no-repeat center;
  background-size: 1rem;

  &:hover:not(:disabled) {
    background: $ay-main-red-color url('../../assets/images/icons/delete-white.svg') no-repeat center;
    background-size: 1rem;
  }
}

.footer-wrapper {
  z-index: $z-index-10;
}
