@import 'src/assets/stylesheets/design-system/colors';

.container {
  align-items: center;
  cursor: default;
  height: 100%;
  min-height: 27.5rem;
  position: relative;
  text-align: center;
  width: 100%;
}

.title {
  align-self: flex-start;
  color: $ay-white-color;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  margin-top: 1.5rem;
  text-align: left;
}

.tooltip {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -0.5rem 0 0;
  width: 65%;

  * {
    box-sizing: border-box;
  }
}

.tooltip-default {
  > div {
    background: $primary-color-500;
    flex: 0 0 calc(50% - 1rem);
    flex-basis: 10%;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    margin-top: 0.25rem;
    text-transform: none;
    white-space: nowrap;
    width: 100%;
  }
}

.tooltip-active {
  > div {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1rem;
    margin-bottom: 0.25rem;
    padding: 0.25rem 0.5rem;
    text-transform: none;
    white-space: nowrap;
  }

  > div:first-child {
    color: $ui-color-white;
    flex: auto;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    margin: 0;
    padding: 0 0 0.625rem;
    text-transform: none;
    width: 100%;
  }
}

.loading-container {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
