@import 'src/assets/stylesheets/variables';
@import 'src/assets/stylesheets/design-system/colors';

.container {
  align-items: center;
  background-color: $primary-color-500;
  border-radius: $ay-border-radius;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  overflow: hidden;
  width: 2.5rem;
}

.profile-image {
  max-width: 100%;
}

.initials {
  color: $ay-main-red-color;
  font-weight: 600;
  text-transform: uppercase;
}
