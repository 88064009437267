@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/shadows';
@import 'src/assets/stylesheets/variables';

.container {
  background-color: $ay-white-color;
  border: 0.0625rem solid $ay-grey-16-color;
  border-radius: $ay-border-radius;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  position: relative;
}

.header {
  color: $ui-color-900;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 0;

  .title {
    display: block;
  }
}

.content {
  padding-top: 1rem;

  &::before {
    background: $ui-color-200;
    content: '';
    height: 0.0625rem;
    left: 0.8rem;
    position: absolute;
    right: 0.8rem;
  }
}

.tag {
  background-color: $ui-color-100;
  margin-top: 0.5rem;
}

.notes {
  color: $ui-color-500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.action-container {
  display: flex;
  justify-content: space-between;
}

.button {
  align-items: center;
  background-color: $ui-color-100;
  display: flex;
  margin-right: 0.25rem;
  min-height: 1.5rem;
}
