@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/variables';

.container {
  height: 30.5rem;
}

.title-container {
  cursor: default;
  margin-bottom: 0.75rem;
  margin-top: 1.5rem;
  min-height: 5.25rem;
  text-align: center;

  .title {
    color: $ui-color-200;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.2rem;
    margin-top: 0;
  }

  .size {
    color: $primary-color-100;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 0.1rem;
    margin-top: 0;
  }

  .floors {
    color: $primary-color-100;
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1rem;
    margin-top: 0;
  }
}

.footer-container {
  cursor: default;
  margin-bottom: 2.5rem;
  text-align: center;

  .title {
    color: $ui-color-200;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.1rem;
  }

  .size {
    color: $supportive-color-400;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 0.2rem;
    margin-top: 0;
  }

  .floors {
    color: $supportive-color-400;
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1rem;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.graph-container {
  position: relative;
}

.svg-graph {
  overflow: visible;
}

.tooltip-spinner-container {
  margin-top: -0.75rem;
  text-align: center;
  width: 100%;
}

.tooltip-top {
  align-items: center;
  background-color: $ay-grey-8-color;
  border-radius: $ay-border-radius;
  box-shadow: rgba(21, 20, 20, 0.6) 0 0 0.5rem 0;
  color: $ay-black-color;
  display: none;
  margin-top: -0.75rem;
  max-height: 3.5rem;
  min-width: 4rem;
  padding: 1rem;
  position: absolute;
  white-space: nowrap;
  width: auto;
  z-index: 2;

  &:not(.tooltip-top-loading)::after {
    border-color: $ay-grey-8-color transparent transparent transparent;
    border-style: solid;
    border-width: 0.625rem;
    content: ' ';
    left: 46%;
    margin-top: -0.8rem;
    position: absolute;
    top: 115%;
  }
}

.tooltip-main-text {
  color: $ay-total-black-color;
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 0.125rem;
}

.tooltip-secondary-text {
  color: $ui-color-600;
  font-size: 0.75rem;
}
