@import 'src/assets/stylesheets/design-system/colors';

.root {
  cursor: pointer;
  position: relative;
}

.body {
  background-color: $ui-color-black;
  border-radius: 0.25rem;
  bottom: -1.375rem;
  color: $ui-color-white;
  font-size: 0.625rem;
  left: -50%;
  margin: 0;
  padding: 0.125rem 0.25rem;
  position: absolute;
  white-space: nowrap;
  z-index: 13;
}
