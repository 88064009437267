@import 'src/assets/stylesheets/design-system/colors';

.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.title {
  color: $ay-total-black-color;
  font-size: 2.5rem;
  font-weight: bold;
}

.error-message {
  align-items: center;
  background-color: $ay-pure-white-color;
  border: 0.0625rem solid $system-color-warning;
  border-radius: 0.25rem;
  color: $ui-color-900;
  display: flex;
  padding-left: 1rem;

  p {
    padding-left: 1rem;
  }
}

// table cell
.cell-content {
  display: flex;
  gap: 0.5rem;
  justify-content: center;

  .cell-input {
    border: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    width: 100%;

    &:focus,
    &:active {
      border-radius: 0.25rem;
      box-shadow: 0 0 0 0.0625rem $ui-color-300;
      outline: none;
    }
  }

  .date-input {
    border: 0;

    &-error-message {
      margin-top: -0.75em;
    }
  }
}

// extra table cell
.extra {
  &-row {
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s ease, background 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }

  &-cell {
    color: $ui-color-400 !important;
    transition: color 0.2s ease;

    input {
      color: $ui-color-400 !important;
      cursor: pointer;
      transition: color 0.2s ease;

      &:focus,
      &:active {
        box-shadow: none !important;
      }
    }
  }
}
