@import 'src/assets/stylesheets/design-system/colors';

.dropdown-button {
  background-color: $ay-grey-8-color;
  border: 0;
  color: $primary-color-400;

  &:hover,
  &:focus {
    background-color: $primary-color-100;
  }
}

.dropdown-label {
  color: $ay-grey-40-color;
  margin-right: 1rem;
}

.dropdown-menu {
  left: initial;
  max-width: 16.25rem;
  right: 0;
}
