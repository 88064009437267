@import 'src/assets/stylesheets/design-system/colors';

.modal-container.links-container {
  height: 30rem;
  max-height: 30rem;
  min-width: 46rem;
  overflow-y: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
  width: 44rem;
}

.market-properties {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: 0.5rem;
  position: relative;

  &::before {
    background: $primary-color-100;
    bottom: 1rem;
    content: '';
    left: -0.5rem;
    position: absolute;
    top: 1rem;
    width: 0.125rem;
  }
}
