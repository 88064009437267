@import '../../../assets/stylesheets/design-system/colors';
@import '../../../assets/stylesheets/mixins';
$items-padding: 0.25rem 0.5rem;

.label {
  color: $ay-grey-64-color;
  display: block;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

.container {
  @include input-border-style;

  align-items: center;
  background-color: $ay-pure-white-color;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  min-height: 3rem;
  padding: 0.25rem;
  position: relative;

  &.is-active {
    border-color: $primary-color-400;
  }

  .email-chip {
    align-items: center;
    background: $ay-grey-8-color;
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: $ay-black-color;
    display: flex;
    font-size: 1rem;
    margin: 0.25rem;
    padding: 0.25rem 0.5rem;

    .email-chip-remove {
      cursor: pointer;
      margin-left: 0.5rem;
    }
  }

  .email-input {
    background-color: transparent;
    border: 0;
    box-sizing: border-box;
    color: $ay-black-color;
    flex: 1 0;
    font-size: 1rem;
    margin: 0.25rem;
    padding: 0;

    &:focus {
      outline: none;
    }
  }
}
