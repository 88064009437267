@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/variables';

.recent-sales-graph-container {
  height: 100%;
  min-height: 31rem;
}

.container {
  cursor: default;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.title {
  color: $ay-pure-white-color;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  margin-top: 1.5rem;

  .subtitle {
    color: $ay-grey-40-color;
    display: block;
  }
}

.svg-graph {
  overflow: visible;
}

.tooltip {
  align-items: center;
  background-color: $ay-grey-8-color;
  border-radius: $ay-border-radius;
  box-shadow: rgba(21, 20, 20, 0.6) 0 0 0.5rem 0;
  color: $ay-black-color;
  display: none;
  flex-direction: column;
  justify-items: center;
  max-height: 3.5rem;
  min-width: 4rem;
  padding: 1rem;
  position: absolute;
  z-index: 2;

  &::after {
    border-color: $ay-grey-8-color transparent transparent;
    border-style: solid;
    border-width: 0.625rem;
    content: ' ';
    margin-top: -0.75rem;
    position: absolute;
    top: 115%;
  }

  > * {
    line-height: 1rem;
    margin: 0;
  }

  &-main-text {
    color: $ay-total-black-color;
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  &-secondary-text {
    color: $ay-total-black-color;
    font-size: 0.75rem;
  }

  &-tertiary-text {
    color: $ay-total-black-color;
    font-size: 0.75rem;
  }
}
