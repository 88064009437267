@import 'src/assets/stylesheets/design-system/colors';

.sale-card {
  &.share-card {
    background-color: $ay-grey-8-color;
    border: 0;
    margin: 0;
    padding: 0;

    &:hover {
      border: 0;
      box-shadow: none;
      transition: none;
    }
  }

  .header {
    position: relative;
  }

  .action-button-wrapper {
    position: absolute;
    right: 0;
    top: 0;

    .action-button {
      align-items: center;
      display: inline-flex;
      height: 2rem;
      justify-content: center;
      width: 2rem;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .buyers-wrapper,
  .sellers-wrapper {
    width: 89%;
  }

  .sale-company-info {
    flex-wrap: nowrap;
  }

  .purchased-by-wrapper {
    &.with-margin {
      margin-bottom: 0.5rem;
    }
  }

  .broker-wrapper {
    display: flex;

    .broker-wrapper-child {
      max-width: 50%;
    }

    .seller-broker-wrapper {
      margin-right: 0.25rem;
    }

    .buyer-broker-wrapper {
      margin-left: 0.25rem;
    }
  }

  .tags {
    margin: 1rem 0 0.75rem;

    &.with-no-top-margin {
      margin-top: 0;
    }

    &.with-no-bottom-margin {
      margin-bottom: 0;
    }

    .tag-item {
      background-color: $ui-color-100;

      &.darker {
        background-color: $ui-color-200;
      }

      &:last-child.conversion {
        background-color: $ay-gold-8-color;
      }
    }
  }

  .note-wrapper {
    margin: 1rem 0 0;
  }

  .user-modification-date {
    justify-content: flex-start;
  }

  .user-modification-date-separator {
    margin: 0 1.375rem;
  }
}
