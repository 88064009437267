@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';

.container {
  @include input-border-style;

  height: 16.5rem;
  margin: 0 0.75rem;
  position: relative;
  width: auto;

  iframe {
    display: none;
  }
}

.marker {
  left: calc(50% - 1rem);
  position: absolute;
  top: calc(50% - 1rem);
  z-index: $z-index-1;
}

.instruction {
  background-color: $ay-grey-64-color;
  border-radius: 0.25rem 0.5rem;
  color: $ay-white-color;
  left: calc(50% - 14rem);
  padding: 0.25rem;
  position: absolute;
  top: 0.5rem;
  z-index: $z-index-1;
}

@mixin map-buttons-control-style {
  background-color: $primary-color-500;
  border: 0.0625rem solid $primary-color-500;
  height: 2rem;
  position: absolute;
  width: 2rem;
}

.controls {
  align-items: center;
  bottom: 0;
  height: 4.2rem;
  margin: 0 1rem 1rem 0;
  position: absolute;
  right: 0;
  width: 4.5rem;
  z-index: $z-index-1;

  .button-icon {
    position: absolute;
    transform: translate(-50%, -50%);
  }

  .button-up {
    @include map-buttons-control-style;

    border-radius: 0.25rem 0.25rem 0 0;
    left: calc(50% + 0.25rem);
  }

  .button-down {
    @include map-buttons-control-style;

    border-radius: 0 0 0.25rem 0.25rem;
    bottom: 0;
    left: calc(50% + 0.25rem);
  }
}
