@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.container {
  display: inline;
  position: relative;
}

.label {
  color: $ay-grey-64-color;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 0.5rem;
  min-width: 9rem;

  .truncate {
    background-image: linear-gradient(90deg, $ay-grey-64-color 85%, rgba(0, 0, 0, 0));
  }
}

.label-required span {
  color: $ay-grey-40-color;
}

.input {
  @include input-border-style;

  background-color: $ui-color-white;
  border-radius: 0.25rem;
  font-size: 1rem;
  height: 2.5rem;
  outline: none;
  padding-bottom: 0;
  padding-left: 1rem;
  padding-top: 0;
  width: 7.5rem;

  &:disabled,
  &[disabled] {
    opacity: 0.4;
  }

  &-clear-padding {
    box-sizing: content-box;
    padding-right: 1.75rem;
  }

  &-border-box {
    box-sizing: border-box;
  }

  &-node {
    position: relative;
  }

  &-full-width {
    width: 100%;
  }

  &-small {
    height: 2rem;
    padding-right: 1rem;
  }

  &-large {
    height: 3rem;
  }

  &-narrow {
    width: 3.75rem;
  }

  &-text-centered {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  &-icon-left {
    padding-left: 2.5rem;
    width: 5rem;
  }

  &-icon-right {
    padding-left: 3rem;
    width: 5.5rem;
  }

  &-text-invalid {
    border-color: $ay-main-red-color;
  }

  &.focus.error,
  &.error {
    border-color: $ay-error-color;
  }
}

.clear-button {
  background-color: transparent;
  border: 0;
  max-height: 2rem;
  max-width: 2rem;
  outline: none;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    cursor: pointer;
  }
}

.input-custom {
  padding-left: 1rem;
  text-align: left;

  &::placeholder {
    color: $ay-grey-64-color;
  }
}
