@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/shadows';

.container {
  background-color: $ay-white-color;
  border: 0.0625rem solid $ay-grey-16-color;
  border-radius: 0.25rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  padding: 1rem;
  width: 100%;
  word-wrap: break-word;

  &:hover {
    border-color: $primary-color-200;
    box-shadow: $shadow-level-1;
    transition: 0.15s ease-in all;
  }
}
