@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/variables';

.graph-container {
  align-items: center;
  background-color: $primary-color-900;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  vertical-align: middle;
  width: 100%;
}

.border-top {
  border-top-left-radius: $ay-border-radius;
  border-top-right-radius: $ay-border-radius;
}

.border-bottom {
  border-bottom-left-radius: $ay-border-radius;
  border-bottom-right-radius: $ay-border-radius;
}
