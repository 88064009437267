@import 'src/assets/stylesheets/design-system/colors';

.container {
  overflow: hidden;
}

.loading {
  align-items: flex-start;
  display: flex;
  min-height: 12rem;
}

.file-name {
  color: $ay-grey-80-color;
  display: flex;
  margin: 0 1rem 1rem;

  &.file-name-with-label {
    flex-direction: column;
    margin: 0 2.5rem 0 0;
  }

  .label {
    margin-top: 0;
  }
}

.tags {
  color: $ay-grey-64-color;
  line-height: 1.5rem;
  margin: 1rem;

  &.tags-with-label {
    margin: 0 0 1rem;
  }
}

.file-name,
.tags {
  .label {
    color: $ay-black-color;
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 0.5rem;
  }
}
