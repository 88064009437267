@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/zIndexes';

.map {
  border-radius: 0.25rem;
  overflow: hidden;
  z-index: $z-index-1;

  &.extra-small {
    height: 3rem;
    width: 3rem;
  }

  &.small {
    height: 4rem;
    width: 4rem;
  }

  &.regular {
    height: 6.5rem;
    width: 6.5rem;
  }

  &.large {
    height: 8rem;
    width: 10.62rem;
  }

  &.big {
    height: 11.25rem;
    width: 11.25rem;
  }

  &.portrait {
    height: 4rem;
    width: 5.75rem;
  }

  &.portrait-large {
    height: 4.5rem;
    width: 6rem;
  }

  &.full-width {
    height: 32.875rem;
    width: 100%;
  }
}

.static-map {
  position: relative;
}

.marker {
  bottom: 10%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  &::before {
    @include map-marker($supportive-color-800);

    background: $supportive-color-500;
  }

  &.portrait {
    &::before {
      height: 1.2rem;
      width: 1.2rem;
    }
  }

  &.small {
    &::before {
      height: 1.2rem;
      width: 1.2rem;
    }
  }

  &.extra-small {
    &::before {
      height: 1rem;
      width: 1rem;
    }
  }
}
