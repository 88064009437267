@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/shadows';

.container {
  max-height: fit-content;
  overflow: hidden;
  padding: 0;
  width: 70rem;
}

.content {
  max-height: 39rem;
  overflow: auto;
  padding: 2rem;
  position: relative;
}

.scoop-comments {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr;
  margin-top: 0.5rem;
}

.add-comment {
  align-items: center;
  background: $ui-color-white;
  border-radius: 0 0 0.25rem 0.25rem;
  bottom: 0;
  box-shadow: $shadow-top-2;
  display: flex;
  left: -2rem;
  min-height: 5.5rem;
  padding: 1rem;
  position: sticky;
  right: -2rem;
}

.empty-comment {
  color: $ui-color-600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.actions {
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: repeat(3, 1fr);
  position: absolute;
  right: 2rem;
  top: 2rem;

  .action-button {
    height: 2rem;
    width: 2rem;
  }
}
