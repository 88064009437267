@import 'src/assets/stylesheets/design-system/colors';

.verticalbubble {
  height: 22.25rem;
  position: relative;
}

.container {
  cursor: default;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2.25rem;
  position: relative;
  width: 100%;
}

.svg-graph {
  overflow: visible;
}
