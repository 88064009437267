@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';

.industry-field {
  border-radius: 0.25rem;
  display: flex;
  height: 1.1rem;
}

.action-buttons {
  bottom: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  position: absolute;
  width: 100%;
  z-index: $z-index-10;

  .button {
    align-items: center;
    display: flex;
    height: 2rem;
    justify-content: center;
    width: 2rem;
  }

  .edit {
    margin-right: 0.5rem;
  }
}

.company-aliases-button {
  display: flex;
  justify-content: flex-end;
  padding-right: 0.5rem;
}

.comment-area {
  background-color: $ui-color-white;
  border: 0.0625rem solid $ay-grey-16-color;
  border-collapse: collapse;
  border-radius: 0.25rem;
  font-family: inherit;
  font-size: 1rem;
  min-height: 5rem;
  outline: none;
  padding: 1rem;
  resize: none;
  width: 100%;

  &:focus {
    border-color: $primary-color-400;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.4;
  }
}

.is-last {
  margin-bottom: 5rem;
}
