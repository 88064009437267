@import 'src/assets/stylesheets/design-system/colors';

.market-address {
  background-color: transparent;
  color: $ay-black-color;
  display: flex;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0;
  text-align: left;
  text-decoration: none;

  &:hover {
    color: $primary-color-400;
    cursor: pointer;
  }
}

.address-container {
  display: flex;
}

.address-icon {
  margin-top: 0.2rem;
}

.truncate {
  background-image: linear-gradient(90deg, $ay-black-color 85%, rgba(0, 0, 0, 0));
  max-width: unset;
}
