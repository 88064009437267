@import 'src/assets/stylesheets/variables';
@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.logo {
  align-items: center;
  background-color: $ay-violet-color;
  border-top-left-radius: $ay-border-radius-sm;
  border-top-right-radius: $ay-border-radius-sm;
  display: flex;
  height: 5.5rem;
  justify-content: center;
  width: 100%;

  .logo-image {
    border-top-left-radius: $ay-border-radius-sm;
    border-top-right-radius: $ay-border-radius-sm;
  }

  .avant-logo {
    height: 2rem;
    width: 1.5rem;
  }
}

.marker {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  position: absolute;

  &::before {
    @include map-marker($supportive-color-800);

    background: $supportive-color-500;
  }
}
