@import 'src/assets/stylesheets/design-system/colors';

.container {
  &.label-required {
    padding-top: 0.25rem;
  }

  &.multiline {
    padding-top: 0.5rem;
  }

  &.label-disabled {
    opacity: 0.4;
  }
}

.label {
  color: $ay-grey-64-color;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  margin: 0.5rem 0;
}

.required {
  color: $ay-main-red-color;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0;
}
