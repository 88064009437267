@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

:global {
  .slider-control-bottomcenter {
    pointer-events: none;
  }
}

.modal-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.title-container {
  align-items: center;
  color: $ui-color-white;
  display: flex;
  flex-flow: column;
  margin-bottom: 2.5rem;
  width: 100%;

  .title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2.5rem;
    margin: 0;
  }

  .subtitle {
    color: $ui-color-400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
  }
}

.slide-images-container {
  * {
    outline: none !important;
  }
}

.media-carousel-wrapper {
  background-color: $ay-black-color;
  height: 32.875rem;
  text-align: center;

  .media-item {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    text-align: center;
    width: auto;
  }
}
