@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/variables';
@import 'src/assets/stylesheets/design-system/colors';

.vertical-label {
  color: $ay-grey-64-color;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 0.5rem;
}

.vertical-label span {
  color: $ay-grey-40-color;
}

.container {
  display: inline-block;
  position: relative;

  &.full-width {
    width: 100%;
  }
}

.button {
  @include dropdown-button;
}
