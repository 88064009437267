@import 'src/assets/stylesheets/design-system/colors';

.tabs {
  display: block;
  width: 100%;

  .tab-items {
    align-items: center;
    display: flex;
    height: 2rem;
  }

  .item {
    border-bottom: 0.125rem solid transparent;
    box-sizing: border-box;
    color: $ay-grey-40-color;
    cursor: pointer;
    font-size: 1.25rem;
    line-height: 2rem;
    margin-right: 1.5rem;
    margin-top: 0.625rem;
    transition: all 0.3s ease;

    .truncate {
      background-image: linear-gradient(90deg, $ay-grey-40-color 85%, rgba(0, 0, 0, 0));
    }

    &.disabled {
      cursor: default;
    }

    &.selected {
      border-bottom-color: $ay-grey-80-color;
      color: $ay-grey-80-color;

      .truncate {
        background-image: linear-gradient(90deg, $ay-grey-80-color 85%, rgba(0, 0, 0, 0));
      }
    }
  }
}

.tab-body {
  margin-top: 2rem;

  .tab-content {
    display: none;

    &.visible {
      display: block;
    }
  }
}
