@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/shadows';
@import 'src/assets/stylesheets/zIndexes';

$input-height: 2.5rem;

.outer-container {
  height: $input-height + 0.125; // 0.125 is 0.0625 * 2 (twice border-width)
  position: relative;
  width: 100%;
}

.inner-container {
  @include input-border-style;

  background-color: $ay-white-color;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: $z-index-9;

  &-focused {
    border-color: $primary-color-400;
    box-shadow: $shadow-level-2;
  }
}

.input-container {
  align-items: center;
  display: flex;
  padding: 0 1rem;
}

.input-inner-wrapper {
  align-items: center;
  display: flex;
  flex: 1;

  &.with-right-icon {
    flex-direction: row-reverse;
  }
}

.icon {
  margin-right: 0.5rem;
}

.button-clear {
  opacity: 1;
  padding: 0.5rem;
  position: absolute;
  right: 0.25rem;
  top: 0.35rem;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.input {
  border: 0;
  box-sizing: border-box;
  font-size: 1rem;
  height: $input-height;
  line-height: 1.5rem;
  outline: none;
  width: 100%;

  &::placeholder {
    color: $ay-grey-64-color;
  }
}

.separator {
  border-color: $ay-grey-16-color;
  border-style: solid;
  border-width: 0.0625rem 0 0;
  margin: 0 auto;
  width: calc(100% - 0.5rem * 2); // 0.5 is the space from the edges
}

.suggestions {
  height: auto;
  max-height: 16rem;
  overflow-y: auto;
}

.item-feedback {
  color: $ay-grey-64-color;
  display: block;
  font-size: 0.75rem;
  line-height: 1.5rem;
  margin: 0;
  padding: 0.5rem 1rem;
}

.item {
  background-color: $ay-white-color;
  display: block;

  &:hover {
    background-color: $ay-grey-8-color;
    cursor: pointer;
  }

  &-active {
    background-color: $ay-grey-8-color;
  }

  &:not(:first-of-type) {
    border-top: 1px solid $ay-grey-16-color;
  }
}

.create-item-box {
  background-color: $ay-white-color;
  border-top: 1px solid $ay-grey-16-color;
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
}

.searched-input {
  color: $ui-color-500;
}

.icon-wrapper {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
}
