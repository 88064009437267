@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/variables';

.image {
  display: block;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  max-height: 6rem;
  max-width: 6rem;
  object-fit: contain;
}

.text {
  display: inline-block;
  font-weight: 400;
  padding-top: 1rem;
  text-align: center;
}

.title {
  font-size: 1rem;
  font-weight: normal;
  left: 1.5rem;
  line-height: 1.5rem;
  margin: 0;
  position: absolute;
  top: 1.5rem;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 1rem;

  &.dark-skin {
    color: $ay-pure-white-color;
  }

  &.light-skin {
    color: $ui-color-700;
  }

  &.font-size-sm {
    font-size: 0.75rem;
  }

  &.font-size-base {
    font-size: 1rem;
  }

  &.font-size-lg {
    font-size: 1.25rem;
  }

  &.bordered {
    border-radius: $ay-border-radius-sm;
    border-style: solid;
    border-width: 0.0625rem;
    padding: 1rem;

    &.dark-skin {
      border-color: $ay-pure-white-color;
    }

    &.light-skin {
      border-color: $ui-color-300;
    }
  }
}
