@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/design-system/colors';

.menu {
  display: flex;
  z-index: $modal-overlay-z-index;
}

.grid-control {
  background: transparent;
  border: 0;
  cursor: pointer;
}

.menulist {
  background-color: $ui-color-white;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(137, 136, 147, 0.32);
  position: absolute;
  top: 100%;
  z-index: $modal-overlay-z-index;
}

.links {
  min-width: 17.125rem;
}

.external-links {
  background-color: $ay-grey-8-color;
  border-radius: 0.5rem;
  margin-left: 1rem;
  padding: 0.625rem 0.75rem;
  width: 8.813rem;
}

.links-container {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.link-wrapper {
  cursor: pointer;
  display: flex;
  margin-bottom: 1rem;
  padding: 0.5rem;
  text-decoration: none;
}

.link-wrapper:hover {
  background-color: $ay-grey-8-color;
  border-radius: 0.25rem;
}

.link-title {
  color: $ay-total-black-color;
  display: block;
  font-size: 0.75rem;
  line-height: 0.8125rem;
}

.link-subtitle {
  color: $ui-color-500;
  font-size: 0.625rem;
}

.link-icon {
  background-color: $ay-grey-8-color;
  border-radius: 0.13rem;
  margin-right: 1rem;
  padding: 0.5rem;
}

.external-link-title {
  color: $ay-total-black-color;
  display: block;
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.external-link {
  color: $ui-color-700;
  cursor: pointer;
  display: block;
  font-size: 0.75rem;
  margin-bottom: 1rem;
  text-decoration: none;
}
