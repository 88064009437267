@import 'src/assets/stylesheets/design-system/colors';

.graph-container {
  height: 9.5rem;
  overflow: hidden;

  &.small {
    height: 8rem;
  }
}

.container {
  cursor: default;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.title {
  color: $ui-color-white;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.75rem;
  margin-left: 1.5rem;
  margin-top: 1.5rem;
}

.svg-graph {
  overflow: visible;
}

.legend-container {
  color: $ay-white-color;
  display: flex;
  font-size: 0.75rem;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  .legend {
    margin-left: 0.25rem;
    padding-bottom: 0;
    padding-top: 0;

    &-list {
      align-items: center;
      display: flex;
      justify-content: space-between;

      > * {
        margin: 0;

        &:not(:last-child) {
          border-right: 0.0625rem solid $primary-color-900;
          margin-right: 0.5rem;
          padding-right: 0.5rem;
        }
      }
    }
  }
}
