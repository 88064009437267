@import '../../assets/stylesheets/design-system/colors';

.login-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 95vh;
  justify-content: center;
  margin: 0 auto;
  width: 24rem;
}

.logo-container {
  margin: 0 0 2.5rem;
  padding-top: 2rem;
  text-align: center;

  &.sign-up {
    margin: 0 0 2.5rem;
  }
}

.ms-loading-popup {
  text-align: center;
}

.login-message {
  color: $ay-grey-80-color;
  text-align: center;
}

.error-link-container {
  margin-top: 0.5rem;
}
