@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';

.toggle-container {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: $z-index-1;
}

.toggle-header {
  display: flex;
  justify-content: space-between;
}

.toggle-options {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.toggle-option {
  background-color: $ay-grey-8-color;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
}

.toggle-option-active {
  background-color: $ay-white-color;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0.0625rem $supportive-color-500;
}

.toggle-modal {
  background-color: $ay-white-color;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem 1rem;
  width: 16rem;
}

.toggle-close {
  background-color: transparent;
  color: $ui-color-500;
  display: flex;
}

.toggle-button {
  background-color: $ay-white-color;
  border-radius: 0.25rem;
  display: flex;
  padding: 0.5rem;
}
