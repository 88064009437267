.container {
  display: grid;
  gap: 0;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 2.25rem;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;

    > div:first-child {
      bottom: 0.25rem;
      position: relative;
    }
  }
}
