@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/variables';
@import 'src/assets/stylesheets/shadows';

.owner-portfolio-graph-container {
  height: 19rem;
}

.container {
  cursor: default;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.floating-actions {
  display: flex;
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  z-index: $z-index-1;

  .see-in-list-button {
    margin-right: 0.5rem;
  }

  .market-switch-container {
    display: inline-block;
  }
}

.title-container {
  position: absolute;
  z-index: $z-index-2;
}

.title {
  color: $ay-pure-white-color;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 1.5rem 1.5rem 0.1rem;
  text-shadow: $text-shadow-level-2;

  &.large {
    margin: 1.5rem 8rem 0 1.5rem;
  }
}

.subtitle {
  color: $ui-color-200;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-left: 1.5rem;
  margin-top: 0;
}

.owner-link {
  color: $ay-white-color;
  font-size: 1rem;
  line-height: 1.5rem;
  text-shadow: $text-shadow-level-2;

  &:hover {
    color: $primary-color-200;
  }
}

.svg-graph-wrapper {
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

.svg-graph {
  height: 15.62rem;
  left: 50%;
  max-height: 90%;
  overflow: visible;
  position: absolute;
  top: 50%;
  transform: rotate(180deg) translate(50%, 50%);
}

.tooltip-spinner-container {
  height: 2.5rem;
  margin-top: -1rem;
  padding-bottom: 1.5rem;
  text-align: center;
}

.tooltip-right {
  align-items: center;
  background-color: $ui-color-white;
  border-radius: $ay-border-radius;
  box-shadow: 0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.2);
  color: $ay-black-color;
  display: none;
  flex-direction: column;
  justify-content: center;
  min-width: 4rem;
  padding: 1rem;
  position: absolute;
  white-space: nowrap;
  width: auto;
  z-index: 2;

  &:not(.tooltip-right-loading)::after {
    border-color: transparent $ay-grey-8-color transparent transparent;
    border-style: solid;
    border-width: 0.625rem;
    content: ' ';
    margin-top: -0.625rem;
    position: absolute;
    right: 100%;
    top: 50%;
  }
}

.tooltip-address {
  color: $ay-total-black-color;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 0.15rem;
}

.tooltip-main-text {
  color: $ay-total-black-color;
  font-size: 1.25rem;
  margin-bottom: 0;
}

.tooltip-small-text {
  color: $ui-color-600;
  font-size: 0.75rem;
  line-height: 1.2rem;
}

.tooltip-container {
  display: flex;
  height: 100%;
}

.tooltip-image {
  border-radius: $ay-border-radius;
  height: 4.875rem;
  width: 4.875rem;
}

.tooltip-image,
.tooltip-map {
  margin-right: 1rem;
}
