@font-face {
  font-family: 'Open Sans';
  font-weight: normal;
  src: url('../fonts/OpenSans-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
}
