@import 'src/assets/stylesheets/design-system/colors';

.link {
  color: $primary-color-400;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
