@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/mixins';

.container {
  align-items: center;
  background-color: $ay-pure-white-color;
  border-radius: 0.25rem;
  bottom: -4.5rem;
  box-shadow: 0 0.5rem 1rem rgba(1, 14, 40, 0.08);
  box-sizing: border-box;
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  left: calc((100% - 70rem) / 2);
  line-height: 0.66rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: fixed;
  transition: bottom 0.3s ease-in-out;
  visibility: hidden;
  width: 70rem;
  z-index: $notification-z-index;

  &.success {
    border: 0.0625rem solid $ay-grass-green-color;
  }

  &.error {
    border: 0.0625rem solid $ay-main-red-color;
  }

  &.visible {
    bottom: 1rem;
    visibility: visible;
  }
}

.content-left {
  align-items: center;
  color: $ay-grey-80-color;
  display: flex;
}

.icon {
  margin-right: 1rem;
}

.close-button {
  @include reset-button;
}

.right-text-button {
  @include reset-button;

  align-items: center;
  color: $primary-color-400;
  cursor: pointer;
  display: flex;
}

.right-arrow-night-icon {
  margin-left: 0.5rem;
}
