@import 'src/assets/stylesheets/css-library/brand/fonts';
@import 'src/assets/stylesheets/design-system/colors';

.title {
  color: $ay-grey-80-color;
}

.body-content {
  color: $ay-grey-40-color;
}
