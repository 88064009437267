@import 'src/assets/stylesheets/design-system/colors';

.container {
  align-items: center;
  display: inline-flex;
}

.disabled {
  opacity: 0.4;
}
