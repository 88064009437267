@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/variables';

.search-suggestion-item {
  cursor: pointer;
  display: flex;
  padding: 1rem;
  text-align: left;

  .suggestion-value {
    display: flex;
    flex: 1 1;

    &:hover {
      background: $ay-grey-8-color;
    }

    &.custom-option {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 0.75rem 0;

      &.with-border {
        border-top: 0.0625rem $ay-grey-16-color solid;
      }
    }
  }

  &.search-suggestion-item-active {
    .suggestion-value {
      background: $ay-grey-8-color;
    }
  }

  .suggestion-text-primary {
    color: $ay-grey-64-color;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;

    .img-profile {
      height: 0.75rem;
      margin-right: 0.5rem;
      width: 0.75rem;
    }

    .highlight {
      color: $ay-black-color;

      &.bold {
        font-weight: 600;
      }
    }
  }

  .suggestion-text-secondary {
    color: $ay-grey-40-color;
    font-size: 0.75rem;
    line-height: 1rem;
    margin: 0;

    &.text-capitalized {
      text-transform: capitalize;
    }
  }
}

.text-container {
  flex: 1;
}
