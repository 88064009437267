.truncated {
  -webkit-background-clip: text;
  max-width: 9.375rem;
  overflow: hidden;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
}

.truncate-tooltip {
  font-size: 0.625rem;
  line-height: 0.625rem;
  padding: 0.25rem 0.3rem;
}
