@import 'src/assets/stylesheets/design-system/colors';

@mixin size-map {
  height: 7.125rem;
  width: 9.5rem;
}

@mixin size-map-small {
  height: 3.5rem;
  width: 4.5rem;
}

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.25rem;
  flex-shrink: 0;
  height: 7.125rem;
  margin-right: 1rem;
  object-fit: cover;
  width: 9.5rem;

  &.small {
    @include size-map-small;

    background-position: inherit;
    margin-right: 0.5rem;
  }
}

.container-map {
  @include size-map;

  margin-right: 1rem;

  section {
    height: 7.125rem;
  }

  .map {
    @include size-map;

    margin-right: 1rem;
  }

  .map-small {
    @include size-map-small;

    margin-right: 1rem;
  }

  &.small {
    @include size-map-small;

    margin-right: 0.5rem;
  }
}
