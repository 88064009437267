@import 'src/assets/stylesheets/design-system/colors';

.table-const {
  margin-bottom: 5rem;
  margin-top: 2rem;
}

.scroll-position {
  bottom: 0;
}

.table-container {
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 2rem;
  width: 100%;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  table,
  th,
  td {
    border: 0.0625rem solid $ay-grey-16-color;
  }

  .content-row {
    color: $ay-grey-80-color;
    line-height: 1.5rem;
  }
}

@mixin cell {
  color: $ay-grey-80-color;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.2rem;
  min-width: 7rem;
  overflow: hidden;
  padding: 0.5rem;
  text-align: left;
  white-space: normal;
}

.cell-property {
  @include cell;

  font-weight: 600;
  min-width: 14rem;
}

.cell-name {
  @include cell;

  font-weight: normal;
}

.cell-link {
  -webkit-box-orient: vertical;
  color: $ay-grey-80-color;
  cursor: pointer;
  display: -webkit-box;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: pre-wrap;

  &:hover {
    color: $primary-color-400;
  }
}

.cell-tenant {
  min-width: 14rem;
}

.broker {
  display: inline-block;
  min-width: 14rem;
}

.cell-price {
  background-color: $ay-frozen-grass-color;
  border-radius: 0.25rem;
  display: inline-block;
  line-height: 1.5rem;
  padding: 0.25rem 0.5rem;
}

.capitalized {
  text-transform: capitalize;
}
