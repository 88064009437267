@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/shadows';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/variables';

.vertical-label {
  color: $ay-grey-64-color;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 0.5rem;
}

.container {
  border-radius: $ay-border-radius;
  position: relative;

  &.container-margin-bottom {
    margin-bottom: 0.5rem;
  }

  &.container-disabled {
    opacity: 0.4;
  }

  &.background-ay-grey-8-color {
    background-color: $ay-grey-8-color;
  }

  &.background-ay-white-color {
    background-color: $ay-white-color;
  }

  &.background-ay-pure-white-color {
    background-color: $ay-pure-white-color;
  }

  &.container-small {
    max-height: 3rem;
  }

  &.active {
    border-radius: $ay-border-radius $ay-border-radius 0 0;
    box-shadow: $shadow-level-2;
  }
}

.result-bar {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: $ay-black-color;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
  position: relative;
  text-overflow: clip;
  white-space: nowrap;

  &.result-bar-regular {
    padding: 1rem;
  }

  &.result-bar-small {
    padding: 0.7rem 0.5rem 0.7rem 1rem;
  }

  &.hoverable:hover {
    border: 1px solid $primary-color-200;
  }

  &.result-bar-disabled {
    cursor: default;
  }

  &.has-border {
    border-color: $primary-color-200 !important;
  }
}

.arrow-container {
  height: 3.5rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 4rem;

  &.background-ay-grey-8-color {
    background: linear-gradient(271.2deg, $ay-grey-8-color 70.36%, rgba(252, 252, 252, 0) 99.18%);
  }

  &.background-ay-white-color {
    background: linear-gradient(271.2deg, $ay-white-color 70.36%, rgba(252, 252, 252, 0) 99.18%);
  }

  &.background-ay-pure-white-color {
    background: linear-gradient(271.2deg, $ay-pure-white-color 70.36%, rgba(252, 252, 252, 0) 99.18%);
  }

  &.arrow-container-small {
    height: 3rem;
  }

  .arrow {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.label-container {
  margin-right: 1.5rem;
  overflow: hidden;
}

.label {
  color: $ay-grey-64-color;
}

.search-input-container {
  box-sizing: border-box;
  padding: 0 1rem;
  position: relative;
  width: 100%;

  &.background-ay-grey-8-color {
    background: $ay-grey-8-color;
  }

  &.background-ay-white-color {
    background: $ay-white-color;
  }

  &.background-ay-pure-white-color {
    background: $ay-pure-white-color;
  }

  .search-input {
    background:
      $ay-pure-white-color
      url('../../assets/images/icons/search-grey-64.svg') no-repeat 1rem 50%;
    background-size: 1rem;
    border: 1px solid $primary-color-400;
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: $ay-black-color;
    display: none;
    font-size: 1rem;
    line-height: 1.5rem;
    outline: none;
    padding: 0.5rem 0.5rem 0.5rem 3rem;
    width: 100%;

    &::placeholder {
      color: $ay-grey-40-color;
    }

    &.active {
      display: block;
    }
  }

  .clear-search {
    background: transparent;
    border: 0;
    cursor: pointer;
    height: 1.5rem;
    outline: none;
    position: absolute;
    right: 1.5rem;
    top: 0.5rem;
    width: 1.5rem;

    .clear-search-icon {
      vertical-align: middle;
    }
  }
}

.options {
  border-radius: 0 0 0.5rem 0.5rem;
  box-sizing: border-box;
  display: none;
  max-height: 28rem;
  overflow-x: hidden;
  overflow-y: overlay;
  padding: 0.5rem 1rem 1rem;
  position: relative;
  width: 100%;

  &.fixed-height {
    height: 28rem;
  }

  &.active {
    display: block;
  }

  .select-deselect {
    position: absolute;
    right: 1rem;
  }
}

.checkbox-item {
  max-width: 15.375rem;
  width: 15.375rem;
}

.radio-item {
  max-width: 12.6rem;
  width: 12.6rem;
}

.options-container {
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: $shadow-option-search-filter;
  display: none;
  position: absolute;
  width: 100%;
  z-index: $z-index-10;

  &.background-ay-grey-8-color {
    background: $ay-grey-8-color;
  }

  &.background-ay-white-color {
    background: $ay-white-color;
  }

  &.background-ay-pure-white-color {
    background: $ay-pure-white-color;
  }

  &.active {
    display: block;
  }
}
