@import 'src/assets/stylesheets/css-library/brand/fonts';
@import 'src/assets/stylesheets/design-system/colors';

.container {
  position: relative;

  .input {
    height: 3rem;
  }

  .button {
    background-color: $ay-white-color;
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    height: 2rem;
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    width: 2rem;
  }
}
