@import '../../assets/stylesheets/design-system/colors';

.container {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 2rem;
  user-select: none;
}

.checkbox {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  width: 0;
}

.checkmark {
  background-color: $ay-white-color;
  border: 1px solid $ay-grey-24-color;
  border-radius: 0.25rem;
  height: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  width: 1.5rem;
}

.checkbox:checked ~ .checkmark {
  background-color: $ay-grass-green-color;
  border: 1px solid $ay-grass-green-color;
}

.checkmark::after {
  background: url('../../assets/images/icons/check.svg') no-repeat center;
  content: '';
  display: none;
  height: 100%;
  width: 100%;
}

.checkbox:checked ~ .checkmark::after {
  display: block;
}

.label {
  line-height: 1.5rem;
  padding-left: 1rem;
}

.selected {
  font-weight: 600;
}
