@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/shadows';

.attachment-thumbnail-container {
  border: 0.0625rem solid $ay-grey-16-color;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  height: inherit;
}

.attachment-thumbnail-footer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 0.5rem;
}

.attachment-name {
  color: $ui-color-black;
  line-height: 1.5rem;
}

.action-buttons-container {
  display: flex;
  gap: 0.25rem;
  justify-content: flex-end;
}

.action-button {
  background-color: $ay-grey-8-color;
}
