@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';

.container {
  border: 0.0625rem solid $ay-grey-16-color;
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0.5rem 1rem;

  &.no-border {
    border: 0;
  }

  &.background-ay-white-color {
    background-color: $ay-white-color;
  }

  &.background-ay-grey-8-color {
    background-color: $ay-grey-8-color;
  }

  &.disabled {
    opacity: 0.4;
  }
}

.content {
  display: flex;
  margin: 0.5rem 0;

  .image-container {
    position: relative;
  }

  .image {
    border-radius: 0.25rem;
    height: 4rem;
    margin-right: 1rem;
    overflow: hidden;
    width: 4rem;
  }
}

.line1 {
  color: $ay-black-color;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 0;
}

.line2 {
  color: $ay-grey-80-color;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.2rem;
  margin: 0;
}

.line3 {
  color: $ay-grey-40-color;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.2rem;
  margin: 0;
}

.clickable-address {
  background: transparent;
  border: 0;
  color: $ay-black-color;
  cursor: pointer;
  display: flex;
  padding-left: 0;

  .title-icon {
    padding-top: 0.05rem;
    width: 1rem;
  }

  &:hover {
    color: $primary-color-400;
  }
}

.top-button-container {
  display: block;

  .top-left-button {
    border: 0;
    cursor: pointer;
    height: 1rem;
    left: 1.25rem;
    position: absolute;
    top: 1.25rem;
    width: 1rem;
    z-index: $z-index-1;
  }

  .featured {
    background: transparent url('../../../assets/images/icons/featured-property.svg') no-repeat center;
  }

  .featured-active {
    background: transparent url('../../../assets/images/icons/featured-property-active.svg') no-repeat center;
  }
}

.top-right-button {
  align-items: center;
  border: 0;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  opacity: 1;
  outline: none;
  width: 1.5rem;

  &:disabled {
    cursor: initial;
  }
}
