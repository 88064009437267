@import 'src/assets/stylesheets/design-system/colors';

.container {
  display: flex;
}

.label {
  color: $ay-grey-64-color;
  font-size: 1rem;
  line-height: 1.5rem;
  min-width: 6rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  text-align: right;
  vertical-align: middle;
  width: 6rem;
}

.search-container {
  background-color: $ui-color-white;
  border-radius: 0.25rem;
  width: calc(100% - 7rem);

  &.no-label {
    width: 100%;
  }

  &.disabled {
    opacity: 0.4;
  }
}

.result-bar {
  align-items: center;
  border: 0.0625rem solid $ay-grey-24-color;
  border-radius: 0.25rem;
  color: $ay-black-color;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
  padding: 0.5rem;
  position: relative;
  text-overflow: clip;
  white-space: nowrap;

  &.hoverable:hover {
    border: 0.0625rem solid $primary-color-200;

    .label {
      color: $primary-color-500;
    }
  }

  &.result-bar-disabled {
    cursor: auto;
  }
}

.active {
  border: 0.0625rem solid transparent;
}

.arrow-container {
  background: linear-gradient(271.2deg, $ui-color-white 70.36%, rgba(252, 252, 252, 0) 99.18%);
  height: 2.5rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 4rem;

  .arrow {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.search-label-container {
  margin-right: 1.5rem;
  overflow: hidden;
}

.search-label {
  color: $ay-grey-64-color;
  margin-left: 0.5rem;
}

.search-input-container {
  box-sizing: border-box;
  margin: 0 2rem 0 1rem;
  position: relative;

  .search-input {
    background: $ui-color-white url('../../assets/images/icons/search-grey-64.svg') no-repeat 1rem 50%;
    background-size: 1rem;
    border: 0.0625rem solid $ay-grey-40-color;
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: $ay-black-color;
    display: none;
    font-size: 1rem;
    line-height: 1.5rem;
    outline: none;
    padding: 0.5rem 0.5rem 0.5rem 3rem;
    width: 100%;

    &::placeholder {
      color: $ay-grey-40-color;
    }

    &.active {
      display: block;
    }
  }

  .clear-search {
    background: transparent;
    border: 0;
    cursor: pointer;
    height: 1.5rem;
    outline: none;
    position: absolute;
    right: 1.5rem;
    top: 0.5rem;
    width: 1.5rem;

    .clear-search-icon {
      vertical-align: middle;
    }
  }
}

.options {
  display: none;
  max-height: 28rem;
  overflow: auto;
  padding: 1rem 0 0 1rem;

  &.active {
    display: block;
  }
}

.checkbox-items {
  max-width: 28.5rem;
  width: 28.5rem;

  &.no-label {
    max-width: 25.5rem;
    width: 25.5rem;
  }
}
