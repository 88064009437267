@import 'src/assets/stylesheets/design-system/colors';

.average-legends {
  background-color: $ay-white-color;
  border-radius: 0.25rem;
  left: 1rem;
  padding: 0.625rem 1.25rem 0;
  position: absolute;
  top: 1rem;
  width: 10rem;

  .average-title {
    color: $ay-black-color;
    font-size: 0.875rem;
    font-weight: 600;
    height: 1.3125rem;
    width: 100%;
  }

  .box-colors-container {
    margin-top: 0.625rem;
    padding: 0.625rem 0;
    width: 100%;

    .lengend-container {
      display: flex;
      justify-content: flex-start;
    }
  }

  .average-box-colors {
    border-radius: 0.125rem;
    height: 1rem;
    margin: 0 0.625rem 1.25rem 0;
    width: 1rem;

    &.compset {
      background-color: $supportive-color-500;
    }

    &.target-property {
      background-color: $primary-on-dark;
    }
  }

  span {
    color: $ay-black-color;
    font-size: 0.875rem;
    font-weight: 400;
  }
}
