@import 'src/assets/stylesheets/design-system/colors';

.container {
  display: flex;
}

.header-label-container {
  display: block;
}

.label {
  color: $ay-grey-64-color;
  font-size: 1rem;
  line-height: 1.5rem;
  min-width: 6rem;
  padding-right: 1rem;
  padding-top: 1rem;
  text-align: right;
  vertical-align: middle;
  width: 6rem;

  &.multiline {
    padding-top: 0.3rem;
  }
}

.header-label {
  margin-bottom: 0.5rem;
  min-width: initial;
  padding-top: 0;
  text-align: left;
  width: initial;
}

.extra-filter-node {
  width: 19.75rem;
}

.extended {
  min-width: 7rem;
}
