@import 'src/assets/stylesheets/variables';
@import 'src/assets/stylesheets/design-system/colors';

.card {
  border-radius: $ay-border-radius-sm;
  box-sizing: border-box;
  height: 100%;
  min-height: 4rem;
  position: relative;
  width: 13rem;

  &.main {
    background-color: $ay-pure-white-color;
    border: 0.0625rem solid $ay-grey-16-color;
  }

  &.secondary {
    background-color: $ui-color-100;
    border: 0.0625rem solid $ui-color-100;
  }

  &.tenant-prospect {
    background-color: $ay-grey-8-color;
    border: 0.0625rem solid $ay-grey-8-color;
  }
}
