@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/variables';

@keyframes showSuggestions {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

$search-input-height-small: 2.5rem;
$search-input-height-medium: 3rem;
$search-input-height-large: 4rem;

.separator {
  background-color: #f4f4f5;
  color: #777886;
  font-size: 0.8rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-top: 0.25rem;
}

.search-suggestions {
  animation: showSuggestions 100ms linear both;
  display: block;
  flex-direction: column;
  gap: 1rem;
  height: auto;
  list-style: none;
  margin: 0;
  max-height: 16rem;
  overflow: auto;
  padding: 0;

  &.search-suggestions-hidden {
    display: none;
  }

  li {
    &:hover {
      background: $ay-grey-8-color;
    }
  }
}

.search-input {
  border: 0;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: $ay-grey-64-color;
  flex: 2;
  font-family: inherit;
  font-size: 1rem;
  outline: none;
  padding-left: 1rem;
  width: 100%;

  &-small {
    height: $search-input-height-small;
    line-height: $search-input-height-small;
  }

  &-medium {
    height: $search-input-height-medium;
    line-height: $search-input-height-medium;
  }

  &-large {
    height: $search-input-height-large;
    line-height: $search-input-height-large;
  }

  &::placeholder {
    color: $ay-grey-64-color;
  }

  &-icon {
    background: $ui-color-white url('../../assets/images/icons/search-inactive.svg') no-repeat 20px 50%;
    padding-left: 3.5rem;
  }

  &-disabled {
    opacity: 0.4;
  }
}

.search-input-wrapper {
  background: $ui-color-white;
  border-radius: 0.25rem;
  position: relative;
  width: 100%;

  &-small {
    min-height: $search-input-height-small;
  }

  &-medium {
    min-height: $search-input-height-medium;
  }

  &-large {
    min-height: $search-input-height-large;
  }

  &-editable {
    width: 100%;
  }

  &-focused {
    z-index: $z-index-9;
  }
}

.input-container {
  align-items: center;
  display: flex;
  position: relative;
}

.suggestions-container {
  @include input-border-style;

  background: $ui-color-white;
  border-radius: 0.25rem;
  height: auto;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: $z-index-1;

  &-disabled:hover {
    border-color: $ay-grey-16-color !important;
  }

  &.active {
    border: 1px solid $primary-color-400;
    box-shadow: 0 8px 24px rgba(0, 32, 91, 0.24);
  }
}

.editable-button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 2rem;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  width: 2rem;
}

.clear-search {
  background: transparent;
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin-right: 1rem;
  outline: none;
  position: absolute;
  right: 1.5rem;
  top: 0.5rem;
  width: 1.5rem;

  .clear-search-icon {
    vertical-align: middle;
  }
}
