@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.container {
  display: flex;
  flex-direction: column;
}

.picture-container {
  background: $ay-white-color;
  border: 0.0625rem solid $ay-grey-16-color;
  border-radius: 0.25rem;
  float: left;
  margin: 1rem 1rem 1rem 0;
  position: relative;
  width: 16rem;

  .container-img {
    background-size: cover;
    height: 7.5rem;
    margin-bottom: 1rem;

    .item-img {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      height: 7.5rem;
      object-fit: cover;
      width: 100%;
    }

    .delete-btn {
      @include eager-background-image('icons/delete.svg', 'icons/delete-white.svg');

      background-color: $ay-grey-8-color;
      background-position: top 0.25rem left 0.25rem;
      background-repeat: no-repeat;
      background-size: 1rem;
      border-radius: 0.25rem;
      box-shadow: 0 0 0.5rem -0.25rem rgba(0, 0, 0, 0.75);
      height: 1.5rem;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      width: 1.5rem;

      &:hover {
        background-color: $ay-main-red-color;
      }
    }
  }

  .img-trash {
    height: 1rem;
    padding: 0.25rem 0.125rem 0.125rem;
    width: 1rem;
  }

  .title {
    color: $ay-grey-80-color;
    margin: 0 0 1rem 1rem;
  }
}

.progress-bar {
  background-color: $ay-white-color;
  height: 0.25rem;
  margin-top: -0.375rem;
  width: 100%;

  .progress-bar-fill {
    background-color: $ay-success-color;
    border-radius: 0.25rem;
    height: 0.25rem;
  }
}

.file-name {
  display: block;
  font-size: 1rem;
  margin-top: -1rem;
  overflow: hidden;
  padding: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.note-container {
  textarea {
    border: 0.0625rem solid $ay-grey-16-color;
    border-radius: 0.25rem;
    height: 5.125rem;
    resize: none;
  }
}
