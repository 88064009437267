@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.owner-container {
  margin-bottom: 0;
}

.card-separator {
  @include card-separator;

  margin: 0.5rem 0;
}

.content {
  .mobile-section-container {
    display: none;
  }

  .text {
    font-size: 1rem;
    font-style: normal;
    margin: 0 0 0.25rem;
  }

  .notes {
    color: $ui-color-black;
    margin: 0.225rem 0 0.5rem;
  }
}

.tag {
  &:hover {
    cursor: pointer;
  }
}

.tag-share {
  background-color: $ui-color-200;
}

.share {
  background-color: $ay-grey-8-color;
}

.scoop-media {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid $ay-grey-16-color;
  height: 16rem;
  margin-bottom: 1rem;
  width: 19.0625rem;
}

.background-ui-color-100 {
  background-color: $ui-color-100;
}

@media only screen and (max-width: 48rem) {
  .content {
    .btn-card {
      display: none;
    }

    .mobile-section-container {
      display: block;

      .btn-card-mobile {
        margin-right: 1rem;
      }
    }
  }
}
