@import '../../assets/stylesheets/global';
@import '../../assets/stylesheets/zIndexes';
@import '../../assets/stylesheets/navbar';
@import '../../assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/variables';

.navbar {
  align-items: center;
  background: $ui-color-white;
  display: flex;
  height: $navbar-height;
  justify-content: space-between;
  left: 0;
  padding: 0 1.5rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $navbar-z-index;
}

.navbar-border {
  border-bottom: 1px solid $ay-grey-16-color;
}

.icon {
  display: flex;
  flex: 1;
}

.logo {
  border-right: 1px solid $ui-color-200;
  cursor: pointer;
  display: block;
  margin-right: 1rem;
  padding-right: 1rem;
}

.search {
  align-items: center;
  display: flex;
  flex: 1;
  width: 25rem;
}

.input-container {
  background: $ay-grey-8-color;
  border: 0;
  border-radius: $ay-border-radius;
  height: 3rem;
  overflow: hidden;
  position: relative;
  width: 25rem;
}

.search-input {
  background:
    transparent url('../../assets/images/icons/search-grey-64.svg')
    no-repeat 20px 50%;
  background-size: 1rem;
  border: 0;
  color: $ay-grey-64-color;
  font-family: inherit;
  font-size: 1rem;
  height: 100%;
  outline: none;
  padding: 0 0 0 3rem;
  width: 80%;
}

.clear-search {
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 0.5rem;
  top: 1rem;
}

.settings {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.nav-link {
  color: $primary-color-900;
  cursor: pointer;
  line-height: 1.5rem;
  padding: 0 1rem;
  text-decoration: none;
  white-space: nowrap;

  &:last-child {
    padding-right: 1.5rem;
  }
}

.profile {
  cursor: pointer;
  margin: 0 2.5rem 0 1rem;
}

.link-profile {
  display: block;
  margin-left: 0.5rem;
  text-decoration: none;
}

.container-add-button {
  .add-button {
    width: 5rem;

    path {
      stroke: $primary-color-500;
    }

    &:hover,
    &:focus {
      path {
        stroke: $ui-color-white;
      }
    }
  }

  .add-button-non-english {
    width: 6.5rem;
  }
}

.add-comps-button-option {
  white-space: nowrap;
}

.project-link {
  margin-right: 1rem;
}
