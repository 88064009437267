@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/shadows';

$submit-btn-container-height: 5rem;

.container {
  border-radius: 0.25rem;
  padding: 0.75rem;

  .large-input-container {
    display: flex;
  }

  .subtitle {
    color: $ay-grey-64-color;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.25rem;
    margin: 0 0 0.5rem;
    padding-right: 0.75rem;
  }

  .comment-area {
    background-color: $ui-color-white;
    border: 0.0625rem solid $ay-grey-16-color;
    border-collapse: collapse;
    border-radius: 0.25rem;
    font-family: inherit;
    font-size: 1rem;
    min-height: 5rem;
    outline: none;
    padding: 1rem;
    width: 100%;

    &:focus {
      border-color: $primary-color-400;
    }
  }

  &.is-last {
    margin-bottom: $submit-btn-container-height;
    padding-bottom: 0;

    .subtitle {
      margin: 0;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .title {
    color: $ay-grey-80-color;
    font-size: 1.75rem;
    font-weight: normal;
    line-height: 2.25rem;
    margin: 0 0 1rem;
  }
}

.footer {
  background-color: $ui-color-white;
  bottom: 0;
  box-shadow: $shadow-level-3;
  height: $submit-btn-container-height;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;

  .actions-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    &.is-editing {
      justify-content: space-between;
    }
  }

  .delete-btn {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .cancel-btn {
    width: 6.25rem;
  }

  .submit-btn {
    margin-left: 1rem;
    width: 10rem;
  }
}

.attachment-container {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 0.5rem;
}

.button-remove-property {
  @include eager-background-image('icons/delete.svg', 'icons/delete-white.svg');

  background-color: $ay-grey-8-color;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem;

  &:hover {
    background-color: $ay-main-red-color;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem;
  }
}

.property-card-wrapper {
  margin-top: 1rem;
}

.update-summary {
  background: $ay-grey-8-color;
  margin-bottom: 5rem;
  margin-top: -1rem;
  padding: 1rem;
}

.delete-icon {
  right: 0.75rem;

  img {
    height: 1rem;
    width: 0.833rem;
  }
}

.download-icon {
  right: 3.25rem;
}

.icon {
  align-items: center;
  background-color: $ui-color-white;
  border: 1px solid $ui-color-200;
  border-radius: 0.25rem;
  color: $ui-color-600;
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  position: absolute;
  top: 0.5rem;
  width: 2rem;
}
