@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/shadows';

.container {
  background-color: $ay-grey-80-color;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $modal-overlay-z-index;
}

.card {
  background: $ay-white-color;
  border-radius: 0.25rem;
  box-shadow: $shadow-level-3;
  height: auto;
  left: 50%;
  margin: auto;
  padding: 2rem;
  position: fixed;
  text-align: left;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;
  z-index: $notification-z-index;

  .text {
    color: $ay-black-color;

    &-header {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    &-paragraph {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .paragraphs-group {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  .buttons-group {
    bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .modal-button {
      border-color: transparent;
      font-size: 1.125rem;
      width: 14rem;
    }

    .margin-right {
      margin-right: 2rem;
    }
  }
}

.close-button {
  cursor: pointer;
  height: 1rem;
  margin-right: 0.583rem;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0.5rem;
  width: 1rem;

  &:not(:disabled) {
    &:hover {
      background-color: $ay-grey-8-color;
      color: $ay-white-color;
    }
  }
}
