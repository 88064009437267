@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.link {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 0.5rem;
  padding: 0;

  &,
  &:visited {
    color: $primary-color-400;
  }
}

.footer {
  color: $ay-grey-80-color;
  font-size: 0.75rem;
  line-height: 1rem;
  position: relative;
}

.options-container {
  bottom: 0;
  position: absolute;
  right: 0;
}

.card-separator {
  @include card-separator;
}

.activity-date {
  color: $ay-grey-64-color;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  margin: 1rem 0 0.5rem;
  padding: 0;
}
