@import 'src/assets/stylesheets/design-system/colors';

.root {
  border: 1px solid $ui-color-300;
  border-radius: 0.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 17.125rem;
  padding: 1rem;
  position: relative;
}

.image {
  align-items: center;
  border: 1px solid $ui-color-200;
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: flex;
  height: 4.875rem;
  justify-content: center;
  margin-right: 1rem;
  padding: 0.563rem 0.75rem;
  width: 6.5rem;

  img {
    border-radius: 0.25rem;
  }
}

.title {
  color: $ui-color-900;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
  margin-right: 0.563rem;
  width: 14.75rem;
}

.footer {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;
}

.header {
  align-items: flex-start;
  display: flex;
  margin-bottom: 1.5rem;
}

.body {
  font-size: 1.5rem;
}

.tag {
  background-color: $ui-color-100;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.344rem 0.5rem;
}
