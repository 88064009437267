.common-agency-leasing-control {
  display: flex;
}

.control {
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.button {
  padding-left: 1rem;
  padding-right: 1rem;
}

.download-button-option {
  width: 100%;
}
