@import 'src/assets/stylesheets/design-system/colors';

.container {
  position: relative;
  width: 100%;
}

.textarea {
  background-color: $ui-color-100;
  border: 0;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: $ui-color-black;
  font-size: 1rem;
  line-height: 1.5rem;
  max-height: 6rem;
  min-height: 3.5rem;
  padding: 0.75rem;
  padding-right: 4.8rem;
  resize: none;
  width: 100%;

  &::placeholder {
    color: $ay-grey-40-color;
  }

  &.is-editing {
    padding-right: 9rem;
  }
}

.actions {
  height: 2.5rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;

  .button {
    height: 100%;
    padding: 0 0.8rem;

    &:disabled {
      background-color: $ay-grey-16-color;
    }

    &:first-child {
      margin-right: 0.5rem;
    }
  }
}
