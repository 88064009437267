@import 'src/assets/stylesheets/design-system/colors';

.donut-title {
  align-self: start;
  color: $ay-white-color;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin: 1.5rem 1.25rem 0;
}

.donut-missing-data {
  max-width: 13.75rem;
}

.donut-inner-container {
  padding-bottom: 1.625rem;
  padding-top: 1.25rem;
  position: relative;
  text-align: center;
  width: 100%;
}

.tooltip.tooltip {
  align-items: center;
  display: flex;
  flex-direction: column;

  > div:first-child {
    color: $ui-color-white;
    font-size: 0.875rem;
    font-weight: normal;
    margin: 0;
    padding: 0 0 0.625rem;
    text-transform: none;
  }

  > div:last-child {
    color: $ui-color-white;
    width: 100%;
  }
}
