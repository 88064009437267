@import 'src/assets/stylesheets/design-system/colors';

.container {
  display: flex;

  .header-link {
    color: $ay-grey-64-color;
    font-size: 1rem;
    margin: 0 0 0.25rem;
  }

  .text-container {
    display: flex;
    flex: 1;
  }

  .activity-property-info {
    margin: 0;
  }

  .property-address {
    color: $ay-black-color;
    display: block;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding: 0;
    text-decoration: none;

    &-clickable:hover {
      color: $primary-color-400;
      cursor: pointer;
    }
  }

  .property-name {
    color: $ay-grey-64-color;
    font-size: 1rem;
    margin: 0 0 0.25rem;
  }

  .tag {
    color: $ay-grey-64-color;
    font-size: 0.875rem;
    margin-left: 0.5rem;
  }

  .share {
    background-color: $ui-color-200;
  }

  .container-category {
    display: flex;
    flex-wrap: wrap;
  }

  .title-icon {
    margin-top: 0.06rem;
    width: 1rem;
  }

  .title {
    display: flex;
  }
}
