@import 'src/assets/stylesheets/design-system/colors';

.field-text {
  color: $ay-grey-80-color;
  margin: 0;
}

.bold {
  font-weight: 600;
}
