@import 'src/assets/stylesheets/design-system/colors';

.container {
  display: flex;
}

.label {
  color: $ay-grey-64-color;
  font-size: 1rem;
  line-height: 1.5rem;
  min-width: 6rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  text-align: right;
  vertical-align: middle;
  width: 6rem;
}

.search-container {
  width: 100%;
}

.results {
  display: flex;
  flex-flow: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.result-item {
  background-color: $ay-white-color;
  border-radius: 0.25rem;
  line-height: 1.5rem;
  padding: 0.25rem 0.75rem 0.25rem 0.5rem;
}

.remove-icon {
  cursor: pointer;
  margin-left: 0.5rem;
}

.label-extended {
  margin-top: -1.2rem;
}
