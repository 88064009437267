@import 'src/assets/stylesheets/zIndexes';

.container {
  align-items: center;
  display: flex;
  margin: auto;
  width: fit-content;
}

.text {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-left: 1rem;
  z-index: $z-index-2;
}
