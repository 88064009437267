@import '../../assets/stylesheets/design-system/colors';

.button {
  border: 0;
  border-radius: 0.25rem;
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: default;
  }

  &.regular-size {
    font-size: 1.25rem;
    min-height: 3.5rem;
    min-width: 16rem;
    padding: 0.5rem 1.375rem;
  }

  &.small-size {
    font-size: 1rem;
    min-height: 3rem;
    min-width: 7.5rem;
    padding: 0.5rem 1rem;
  }

  &.ghost {
    min-height: 0;
    min-width: 0;
    padding: 0.25rem 0.5rem;
  }

  &.left-icon,
  &.right-icon {
    background-repeat: no-repeat;
  }

  &.left-icon {
    background-position: top 50% left 1rem;

    &.small-size {
      padding-left: 2rem;
    }
  }

  &.right-icon {
    background-position: top 50% right 1rem;
    text-align: left;
  }
}

.main {
  background-color: $primary-color-500;

  &:disabled {
    background-color: $ay-grey-8-color;
    color: $ay-grey-24-color;
  }

  &:not(:disabled) {
    color: $ay-white-color;

    &:focus {
      background-color: $primary-color-400;
      color: $ay-white-color;
    }

    &:hover {
      background-color: $primary-color-400;
      color: $ay-white-color;
    }

    &:active {
      background-color: $primary-color-900;
    }
  }
}

.secondary {
  background-color: transparent;
  border: 1px solid $primary-color-200;
  color: $primary-color-400;

  &:disabled {
    border: 1px solid $ay-grey-16-color;
    color: $ay-grey-24-color;
  }

  &:not(:disabled) {
    &:focus,
    &:hover {
      background-color: $primary-color-500;
      border: 1px solid $primary-color-500;
      color: $ay-white-color;
    }

    &:active {
      background-color: $primary-color-400;
    }
  }

  &.left-icon,
  &.right-icon {
    &.regular-size {
      background-repeat: no-repeat;
      background-size: 1.5rem;
    }

    &.small-size {
      background-repeat: no-repeat;
      background-size: 1rem;
    }
  }

  &.left-icon {
    background-position: top 50% left 1rem;

    &.small-size {
      padding-left: 2rem;
    }
  }

  &.right-icon {
    background-position: top 50% right 1rem;
    text-align: left;
  }
}

.ghost {
  background-color: transparent;
  border: 0;
  color: $primary-color-500;

  &:disabled {
    color: $ay-grey-24-color;
  }

  &:not(:disabled) {
    &:focus,
    &:hover {
      background-color: $primary-color-100;
      color: $primary-color-500;
    }

    &:active {
      background-color: $primary-color-500;
      color: $ay-white-color;
    }
  }
}

.contextual {
  background-color: $primary-color-100;
  border: 0;
  color: $primary-color-500;

  &:disabled {
    background-color: $ay-grey-8-color;
    color: $ay-grey-24-color;
  }

  &:not(:disabled) {
    &:focus,
    &:hover {
      background-color: $primary-color-500;
      color: $ay-white-color;
    }

    &:active {
      background-color: $primary-color-900;
      color: $ay-white-color;
    }
  }
}

.neutral {
  background-color: $ay-grey-8-color;
  border: 0;
  color: $ay-grey-80-color;

  &:disabled {
    background-color: $ay-grey-8-color;
    color: $ay-grey-24-color;
  }

  &:not(:disabled) {
    &:focus,
    &:hover {
      background-color: $ay-grey-16-color;
      color: $ay-grey-80-color;
    }

    &:active {
      background-color: $ay-grey-64-color;
      color: $ay-white-color;
    }
  }
}

.delete {
  border: 1px solid $ay-error-color;
  color: $ay-error-color;

  &:focus {
    background-color: $ay-grey-80-color;
    color: $ay-white-color;
  }

  &.left-icon,
  &.right-icon {
    background-repeat: no-repeat;

    &.regular-size {
      background-size: 1.5rem;
    }

    &.small-size {
      background-size: 1rem;
    }
  }

  &.left-icon {
    background-position: top 50% left 1rem;

    &.small-size {
      min-width: 11.125rem;
      padding-left: 2rem;
    }
  }

  &.right-icon {
    background-position: top 50% right 1rem;
    text-align: left;

    &.small-size {
      min-width: 11.125rem;
    }
  }

  &:hover {
    background-color: $ay-error-color;
    color: $ay-white-color;
  }
}

.inline {
  background-color: $primary-color-100;
  height: 4rem;
  width: 100%;

  &.right-icon {
    background-position: top 50% right 1rem;
    background-repeat: no-repeat;
    background-size: 0.75rem;
    padding-right: 0 1rem 0 1rem;
    text-align: left;
  }

  &:not(:disabled) {
    color: $primary-color-900;

    &:focus {
      background-color: $primary-color-900;
      color: $ay-white-color;
    }

    &:hover {
      background-color: $primary-color-500;
      color: $ay-white-color;
    }
  }
}

.light {
  background-color: $ay-white-color;
  border: 0;
  color: $ay-grey-80-color;

  &:disabled {
    background-color: $ay-grey-8-color;
    color: $ay-grey-24-color;
  }

  &:not(:disabled) {
    &:focus,
    &:hover {
      background-color: $ay-grey-16-color;
      color: $ay-grey-80-color;
    }

    &:active {
      background-color: $ay-grey-64-color;
      color: $ay-white-color;
    }
  }
}
