@import 'src/assets/stylesheets/design-system/colors';

.svg-graph-container {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
}

.svg-graph {
  overflow: visible;
}

.tags {
  margin: 0 4.5rem 1.75rem;
  text-align: center;
}

.tooltip {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);

  .tooltip-title {
    color: $ay-pure-white-color;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin: 0;
    padding: 0 0.25em 0.25rem;
    text-transform: capitalize;
    white-space: pre-line;

    &.winter-grey {
      color: $ay-winter-grey-color;
    }

    &.white {
      color: $ay-pure-white-color;
    }

    &.small {
      font-size: 1rem;
      line-height: 1.25rem;
    }

    &.gold-color {
      color: $ay-main-gold-color;
    }
  }
}

.empty-tag {
  display: block;
  width: 100%;
}

.tag {
  background-color: transparent;
  border: 1px solid $ay-pure-white-color;
  border-radius: 0.25rem;
  color: $ay-pure-white-color;
  cursor: pointer;
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1.2rem;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  padding: 0.15rem 0.25rem;

  &:last-child {
    margin-right: 0;
  }

  &.subtitle {
    border: 0;
    color: $ui-color-white;
    margin-bottom: 0;
    padding: 0 0.5rem;
  }

  &.big-font {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &.winter-grey {
    border: 1px solid $ay-pure-white-color;
    color: $primary-color-900;

    &.active {
      background-color: $ay-pure-white-color;
    }
  }

  &.gold-color {
    background-color: $ay-main-gold-color;
  }

  &.large {
    padding: 0.25rem 0.5rem;
  }

  &.active {
    background-color: $ay-pure-white-color;
    color: $ui-color-900;
    font-size: 0.625rem;
    line-height: 0.75rem;
    padding: 0.25rem;
  }
}
