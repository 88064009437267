@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/shadows';

.action-button {
  align-items: center;
  background-color: $ui-color-white;
  border: 0.0625rem solid $ay-grey-16-color;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: $ui-color-600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.4rem;

  &:hover {
    box-shadow: $shadow-level-1;
    transition: 0.15s ease-in all;
  }
}
