@import 'src/assets/stylesheets/design-system/colors';

.loading {
  align-items: flex-start;
  display: flex;
  min-height: 10rem;
}

.title {
  color: $ay-grey-80-color;
  margin: 0 0 1rem 1rem;

  &.title-big {
    color: $ay-black-color;
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

.tags {
  color: $ay-grey-64-color;
  line-height: 1.5rem;
  margin: 1rem;
}

.amenities-list {
  margin-top: 0.5rem;
}
