.container {
  margin-top: 2rem;

  .header {
    position: relative;
  }

  .pagination {
    bottom: 0;
    z-index: 1;
  }
}
