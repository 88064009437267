.container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.item-container {
  align-items: center;
  display: flex;

  .text-input-container {
    margin-right: 0.5rem;
  }

  .remove-btn-container {
    margin-right: 1rem;
    opacity: 1;

    img {
      display: block;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}
