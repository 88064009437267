@import 'src/assets/stylesheets/design-system/colors';

.comment {
  color: $ay-grey-64-color;
  font-size: 0.875rem;
  line-height: 1.3rem;
  margin-bottom: 0;

  &.hide-margin {
    margin-top: 0.5rem;
  }

  span {
    font-weight: 600;
  }
}

.see-note-contanier {
  align-items: center;
  border: 1px solid $ay-grey-16-color;
  border-radius: 0.25rem;
  color: $ay-grey-64-color;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
  padding: 0.25rem;

  .icon {
    margin-right: 0.25rem;
  }
}
