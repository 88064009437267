@import '../../assets/stylesheets/design-system/colors';
@import '../../assets/stylesheets/zIndexes';

.container {
  bottom: 1.5rem;
  display: none;
  height: 5rem;
  left: 50%;
  position: fixed;
  transform: translate(-50%, 0);
  z-index: $notification-z-index;

  &.visible {
    display: flex;
  }
}

.content {
  background-color: $ay-white-color;
  box-shadow: 0 0.5rem 2rem rgba(1, 14, 40, 0.24);
  padding: 1rem 2.5rem;
  width: 58rem;
}

.title {
  color: $primary-color-900;
  line-height: 2rem;
}

.subtitle {
  color: $ay-grey-64-color;
  line-height: 2rem;
}

.action-container {
  align-items: center;
  display: flex;
}

.try-again {
  color: $primary-color-400;
  cursor: pointer;
  display: inline;
}

.close-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 1rem;
  justify-content: center;
  margin-left: 1.5rem;
  width: 1rem;
}

.three-dots {
  color: $primary-color-200;
  display: block;
  height: 1.5rem;
  width: 3rem;

  .dot {
    animation: threeDotsPulse 0.6s infinite ease-in-out;
    background-color: $primary-color-200;
    border-radius: 100%;
    box-sizing: border-box;
    display: inline-block;
    height: 0.375rem;
    margin: 0.25rem;
    position: relative;
    width: 0.375rem;

    &:nth-child(1) {
      animation-delay: -0.14s;
    }

    &:nth-child(2) {
      animation-delay: -0.07s;
    }

    &:nth-child(3) {
      animation-delay: 0s;
    }
  }
}

@keyframes threeDotsPulse {
  33% {
    transform: translateY(100%);
  }

  66% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}
