.labeled-data {
  display: flex;

  &.inline {
    display: inline-flex;
  }

  .label {
    margin-right: 0.3125rem;
    white-space: nowrap;
  }

  .data-wrapper {
    &.data-in-bold {
      font-weight: 600;
    }
  }
}
