@import 'src/assets/stylesheets/design-system/colors';

.actions {
  overflow-x: hidden;

  &-button {
    font-size: 0.875rem;
    font-weight: normal;
    padding: 0;
  }

  .separator {
    margin: 0 0.25rem;
  }

  &-row {
    display: flex;
  }
}
