@import '../../assets/stylesheets/design-system/colors';

.container {
  align-items: flex-end;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  &.label-required {
    justify-content: flex-start;
    padding-top: 0.25rem;
  }

  &.multiline {
    justify-content: flex-start;
    padding-top: 0.5rem;
  }
}

.hidden {
  display: none;
}

.label {
  color: $ay-grey-80-color;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: right;

  .truncate {
    background-image: linear-gradient(90deg, $ay-grey-80-color 85%, rgba(0, 0, 0, 0));
    max-width: none;
    white-space: normal;
  }
}

.label-wrapper {
  min-width: 10rem;
  padding: 0;

  &-disabled {
    opacity: 0.4;
  }
}

.required {
  color: $ay-main-red-color;
  font-size: 0.75rem;
  line-height: 1rem;
}
