@import 'src/assets/stylesheets/design-system/colors';

.wrapper {
  align-items: center;
  background-color: $primary-color-500;
  border-radius: 0.25rem;
  display: inline-flex;
  justify-content: center;
  padding: 0.125rem 0.25rem;
}

.text {
  color: $ui-color-white;
}
