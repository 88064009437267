@import 'src/assets/stylesheets/design-system/colors';

$field-height: 3rem;

.container {
  margin-bottom: 6rem;
}

.title {
  color: $ay-grey-80-color;
  font-size: 1.75rem;
  font-weight: 400;
  text-transform: capitalize;
}

.field-container {
  &:not(:first-child) {
    margin-top: 1rem;
  }
}

.field {
  height: $field-height;
}

.comp-set-name-field {
  box-sizing: border-box;
}

.footer-wrapper {
  z-index: 1;
}

.button-remove-property {
  background: $ay-white-color url('../../assets/images/icons/delete.svg') no-repeat center;
  background-size: 1rem;

  &:hover:not(:disabled) {
    background: $ay-main-red-color url('../../assets/images/icons/delete-white.svg') no-repeat center;
    background-size: 1rem;
  }
}
