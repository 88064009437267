@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/shadows';

.container {
  min-width: 3rem;
  position: relative;
  width: auto;

  .dropdown-button {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  .options-container {
    background: $ay-grey-8-color;
    border-radius: 0.25rem;
    box-shadow: $shadow-level-2;
    display: none;
    padding: 0.5rem;
    position: absolute;
    right: 0;
    top: 115%;
    z-index: $z-index-9;

    &.is-visible {
      display: block;
    }

    .options-item {
      align-items: center;
      border-radius: 0.25rem;
      color: $primary-color-400;
      cursor: pointer;
      display: flex;
      font-size: 1rem;
      padding: 0.8rem 0.5rem;
      white-space: nowrap;

      &:hover {
        background: $ay-white-color;
        color: $primary-color-500;
      }
    }

    .options-item-icon {
      margin-left: 6rem;
    }

    .icon-padded {
      margin-left: 6.6rem;
    }
  }
}
