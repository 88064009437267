@import 'src/assets/stylesheets/design-system/colors';

.list {
  list-style-type: none;
}

.details {
  display: flex;

  > dt {
    color: $ay-grey-80-color;
    line-height: 1.5rem;
    margin-right: 1rem;
    min-width: 11.5rem;
    text-align: right;
  }

  > dd {
    color: $ui-color-600;
    margin: 0;
    padding-right: 0.25rem;
  }
}
