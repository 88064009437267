@import 'src/assets/stylesheets/design-system/colors';

.container {
  color: $ay-grey-64-color;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 0;

  .col {
    flex-wrap: wrap;
    margin-right: 1rem;
  }
}

.margin-top-updated-user {
  margin-top: 1rem;
}

.user-name {
  display: inline-block;
}

.colored {
  color: $primary-color-500;
}

.comment {
  color: $ui-color-black;
  margin-right: 0.5rem;
}

@media only screen and (max-width: 75rem) {
  .updated-user {
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 75rem) {
  .container-flex {
    display: flex;

    .updated-user {
      padding-left: 1rem;
    }

    .border {
      border-left: 0.1rem solid $ay-grey-16-color;
    }
  }
}
