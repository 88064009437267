@import 'src/assets/stylesheets/design-system/colors';

.x-axis {
  color: transparent;
  margin-top: 1rem;

  text {
    fill: $ay-pure-white-color;
    font-size: 0.75rem;
    line-height: 1rem;
  }
}
