@import 'src/assets/stylesheets/vendors';
@import 'src/assets/stylesheets/css-library/main';
@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/fonts';

iframe {
  position: unset !important;
}

html {
  overflow-y: scroll;
}

html,
body {
  background: $ui-color-white;
  margin: 0;
  padding: 0;
}

// The entire website must use this font-family
* {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

tspan {
  font-family: 'Open Sans', sans-serif !important;
}

/* stylelint-disable selector-max-id, selector-max-compound-selectors */
#victory-chart > div > svg > g > g > text > tspan {
  font-size: 12px !important;
  margin-right: 1rem;
}
/* stylelint-enable selector-max-id, selector-max-compound-selectors */

// Note: some Simplebar styles can only be overridden using !important
.simplebar-horizontal {
  background-color: $ay-grey-8-color;
  height: 0.5rem !important;
  pointer-events: none;
}

.simplebar-scrollbar {
  pointer-events: auto;

  &::before {
    background-color: $ay-grey-24-color !important;
    opacity: 1 !important;
  }

  &:hover,
  &:focus,
  &:active {
    &::before {
      background-color: $ay-grey-40-color !important;
    }
  }
}

.ay-col-xs-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

// Toggle component styles
.custom-toggle.react-toggle {
  .react-toggle-track {
    background-color: $ay-grass-green-color;
  }

  &:not(.react-toggle--checked) .react-toggle-track {
    background-color: $ay-grey-80-color;
  }

  &:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: $ay-grey-80-color;
  }

  &.react-toggle--checked {
    .react-toggle-thumb {
      border-color: $ay-grass-green-color;
    }

    &:hover:not(.react-toggle--disabled) .react-toggle-track {
      background-color: $ay-grass-green-color;
    }
  }
}

.tooltip-wrapper {
  padding: 2px 4px !important;
  z-index: $notification-z-index !important;
}

[data-layout='badgeBlank'] {
  z-index: $z-index-10 !important;
}
