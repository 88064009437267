@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.container {
  @include input-border-style;

  background: $ui-color-white;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: $ay-grey-80-color;
  cursor: default;
  font-size: 1rem;
  height: 2.5rem;
  line-height: 1.5rem;
  padding: 0.5rem 1rem;
}

.separator {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
