@import './variables';
$images-path: '../../assets/images/';

@mixin eager-background-image($src, $hoverSrc) {
  background-image: url('#{$images-path}#{$src}');

  &::after {
    content: url('#{$images-path}#{$hoverSrc}');
    display: none;
  }

  &:hover,
  &:focus {
    background-image: url('#{$images-path}#{$hoverSrc}');
  }
}

@mixin input-border-style {
  border: 0.0625rem solid $ay-grey-16-color;
  border-radius: 0.25rem;

  &:focus {
    border-color: $primary-color-400;
  }

  &:required {
    border-color: $ay-error-color;
  }

  &:hover:not(:disabled),
  &:hover:not([disabled]),
  &:hover:not(:focus) {
    border-color: $primary-color-200;
  }
}

@mixin profiles-bottom-notification {
  bottom: calc(#{$property-profile-footer-height} + 2rem);
}

@mixin reset-button {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
}

@mixin dropdown-button {
  align-items: center;
  background-color: $ay-white-color;
  border: 0.0625rem solid $ay-white-color;
  border-radius: $ay-border-radius-sm;
  box-sizing: border-box;
  color: $primary-color-900;
  display: flex;
  font-size: 1rem;
  height: 2.5rem;
  justify-content: space-between;
  line-height: 1.5rem;
  outline: none;
  padding: 0.5rem;

  &:focus {
    &.border-ay-grass-green-color {
      border-color: $ay-grass-green-color;
    }

    &.border-primary-color-200 {
      border-color: $primary-color-200;
    }

    &.border-supportive-color-500 {
      border-color: $supportive-color-500;
    }
  }

  &:not(.with-value-selected) {
    border: 0.0625rem solid $ui-color-300;
  }

  &:not(:disabled):hover {
    cursor: pointer;
  }

  &.active {
    border-color: $ay-grey-24-color;
  }

  &.disabled {
    color: $ay-grey-40-color;
  }

  &.with-value-selected {
    &.border-ay-grass-green-color {
      border-color: $ay-grass-green-color;
    }

    &.border-primary-color-200 {
      border-color: $primary-color-200;
    }

    &.border-supportive-color-500 {
      border-color: $supportive-color-500;
    }
  }

  &.full-width {
    width: 100%;
  }

  .icon {
    height: 0.75rem;
    padding-left: 1rem;
  }
}

@mixin reset-button {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
}

@mixin text-truncate($width, $text-align: null, $display: null) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: #{$width};

  @if $display != null {
    text-align: $display;
  }

  @else {
    display: block;
  }

  @if $text-align != null {
    text-align: $text-align;
  }

  @else {
    text-align: start;
  }
}

@mixin card-separator {
  border-top: 0.0625rem solid $ay-grey-16-color;
  width: 100%;
}

@mixin map-marker($border-color) {
  background: $supportive-color-500;
  border: 0.0625rem solid $border-color;
  border-radius: 50% 50% 50% 0;
  content: '';
  display: inline-block;
  height: 1.875rem;
  transform: rotate(-45deg);
  width: 1.875rem;
}

@mixin dropdown {
  --input-color: #{$primary-color-500};
  --list-opened-background-color: #{$primary-color-500};
  --list-opened-color: #{$ay-white-color};

  align-items: center;
  background-color: $primary-color-100;
  display: flex;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  height: 2rem;
  justify-content: flex-start;
  line-height: 150%;
  padding-left: 0.625rem;
  width: 12.5rem;

  &:hover {
    cursor: pointer;
  }
}
