@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

.footer-container {
  margin-top: auto;
  padding-top: 0.75rem;

  &.card-separator {
    @include card-separator;
  }
}

.tim-data {
  align-items: center;
  display: flex;
  line-height: 1.5rem;

  &.extra-small {
    flex-wrap: wrap;
    padding-bottom: 0.75rem;
  }

  .content-footer-tag {
    width: 5.5rem;
  }

  .content-footer-text {
    width: calc(100% - 5.5rem);
  }
}

.tim-comments {
  color: $ay-grey-64-color;
  font-size: 0.875rem;
  line-height: 1.3rem;
  margin-bottom: 1rem;
  max-height: 6.25rem;
  overflow: auto;

  span {
    font-weight: 600;
  }
}

.footer-tag {
  background-color: $secondary-color-100;
  border-radius: 0.25rem;
  color: $primary-color-900;
  display: inline;
  font-weight: 600;
  margin: 0;
  padding: 0.25rem;

  &.in-market {
    background-color: $supportive-color-500;
    color: $ui-color-white;
  }

  &.extra-small {
    flex-grow: 1;
    text-align: center;
  }
}

.footer-text {
  color: $ay-grey-64-color;
  display: inline;
  font-size: 0.875rem;
  margin: 0 0 0 0.25rem;

  &.extra-small {
    flex-grow: 1;
    margin: 0;
    text-align: center;
  }
}
