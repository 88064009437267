@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/shadows';

.modal-container {
  height: auto;
  width: 30rem;
}

.container {
  padding: 0;

  .header {
    color: $ay-black-color;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  .information {
    color: $ay-grey-80-color;
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }

  .ok-button {
    padding: 0.5rem 1rem;
  }
}
