@import 'src/assets/stylesheets/design-system/colors';

.recent-leases-graph-container {
  .chart {
    height: auto;

    .chart-container {
      height: auto;
      margin: 0;
    }
  }
}

.container {
  cursor: default;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  position: relative;
  width: 100%;

  header {
    margin-bottom: 0;
  }
}

.title {
  color: $ay-pure-white-color;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-top: 1.5rem;
}

.no-leases-legend {
  color: $ay-grey-24-color;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: -1rem;
}

.svg-graph {
  overflow: visible;
}

.x-axis {
  color: transparent;
  margin-top: 1rem;

  text {
    fill: $ay-pure-white-color;
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

.graph-y-legend-top {
  color: $ay-pure-white-color;
  font-size: 0.75rem;
  margin: 0 0 0 1rem;
  position: absolute;
  top: 5rem;
}

.graph-y-legend-bottom {
  bottom: 2rem;
  color: $ay-pure-white-color;
  font-size: 0.75rem;
  margin: 0 0 0 1rem;
  position: absolute;
}

.recent-leases-graph-row {
  margin-bottom: 2rem;
}
