@import '../../../../assets/stylesheets/design-system/colors';

.action-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.title {
  color: $primary-color-500;
  font-size: 1.75rem;
}

.delete-button {
  background: transparent;
  border: 0;
  box-sizing: border-box;
  color: $ay-main-red-color;
  cursor: pointer;
  display: flex;
  font-size: 1.125rem;
  line-height: 1.5rem;
  outline: none;
}

.delete-icon {
  padding-left: 0.625rem;
}
