@import 'src/assets/stylesheets/design-system/colors';

.card-link {
  border: 0.0625rem solid $ay-grey-16-color;
  border-radius: 0.25rem;
  display: flex;
  gap: 1rem;
  padding: 0.5rem;

  .image {
    border-radius: 0.25rem;
    height: 4rem;
    width: 4rem;
  }
}

.title {
  color: $ay-black-color;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 0;

  &:hover {
    color: $primary-color-400;
  }
}

.subtitle {
  color: $ay-grey-80-color;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.2rem;
  margin: 0;
}

.tertiary-title {
  color: $ay-grey-40-color;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.2rem;
  margin: 0;
}
