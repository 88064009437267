@import 'src/assets/stylesheets/design-system/colors';

.legend-container {
  align-items: center;
  display: flex;

  .legend-item {
    display: inline-block;
    margin-right: 0.5rem;
  }

  .legend-ball {
    border-radius: 50%;
    height: 0.5rem;
    width: 0.5rem;
  }

  .legend-line {
    border-radius: 0.25rem;
    height: 0.25rem;
    margin-bottom: 0.125rem;
    width: 1rem;
  }

  .legend-text {
    color: $ui-color-white;
    font-size: 0.75rem;
    margin-right: 1.5rem;
  }
}
