@import 'src/assets/stylesheets/variables';
@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';

.content-chart {
  position: relative;
}

.content-switch {
  margin-top: 1.25rem;
  position: absolute;
  right: 2rem;
  z-index: $z-index-1;
}

.types-switch {
  align-items: center;
  border: solid 0.05rem $ui-color-400;
  height: $switch-toggle-buttons-small-size;
  margin: 0.975rem 1rem 1rem 0;
  min-height: $switch-toggle-buttons-small-size;

  .switch-button {
    background-color: transparent;
    color: $ui-color-400;
    font-size: 0.875rem;
    font-weight: 600;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 6rem;
  }
}

.container {
  position: relative;
}

.container h3 + span {
  position: absolute;
  top: 4.125rem;
}
