@import 'src/assets/stylesheets/design-system/colors';

.breadcrumb {
  align-items: center;
  color: $ay-grey-40-color;
  display: flex;
  font-size: 1.5rem;
  line-height: 1;
  overflow: hidden;
  white-space: nowrap;

  &.content-faded {
    position: relative;

    &::after {
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 5%;
    }
  }
}

.content-wrapper {
  align-items: center;
  display: flex;
  flex: 1;
}

.level-wrapper {
  align-items: center;
  display: inline-flex;

  &.current-level {
    color: $ay-grey-80-color;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      color: $primary-color-200;
    }
  }
}

.separator {
  margin: 0 0.5rem;
  overflow: visible;
}
