@import 'src/assets/stylesheets/design-system/colors';

.cell-property {
  font-weight: 600;
  min-width: 14rem;
}

.cell-price {
  background-color: $ay-frozen-grass-color;
  border-radius: 0.25rem;
  display: inline-block;
  line-height: 1.5rem;
  padding: 0.25rem 0.5rem;
}

.cell-link {
  color: $ay-grey-80-color;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $primary-color-400;
  }
}

.cell-inline {
  display: inline;
}

.partial-inv-volume {
  margin: 0;
  min-width: 9rem;
}
