.second-column {
  align-self: center;
  display: flex;
  justify-content: flex-end;

  &.full-width {
    align-items: center;
    justify-content: space-between;
  }

  &.content-to-right {
    align-items: center;
    justify-content: flex-end;
    min-height: 5.5rem;
  }
}

.title {
  align-items: center;
  display: flex;
}
