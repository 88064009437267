@import 'src/assets/stylesheets/design-system/colors';

.radio-group {
  display: flex;

  .radio-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 1.5rem;
    position: relative;
    user-select: none;

    &-underline {
      width: 100%;

      &:not(:first-child) {
        margin-top: 0.56rem;
      }

      &:not(:last-of-type)::after {
        border-top: 0.015625rem solid $ay-grey-16-color;
        bottom: -0.5rem;
        content: '';
        position: absolute;
        width: 100%;
      }
    }
  }
}

.radio-group-row-layout {
  align-items: center;

  .radio-item {
    margin-left: 1.5rem;
  }
}

.radio-group-disabled {
  opacity: 0.5;
}

.radio-group-column-layout {
  align-items: flex-start;
  flex-direction: column;

  .radio-item {
    margin-bottom: 0.5rem;
  }
}

.radio {
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checked {
  background: transparent;
  border: 0.125rem $ay-grey-16-color solid;
  border-radius: 50%;
  height: 1rem;
  left: 0;
  position: absolute;
  top: 0.09375rem;
  width: 1rem;

  &-visible {
    border: 0.125rem $ay-grass-green-color solid;
  }

  &-parent {
    border-color: $ay-grey-40-color;
  }

  &-background-ay-white-color {
    background-color: $ay-white-color;
  }

  &-multi {
    border-radius: 0.25rem;
    height: 1.5rem;
    width: 1.5rem;
  }
}

.checkmark {
  background-color: $ay-grass-green-color;
  border: 0.0625rem $ay-grass-green-color solid;
  border-radius: 50%;
  content: '';
  display: none;
  height: 0.5rem;
  left: 0.3125rem;
  position: absolute;
  top: 0.40625rem;
  width: 0.5rem;

  &-visible {
    display: block;
  }

  &-parent {
    background-color: $ay-grey-40-color;
    border: 0.0625rem $ay-grey-40-color solid;
  }

  &-multi {
    border-radius: 0;
    height: 1.5rem;
    left: 0.07rem;
    top: 0.2rem;
    width: 1.5rem;

    &::after {
      background: url('../../../assets/images/icons/check.svg') no-repeat center;
      content: '';
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

.radio-item:hover .radio:not(:disabled) ~ .checked {
  border: 0.125rem $ay-grey-40-color solid;
}

.label {
  color: $ay-grey-64-color;
  padding-left: 2rem;
  top: 0.09375rem;

  &:hover {
    color: $ay-grey-80-color;
  }

  &-checked {
    color: $primary-color-900;
  }

  &-multi {
    margin-left: 0.25rem;
    margin-top: 0.25rem;
    position: relative;
  }
}
