@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';

:global {
  .slider-control-bottomcenter {
    pointer-events: none;
  }
}

.slide-control-container {
  align-items: center;
  color: $ui-color-white;
  display: flex;
  justify-content: space-between;
  position: absolute;
  // Unfortunately, the use of !important is necessary here because the library is applying these
  // rules as inline styles.
  top: -0.5rem !important;
  transform: translate(-50%, -100%) !important;
  width: 19rem;
}

.modal-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.title-container {
  align-items: center;
  color: $ui-color-white;
  display: flex;
  flex-flow: column;
  margin-bottom: 2.5rem;
  width: 100%;

  .title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2.5rem;
    margin: 0;
  }

  .subtitle {
    color: $ui-color-400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
  }
}

.slide-controller-container {
  margin-bottom: 1rem;
  text-align: center;
  z-index: 1;

  .slide-controller {
    align-items: center;
    background-color: $ay-black-color;
    border-radius: 0.25rem;
    color: $ay-white-color;
    display: flex;
    font-size: 1rem;
    height: 2.5rem;
    justify-content: space-between;
    line-height: 2rem;
    margin: auto;
    text-align: center;
    width: 19rem;

    &.label-centered {
      justify-content: center;
    }
  }
}

.arrow-left {
  cursor: pointer;
  float: left;
  margin-left: 0.75rem;
  width: 1rem;

  &:hover {
    content: url('../../../assets/images/icons/arrow-left-active.svg');
  }
}

.arrow-right {
  cursor: pointer;
  float: right;
  margin-right: 0.75rem;
  width: 1rem;

  &:hover {
    content: url('../../../assets/images/icons/arrow-right-active.svg');
  }
}

.slide-images-container {
  * {
    outline: none !important;
  }
}

.download-container {
  margin-top: 0.5rem;
  padding-right: 0;
}

.set-primary-btn-container {
  display: inline-block;

  .set-primary-btn {
    background-color: transparent;
    color: $ay-white-color;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

.cover-label {
  color: $ay-grass-green-color;
  cursor: default;
  font-size: 1.25rem;
  line-height: 2rem;
  margin: 0;
  padding: 0.25rem 0;
}

.download-icon {
  @include eager-background-image(
    'icons/download-white.svg',
    'icons/download.svg'
  );

  background-position: top 50% right 50%;
  background-repeat: no-repeat;
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 2rem;
  width: 2rem;

  &:hover {
    background-color: $primary-color-100;
  }
}

.media-carousel-wrapper {
  background-color: $ay-black-color;
  height: 32.875rem;
  text-align: center;

  .media-item {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    text-align: center;
    width: auto;
  }
}

.gallery-note {
  color: $ay-pure-white-color;
  margin: 1.25rem 0.5rem 0;
}
