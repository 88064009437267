$view-width-tablet-min: 48rem;
$view-width-small-desktop-min: 60rem;
$view-width-desktop-min: 75rem;

$container-width-mobile: 100%;
$container-width-tablet: 46rem;
$container-width-small-desktop: 62rem;
$container-width-medium-desktop: 70rem;

$columns-count-mobile: 4;
$columns-count-tablet: 8;
$columns-count-small-desktop: 12;
$columns-count-medium-desktop: 12;
$columns-count-large-desktop: 12;

$gutter-width-mobile: 1.5rem;
$gutter-width-tablet: 2rem;
$gutter-width-small-desktop: 2rem;
$gutter-width-desktop: 2rem;

@mixin create-container($container-width, $gutter) {
  .ay-container {
    margin: 0 auto;
    max-width: $container-width;
    padding-left: $gutter / 2;
    padding-right: $gutter / 2;
  }
}

@mixin generate-grid($container-width, $columns-count, $gutter, $class-name) {
  @include create-container($container-width, $gutter);

  .ay-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter / 2;
    margin-right: -$gutter / 2;
  }

  @for $current-column from 1 to $columns-count + 1 {
    $col-number: $current-column;
    $col-percentage: percentage($col-number / $columns-count);
    $side-gutter: $gutter / 2;

    .ay-col-#{$class-name}-#{$col-number} {
      box-sizing: border-box;
      flex: 0 0 $col-percentage;
      max-width: $col-percentage;
      padding-left: $side-gutter;
      padding-right: $side-gutter;
      width: $col-percentage;
    }
  }
}

@include generate-grid($container-width-mobile, $columns-count-mobile, $gutter-width-mobile, 'xs');

@media only screen and (min-width: $view-width-tablet-min) {
  @include generate-grid($container-width-tablet, $columns-count-tablet, $gutter-width-tablet, 'sm');
}

@media only screen and (min-width: $view-width-small-desktop-min) {
  @include generate-grid($container-width-small-desktop, $columns-count-small-desktop, $gutter-width-small-desktop, 'md');
}

@media only screen and (min-width: $view-width-desktop-min) {
  @include generate-grid($container-width-medium-desktop, $columns-count-medium-desktop, $gutter-width-desktop, 'lg');
}
