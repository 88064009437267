@import 'src/assets/stylesheets/design-system/colors';

.container {
  display: flex;
  width: 100%;
}

.property-info {
  align-items: center;
  color: $ay-grey-64-color;
  display: flex;
  font-size: 1rem;
  margin: 0;
}

.carousel-title-container {
  display: flex;
}

.property-title {
  margin-bottom: 0.25rem;
}

.property-info-separator {
  margin: 0 0.25rem;
}

.header-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  &.in-carousel {
    flex-direction: column;
  }

  .header-link {
    color: $ay-grey-64-color;
    font-size: 1rem;
  }

  .property-address {
    color: $ay-black-color;
    display: block;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding: 0;
    text-decoration: none;

    &-clickable:hover {
      color: $primary-color-400;
      cursor: pointer;
    }
  }

  .tag {
    align-items: center;
    color: $ay-grey-64-color;
    display: inline-flex;
    font-size: 0.875rem;
    margin: 0;
  }

  .share {
    background-color: $ui-color-200;
  }

  .container-category {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}

.container-in-carousel {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  word-break: break-all;

  .property-info {
    flex-wrap: wrap;
    font-size: 0.875rem;

    .header-link {
      font-size: inherit;
    }
  }
}

.activity-property-info {
  width: 100%;
}
