@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/shadows';

$submit-btn-container-height: 5rem;

.container {
  background-color: $ay-grey-8-color;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  margin-right: 0;
  padding: 0.75rem;

  &.is-last {
    margin-bottom: $submit-btn-container-height;
  }

  .min-max-inputs-container {
    display: inline-flex;
    width: auto;

    .min-max-input {
      width: 7rem;
    }
  }
}

.form-row {
  padding: 0.75rem;

  .form-choice-input {
    margin-bottom: 0;
  }

  &-start {
    align-items: flex-start;
  }

  .date-input-error {
    // I'm using `!important` because sometimes the DateInput.module.scss file is injected after this file,
    // making the rules take precedence over the ones defined here.
    border-color: $ay-main-red-color !important;
  }

  .form-margin-bottom-control {
    margin-bottom: 0;
  }

  .slab-field-container {
    display: flex;
  }
}

.form-address-button-container {
  display: flex;
}

.form-address-button {
  display: none;
  padding-top: 5px;
  width: 22%;
}

.flexible-address-input {
  float: left;
  width: 100%;
}

.hidden-container {
  display: none;
}

.escalation-frequency-list {
  padding: 0.75rem;
}

.secondary-row {
  margin-top: -0.5rem;
  padding: 0 0.75rem 0.75rem;
}

.dollar-input {
  background: $ay-white-color url('../../../assets/images/icons/currency-dollar.svg') no-repeat 0.5rem 50%;
  text-align: center;
}

.with-padding {
  padding-right: 1.25rem;
}

.percentage-input {
  background: $ay-white-color url('../../../assets/images/icons/percentage.svg') no-repeat 7.2rem 50%;
}

.large-input-container {
  align-items: center;
  display: flex;

  &-gap {
    gap: 2rem;
  }
}

.input-helper {
  align-self: center;
  color: $ay-grey-64-color;
  padding: 0 1rem;

  &.with-choice-options {
    padding-right: 0;
  }

  &.disabled {
    opacity: 0.4;
  }
}

.wrapper-radio-container {
  align-items: center;
  display: flex;
  width: fit-content;

  .button-input-item {
    margin-bottom: 0;
  }
}

.radio-input-container {
  min-height: 2.5rem;

  &.auto-width {
    width: auto;
  }
}

.radio-input-container-multiline {
  min-height: 2.5rem;
}

.comment-area {
  background-color: $ui-color-white;
  border: 0.0625rem solid $ay-grey-16-color;
  border-collapse: collapse;
  border-radius: 0.25rem;
  font-family: inherit;
  font-size: 1rem;
  min-height: 5rem;
  outline: none;
  padding: 1rem;
  resize: none;
  width: 100%;

  &:focus {
    border-color: $primary-color-400;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.4;
  }
}

.broker-helpers {
  flex-wrap: wrap;
  margin-left: -1.5rem;

  // This targets the elements directly inside this container class .broker-helpers
  // passed through as wrapperClassName. We should ideally use wrapperClassName
  // to target the child components, but unfortunately in this case I needed
  // to reach a deeper target.
  > * {
    margin-top: 1rem;
  }
}

.submit-btn-container {
  background-color: $ay-white-color;
  bottom: 0;
  height: $submit-btn-container-height;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: $z-index-2;
}

.cancel-btn,
.submit-btn {
  margin-top: 1rem;
  width: 10rem;
}

.submit-btn {
  margin-left: 1rem;
}

.footer {
  box-shadow: $shadow-top-2;
}

.edit-space-title {
  color: $primary-color-500;
  font-size: 1.75rem;
}

.currency-input {
  > div {
    display: flex;

    &:first-child > div:first-child {
      width: 100%;
    }
  }
}

.twin-fields {
  align-items: center;
  display: flex;
  gap: 1rem;

  .twin-label {
    margin-right: 0.5rem;
  }

  .twin-helper {
    color: $ui-color-600;
  }
}

.form-input-field-small {
  width: 3rem;
}

.checkbox-label {
  color: $ay-grey-64-color;
  width: max-content;
}

.subtitle {
  color: #383c42;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 1.1rem;
  padding-left: 4rem;
  text-align: right;
}

.subtitle-longer {
  color: #383c42;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 1.1rem;
  padding-left: 2.2rem;
  text-align: right;
}

.subtitle-longer-extended {
  color: #383c42;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 1.1rem;
  padding-left: 3.3rem;
  text-align: right;
}

.source-label {
  margin-right: 1rem;
  padding-left: 8rem;
  white-space: pre;
}

.source-input {
  box-sizing: border-box;
  height: 2.5rem;
}

.size-spaced {
  padding-left: 1rem;
}

.multiple-dates {
  display: flex;
}

.wrapper-size-container {
  display: flex;
  padding-left: 1.5rem;

  .lease-size-column-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  .lease-size-column {
    display: flex;
    flex: 0;
    flex-direction: row;

    label {
      width: max-content;
    }
  }
}

.collar-cap-container {
  display: flex;
  gap: 2.5rem;
  padding-left: 2rem;
}

.escalation-cap-container {
  display: flex;
}

.collar-cap-wrapper {
  flex-wrap: nowrap;
}

.collar-cap-label {
  min-width: fit-content;
}

.escalation-checkboxes {
  display: flex;
  gap: 2rem;
  padding: 0 0.75rem 0.75rem 12.25rem;
}

.error-message {
  color: $ay-error-color;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0;
  margin-top: 0.25rem;
  padding: 0;
}

.form-row-extended {
  height: 3rem;
}

.error-message-label {
  margin-bottom: 1rem;
}
