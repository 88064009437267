@import '../../assets/stylesheets/design-system/colors';

@mixin font-style {
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
  text-align: center;
}

.table {
  border: 0.06rem solid $ay-grey-16-color;
  border-collapse: collapse;
  border-radius: 0.25rem;
  box-sizing: border-box;
  width: 100%;

  .header-row {
    @include font-style;

    background-color: $ui-color-white;
    border-bottom: 0.0625rem solid $ui-color-300;
    border-right: 0.0625rem solid $ui-color-300;
    color: $ui-color-600;
    font-size: 0.75rem;
    padding: 0.5rem;
  }

  .row-cell {
    @include font-style;

    background-color: $ui-color-white;
    border-bottom: 0.0625rem solid $ui-color-300;
    border-right: 0.0625rem solid $ui-color-300;
    box-sizing: border-box;
    color: $ui-color-900;
    min-width: 6rem;
    padding: 0.5rem;
    white-space: nowrap;
  }

  .extra-row {
    pointer-events: none;
  }
}
