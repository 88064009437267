@import 'src/assets/stylesheets/design-system/colors';

.container {
  padding: 2rem;
  width: 47rem; // It allows industries to show in 3 columns as per design

  .header {
    color: $ay-black-color;

    .title {
      font-size: 1.5rem;
      font-weight: 400;
      margin: 0;
    }

    .subtitle {
      display: block;
      font-size: 1rem;
      margin: 0.75rem 0;
    }
  }

  .delete-button {
    background: transparent;
    color: $ay-main-red-color;
    float: right;
    margin-right: 1rem;

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      background: $ay-main-red-color;
      color: $ay-pure-white-color;

      path {
        stroke: $ay-pure-white-color;
      }
    }
  }

  .header-buttons {
    display: flex;
  }

  .merge-button {
    background: transparent;
    color: $ui-color-600;
    margin-right: 1.5rem;

    &:hover,
    &:focus {
      background-color: $ui-color-600;
      color: $ay-white-color;

      path {
        stroke: $ay-white-color;
      }
    }

    &:active {
      background-color: $ui-color-black;
      color: $ay-white-color;

      path {
        stroke: $ay-white-color;
      }
    }
  }

  .footer {
    margin-top: 2rem;

    button {
      font-size: 1.125rem;
      width: 100%;
    }

    .cancel-button {
      color: $ay-grey-64-color;
    }
  }
}

.header-container {
  color: $primary-color-500;
  display: flex;
  font-size: 1.75rem;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.company-form-container {
  margin-bottom: 4rem;
}

.create-update {
  background-color: $ay-grey-8-color;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  margin-right: 0;
  padding: 0.75rem;
}
