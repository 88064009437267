.properties {
  display: grid;
  position: relative;
}

.comparative-container {
  display: grid;
  grid-column-gap: 1.125rem;
  grid-template-columns: repeat(5, 1fr);
}
