@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/mixins';
@import 'src/assets/stylesheets/shadows';
@import 'src/assets/stylesheets/variables';
@import 'src/assets/stylesheets/zIndexes';

.card {
  background-color: $ay-white-color;
  border: 0.0625rem solid $ay-grey-16-color;
  border-radius: $ay-border-radius;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0;
  position: relative;

  &:hover {
    box-shadow: $shadow-level-1;
  }

  .hover {
    background-color: $secondary-color-500;
  }
}

.header-wrapper {
  display: inline-block;
  padding-right: 2.5rem;
}

.number-wrapper {
  align-items: center;
  background-color: $supportive-color-500;
  border-radius: $ay-border-radius-sm;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-right: 1rem;
  min-width: 2rem;

  .number {
    color: $ui-color-white;
  }
}

.header-with-property {
  justify-content: space-between;
  margin-bottom: 1rem;
  min-height: 2.5rem;
  padding-bottom: 0.5rem;
  position: relative;

  &::before {
    background: $ay-grey-16-color;
    bottom: 0;
    content: '';
    height: 0.0625rem;
    left: 0;
    position: absolute;
    right: -2.5rem;
  }

  .header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .content-thumb {
    height: 8rem;
    margin-right: 1rem;
    position: relative;
    width: 10.62rem;
  }

  .comp-set-button {
    border-radius: 0 0 0.25rem 0.25rem;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 10.62rem;
    z-index: $z-index-1;

    &:hover,
    &:focus {
      path {
        stroke: $ui-color-white;
      }
    }
  }

  .image {
    border-radius: $ay-border-radius;
    height: 8rem;
    width: 10.62rem;
  }

  .content-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 8rem;
  }

  .title {
    color: $ay-black-color;
    cursor: pointer;
    display: flex;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875;
    margin: 0;
    padding-bottom: 0.25rem;
    text-decoration: none;

    &:hover {
      color: $primary-color-400;
    }
  }

  .title-icon {
    margin-top: 0.06rem;
    width: 1rem;
  }

  .subtitle {
    color: $ay-grey-64-color;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
