$z-index-1: 1;
$z-index-2: 2;
$z-index-3: 3;
$z-index-9: 9;
$z-index-10: 10;
$z-index-11: 11;
$z-index-100: 100;
$fixed-table-headings-z-index: 99;
$fixed-tabs-z-index: 999;
$navbar-z-index: 9999;
$modal-overlay-z-index: 10000;
$modal-z-index: 10001;
$maps-places-autocomplete: 99998;
$notification-z-index: 99999;
$subnavbar-z-index: 9998;
