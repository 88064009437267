@import 'src/assets/stylesheets/design-system/colors';

.chip {
  background-color: $ay-grey-8-color;
  border-radius: 0.25rem;
  color: $ui-color-800;
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.25rem 0.5rem;
}
