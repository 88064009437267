@import 'src/assets/stylesheets/design-system/colors';

.label {
  color: $ay-grey-64-color;
  margin-right: 0.5rem;
}

.input {
  margin-right: 0.5rem;
}

.units {
  color: $ay-grey-64-color;
}

.label,
.units {
  &.disabled {
    opacity: 0.4;
  }

  &.from-label-disabled {
    margin-left: -2.5rem;
  }
}
