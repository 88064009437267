
.lease-layout {
  align-items: center;
  background: #f4f4f5;
  border: 1px solid #d8dade;
  border-radius: 8px;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 19rem;
  padding: 1rem;
  word-break: keep-all;
}

.sale-layout {
  align-items: center;
  background: #f4f4f5;
  border: 1px solid #d8dade;
  border-radius: 8px;
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 19rem;
  padding: 1rem;
  word-break: keep-all;
}

.asking-rent-layout {
  align-items: center;
  background: #f4f4f5;
  border: 1px solid #d8dade;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin: 0 auto;
  margin: 0.75rem;
  min-height: 13.75rem;
  position: relative;
  word-break: keep-all;
}

.availability-layout {
  align-items: center;
  background: #f4f4f5;
  border: 1px solid #d8dade;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin: 0 auto;
  margin: 0.75rem;
  min-height: 10.5rem;
  position: relative;
  word-break: keep-all;
}

.tenant-information-layout {
  align-items: center;
  background: #f4f4f5;
  border: 1px solid #d8dade;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  word-break: keep-all;
}

.text {
  color: #777886;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding: 1rem;
  text-align: center;
  width: auto;
}

.link-text {
  color: #55489e;
  cursor: pointer;
  font-weight: 600;
}
