@import 'src/assets/stylesheets/navbar';
@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';

.empty-activities-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 8rem;
}

.feed-content {
  min-height: 45rem;
}

.tags-filter-container {
  display: flex;
  justify-content: flex-end;
}

.tags-filter {
  position: absolute;
  z-index: $z-index-2;
}

.is-sticked {
  position: fixed;
  top: $navbar-height + 0.25rem;
}
