@import 'src/assets/stylesheets/design-system/colors';

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.image {
  display: block;
  margin-bottom: 2rem;
  width: 2.875rem;
}

.title {
  color: $ay-grey-40-color;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.5rem;
  margin: 0 0 0.5rem;
  padding: 0;
}
