.items-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1.5rem;
}

.drag-overlay {
  transform-origin: 0 0;
}

.item {
  align-items: center;
  cursor: grab;
  display: flex;
  justify-content: center;
  opacity: 1;
  position: relative;
  transform-origin: 50% 50%;

  > div {
    width: 100%;
  }
}

.item-grabbing {
  cursor: grabbing;
  opacity: 0;
}
