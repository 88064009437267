@import '../../assets/stylesheets/design-system/colors';
@import '../../assets/stylesheets/mixins';

.container {
  padding-bottom: 5rem;
}

.container-share {
  position: relative;
}

.title {
  color: $ay-grey-80-color;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.25rem;
  margin: 1.5rem 0 0.5rem;
}

.content-card {
  background: $ay-grey-8-color;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  padding: 1rem;

  .card-subtitle {
    color: $primary-color-900;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    margin: 0 0 0.5rem;
  }

  .small-text {
    color: $ay-grey-64-color;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1rem;
    margin: 0 0 0.5rem;
  }

  .medium-text {
    color: $ay-grey-80-color;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.5rem;
    margin: 0 0 0.5rem;

    .detail-title {
      color: $ay-grey-64-color;
    }
  }

  .black-text {
    color: $ay-grey-80-color;
  }

  .comment-label {
    color: $ay-grey-64-color;
    display: block;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .comment-area {
    @include input-border-style;

    box-sizing: border-box;
    color: $ay-black-color;
    display: block;
    font-size: 1rem;
    line-height: 1.5rem;
    min-height: 7.5rem;
    padding: 0.5rem;
    width: 100%;
  }
}

.submit-btn-container {
  background-color: $ay-white-color;
  bottom: 0;
  height: 5rem;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;

  .cancel-btn,
  .submit-btn {
    margin-top: 1rem;
    width: 10rem;
  }

  .submit-btn {
    margin-left: 1rem;
  }
}

.lease-card {
  background-color: $ay-grey-8-color;
  border: 0;
  margin: 0;
  padding: 0;

  &:hover {
    border: 0;
    box-shadow: none;
    transition: none;
  }
}
