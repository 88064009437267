@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/shadows';

.container {
  background: $ay-grey-8-color;
  border-radius: 0.25rem;
  box-shadow: $shadow-level-2;
  height: auto;
  left: 0;
  max-height: 30rem;
  position: absolute;
  top: 2.75rem;
  width: 20rem;
  z-index: $z-index-100;

  &.full-width {
    width: fit-content;
  }
}

.search-input-container {
  border-bottom: 0.0625rem solid $ay-grey-16-color;
  padding: 0.5rem 3.25rem 0.5rem 0.5rem;

  .search-input {
    background:
      $ay-white-color
      url('../../../assets/images/icons/search-inactive.svg') no-repeat 0.75rem
      50%;
    padding-left: 2.5rem;
    width: 100%;
  }
}

.options-container {
  max-height: 25.5rem;
  overflow-y: auto;
  padding: 0 0.5rem 0.5rem;
}

.option-items-container {
  height: auto;
  overflow: hidden;
  padding-top: 0.5rem;
}

.multi-select-items {
  max-height: 26.5rem;

  .option-items-container {
    height: auto;
    max-height: initial;
  }
}

.chips-container {
  background: $ay-grey-8-color;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top: 0.0625rem solid $ay-grey-16-color;
  bottom: 0;
  box-shadow: 0 0.5rem 1rem rgba(1, 14, 40, 0.24);
  left: 0;
  padding: 0.5rem 0.5rem 0;
  position: absolute;
  width: 19rem;
}

.choice-item {
  width: 100%;
}

.empty-message {
  color: $ay-grey-64-color;
  font-size: 1rem;
  margin: 0;
  padding: 0.5rem;
}

.action-button-container {
  padding: 0.5rem;
}
