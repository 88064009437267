@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/shadows';

.confirmation-modal-footer {
  background-color: $ay-white-color;
  bottom: 0;
  box-shadow: $shadow-top-2;
  height: 5rem;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;

  .button-container {
    display: flex;
    justify-content: center;

    &.with-delete {
      justify-content: space-between;
    }

    .button {
      margin-top: 1rem;
      width: 10rem;

      &:last-child {
        margin-left: 1rem;
      }
    }

    .delete-button {
      width: auto;

      &:not(:hover) {
        background-color: $ay-grey-8-color;
      }
    }
  }
}
