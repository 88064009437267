@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';

@mixin option-button {
  height: 2.6rem;
  width: 3.5rem;

  &.selected {
    border: 0.125rem solid $supportive-color-500;
    border-radius: 0.25rem;
    box-sizing: border-box;
  }
}

.container {
  background-color: $ay-white-color;
  border-radius: 0.25rem;
  height: 8.25rem;
  position: absolute;
  right: 1rem;
  top: 5.62rem;
  width: 11.25rem;
  z-index: $z-index-1;

  .title {
    color: $ui-color-black;
    font-size: 0.75rem;
    font-weight: 400;
    padding: 1rem 1.5rem 0;
  }

  .close-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: absolute;
    right: 1rem;
    top: 1.25rem;
  }

  .options {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
  }

  .button {
    height: 4rem;
    text-align: center;
    width: 3.6rem;

    .label {
      color: $ui-color-600;
      font-size: 0.75rem;
      font-weight: 400;
      margin-top: 0.25rem;

      &.selected {
        color: $supportive-color-500;
        font-weight: 600;
      }
    }
  }

  .default-option {
    @include option-button;

    background-image: url('../../../assets/images/pins-button.png');
  }

  .heatmap-option {
    @include option-button;

    background-image: url('../../../assets/images/heatmap-button.png');
  }
}
