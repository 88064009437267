@import 'src/assets/stylesheets/design-system/colors';

.chips-container {
  display: flex;
  flex-wrap: wrap;
}

.chip {
  background-color: $ay-white-color;
  border-radius: 0.25rem;
  color: $ay-black-color;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.25rem 0.5rem;
}

.button-remove {
  margin-left: 0.5rem;
  opacity: 1;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
