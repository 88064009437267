@import 'src/assets/stylesheets/design-system/colors';
@import 'src/assets/stylesheets/zIndexes';
@import 'src/assets/stylesheets/variables';

.container {
  border: 1px solid $ay-grey-16-color;
  border-radius: 0.25rem;
  box-shadow: 0 -2px 0 2px $ay-white-color;
  height: 100%;
  margin-bottom: 2rem;
  -ms-overflow-style: none;
  overflow-y: scroll;
  position: relative;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  .checkmark {
    height: 1rem;
    min-height: 1rem;
    min-width: 1rem;
    width: 1rem;
  }

  .checkbox-container {
    height: 1.6rem;
  }
}

.table-container {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;

  .row-cell {
    background-color: $ay-white-color;
    border-bottom: 1px solid $ay-grey-16-color;
    border-right: 1px solid $ay-grey-16-color;
    color: $ay-grey-80-color;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.2rem;
    min-width: 7rem;
    overflow: hidden;
    padding: 0.5rem;
    text-align: left;
    white-space: nowrap;

    &.active-column {
      background-color: $ay-grey-8-color;
    }
  }

  .alignment {
    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }

    &-center {
      text-align: center;
    }
  }

  tr {
    th:last-child,
    td:last-child {
      border-right: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    &:hover .row-cell {
      background: $ay-grey-8-color;
    }
  }

  .content-row {
    color: $ay-grey-80-color;
    line-height: 1.5rem;
  }

  .column-selection {
    min-width: 1rem;
    text-align: center;
  }
}

.header-row {
  background-color: $ay-white-color;
  border-bottom: 1px solid $ay-grey-16-color;
  border-right: 1px solid $ay-grey-16-color;
  color: $ay-grey-64-color;
  cursor: pointer;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1rem;
  padding: 0.5rem;
  position: sticky;
  text-align: left;
  z-index: $z-index-9;

  &.header-row-sorted {
    color: $primary-color-900;
  }
}

.sort-header {
  background: url('../../assets/images/icons/order-asc.svg') no-repeat top 50% left 0;
  display: inline-block;
  height: 0.75rem;
  margin-left: 0.25rem;
  vertical-align: middle;
  width: 0.75rem;

  &.down {
    background: url('../../assets/images/icons/order-desc.svg') no-repeat top 50% left 0;
  }
}

.average-column {
  position: relative;
}

.average-line {
  border-left: 0.125rem dashed $supportive-color-500;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
}

.average-value {
  color: $supportive-color-500;
  position: absolute;
  top: 100%;
}

.divide-line::before {
  border-left: 1px solid $ui-color-300;
  bottom: 0;
  content: '';
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}

.no-results-container {
  align-items: center;
  background-color: $ay-grey-8-color;
  border-radius: $ay-border-radius;
  display: flex;
  flex-direction: column;
  height: 10rem;
  justify-content: center;
  margin-top: 0.5rem;
  text-align: center;
  width: 100%;

  .empty-message {
    color: $ay-grey-64-color;
    font-size: 1.25rem;
    line-height: 2rem;
    margin: 0;
  }
}

.scroll-container {
  background-color: $ay-grey-8-color;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: fixed;
  z-index: $fixed-table-headings-z-index;

  &.absolute-container {
    position: absolute;
  }

  .scroll-wrapper,
  .scroll-content {
    height: 0.5rem;

    &.hidden {
      display: none;
    }
  }
}

.active-column {
  background-color: $ay-grey-8-color;
}

.fixed-table-heading {
  border: 1px solid $ay-grey-16-color;
  border-bottom: 0;
  display: none;
  height: 100%;
  margin-bottom: 2rem;
  overflow: hidden;
  position: fixed;
  z-index: $fixed-table-headings-z-index;

  .fixed-heading-scrollable {
    background-color: $ui-color-white;
    position: relative;
  }

  &.fixed-heading-visible {
    display: block;
  }
}

.edit-button-wrapper {
  align-items: center;
  display: none;
  position: absolute;
  width: 2.5rem;
}

.edit-button {
  height: 2rem;
  margin-left: 0.5rem;
  min-width: 2rem;
  outline: none;
}

.number-field {
  text-align: right;
}

.footer {
  background-color: $ay-white-color;
}
