@import 'src/assets/stylesheets/design-system/colors';

@mixin icon {
  path {
    stroke: $primary-color-500;
  }
}

.action-container {
  display: flex;
}

.common-control-container {
  margin-right: 0.5rem;
}

.group-options-container {
  padding: 0.625rem;
}

.modal-button {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

.action-button {
  @include icon;

  background-color: transparent;
  margin: 0.5rem;
  width: calc(100% - 1rem);

  &:first-child {
    margin-bottom: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
  }

  &:hover,
  &:focus {
    @include icon;

    background-color: $ui-color-white;
    color: $primary-color-500;
  }
}

.group-option-label {
  color: $ui-color-600;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.3125rem;
}
