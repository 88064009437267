@import 'src/assets/stylesheets/design-system/colors';

.side-navigation-item {
  align-items: center;
  color: $ui-color-500;
  cursor: pointer;
  display: flex;
  padding: 1rem;
  text-decoration: none;

  &.subitem {
    &:not(.collapsed) {
      padding-left: 2rem;
    }
  }

  &:hover {
    background-color: $primary-color-100;
  }

  .icon {
    margin-right: 0.5rem;
  }

  &.collapsed {
    justify-content: center;

    .icon {
      margin: 0;
    }
  }

  &.active:not(.disabled) {
    background-color: $primary-color-100;
    box-shadow: inset 2px 0 $primary-color-500;
    color: $ui-color-black;
  }

  &.disabled {
    color: $ui-color-300;
    pointer-events: none;

    &:hover {
      background-color: transparent;
    }

    .icon {
      color: inherit;

      path {
        stroke: currentColor;
      }
    }
  }
}

.label {
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
}
